import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import 'react-image-lightbox/style.css';

// Translation Higher Order Component
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,

} from 'reactstrap';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage, getLanguage,
  translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';



// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// //import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

class Research_details extends Component {
  constructor(props) {
    super(props);
    this.event_id = 0;
    this.state = {
        photoIndex: 0,
        startIndex: 0,
       lightboxOpen: false,
        isOpen: false,
       posts: [],
      selected: '1',
      types: [],
      scheduleEvent: [],
      eventtopic: [],
      flag: 0,
      review: [],
      feedback: [],
      arrVideo: [],
      images: [],
      cartEvent: [],
      arrMagazine: [],
      machineCartEvent: [],
      currentProductTopics: [],
      arrProducts: [],
      guestinfo: {
        type_id: '',
        no_of_pages: '',
        total_like: '',
        total_review: '',
        title: '',
        author: '',
        quantity: '',
        price: '',
        description: '',
        images_array: [],
        arr_event_languages: [],
        currentProductTopics: [],
        current_event_type_id: '',
      },
      guestinforelated: [],
      editorState: EditorState.createEmpty(),
    }
    this.handleChange = this.handleChange.bind(this);
    this.setTopicId = this.setTopicId.bind(this);
    this.submitReview = this.submitReview.bind(this);
    this.submitLike = this.submitLike.bind(this);

    this.readMore = this.readMore.bind(this);
    this.readLess = this.readLess.bind(this);

    this.readMoreRelated = this.readMoreRelated.bind(this);
    this.readLessRelated = this.readLessRelated.bind(this);

    this.showMore = this.showMore.bind(this);
    this.showLess = this.showLess.bind(this);
    this.showallReview = this.showallReview.bind(this);

    this.showMoreFeedback = this.showMoreFeedback.bind(this);
    this.showLessFeedback = this.showLessFeedback.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.openGallery = this.openGallery.bind(this);

    this.openGalleryVideo = this.openGalleryVideo.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
  }

  setTopicId() {
    var first_topic_id = localStorage.getItem('topic_id_duplicate');
    localStorage.removeItem('event_topic_id');
    localStorage.setItem('event_topic_id', first_topic_id);
    setTimeout(this.props.history.push('/products'), 10);
  }


  addToCart(e) {
    var product_id = e.target.id;
    // console.log(e.target.id);
    var product_quantity = $('#qty' + product_id).val();
    var user_id = localStorage.getItem('user_id');
    console.log(product_quantity);

    if (product_quantity > 0 && product_id != '' && user_id != '' && user_id > 0) {
      fetch(myConstClass.BASE_URL + 'cart/addProductToCart/' + product_id + '/' + product_quantity + '/' + user_id)
        .then(response => response.json())
        .then(response => {
          this.setState({ cart: response })
          var message = this.state.cart.message;
          var status = this.state.cart.status;
          if (status == 'true') {
            // $('#'+product_id).css('display','none');
            // $('#addedInCart_'+product_id).css('display','inherit');
            $('#' + product_id).removeClass('btn-brown');
            $('#' + product_id).addClass('btn-light');
            //$('#' + product_id).html('ADDED TO CART');
             if (getLanguage() == 'en') {
                            $('#' + product_id).html('ADD MORE');
                        } else {
                            $('#' + product_id).html('अधिक घ�?या');
                        }

            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            if (user_id > 0 && user_id != null) {
              fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                .then(response => response.json())
                .then(response => {
                  this.setState({ cartcount: response })
                  var totalcartcount = this.state.cartcount.totalcartcount;
                  $('.lblCartCount').html(totalcartcount);
                })
            }
          } else {
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
          }
          //console.log(status);
        })
        .catch(err => console.log(err))


    } else {
      //toastr.warning('Product quantity must be greater than 0')
      if (user_id > 0) {
        toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
      } else {
        var message = "Please login to our website.";
        localStorage.removeItem('error');
        localStorage.setItem('error', message);
        this.props.history.push('/signin')
        //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

      }

    }
    //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
    //this.props.offline();    
  }

  showallReview() {
    $('#ShowAllReviewDiv').css('display', 'none');
    const answer_array = this.props.location.pathname.split('/');
    fetch(myConstClass.BASE_URL + 'review/getResearchReviewFrontEnd/' + answer_array['2'] + '/200')
      .then(response => response.json())
      .then(response => this.setState({ review: response }))
      .catch(err => console.log(err))

  }
    openGalleryVideo(e) {
        $('.jss1').attr("style", "display: none !important");
        $("#openGallery").css({'display':'flex'});
        this.setState({ lightboxOpen: true });
        // var id = e.target.id;
        $('html,body').animate({
            scrollTop: 0
        }, 700);
        $('.slide-ico').css('display', 'none');
        this.state.photoIndex = e.target.id;
        $('#videoCloseIcon').css('display', 'inline');
        $('body').css('overflow', 'hidden');
        // $("#openGallery > div").css("z-index","99999"); 
        // $("#openGallery > div").css("z-index","99999 !important");
        // $( "#openGallery div" ).first().css("z-index","99999 !important"); 
        $('.fixed-top').css('z-index', '-9999999');
        $('.jss1').css('display', 'none !important');
    }
    closeGallery() {
        this.setState({ lightboxOpen: false });
        $('#videoCloseIcon').css('display', 'none');
        $('.slide-ico').css('display', 'block');
        $('body').css('overflow', 'scroll');
        // $('.fixed-top').css('z-index','9999999');
        $('.fixed-top').css('z-index', '9999999');
        $('#openGallery').css('display', 'none');
    }

    openGallery(e) {
        $('.jss1').attr("style", "display: none !important");
        $("#openGallery").css({'display':'flex'});
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }

  showMore(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_' + review_id).css('display', 'none');
    $('#showLessDiv_' + review_id).css('display', 'block');
  }

  showLess(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_' + review_id).css('display', 'block');
    $('#showLessDiv_' + review_id).css('display', 'none');
  }

  showMoreFeedback(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_' + review_id).css('display', 'none');
    $('#showLessFeedbackDiv_' + review_id).css('display', 'block');
  }

  showLessFeedback(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_' + review_id).css('display', 'block');
    $('#showLessFeedbackDiv_' + review_id).css('display', 'none');
  }

  readMore(e) {
    // var product_id = e.target.id;
    $('#ReadMoreDiv').css('display', 'none');
    $('#ReadLessDiv').css('display', 'block');
  }

  readLess(e) {
    // var product_id = e.target.id;
    $('#ReadMoreDiv').css('display', 'block');
    $('#ReadLessDiv').css('display', 'none');
  }



  readMoreRelated(e) {
    var n_research_id = e.target.id;
    // alert(n_research_id);
    $('#ReadMoreDivRelated_' + n_research_id).css('display', 'none');
    $('#ReadLessDivRelated_' + n_research_id).css('display', 'block');
  }

  readLessRelated(e) {
    // var product_id = e.target.id;
    var n_research_id = e.target.id;
    $('#ReadMoreDivRelated_' + n_research_id).css('display', 'block');
    $('#ReadLessDivRelated_' + n_research_id).css('display', 'none');
  }



  submitLike() {
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    if (user_id != '' && user_id != null && user_id > 0) {

      fetch(myConstClass.BASE_URL + 'review/saveNewLikeFrontEnd/' + user_id + '/' + answer_array['2'])
        .then(response => response.json())
        .then(response => {
          this.setState({ eventinfo: response })
          var status = this.state.eventinfo.status;
          var message = this.state.eventinfo.message;
          if (status == 'true') {
            var productLikeCount = $('#productLikeCount').html();
            var productLikeCountNew = parseInt(productLikeCount) + 1;
            $('#productLikeCount').html(productLikeCountNew);
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            //setTimeout(this.props.history.push('/products'),1000);
          } else {
            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            //toast("Wow so easy !");
          }
        })
        .catch(err => console.log(err))
      // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


    } else {
      //var error =  'You must have login for submitting review.';
      localStorage.removeItem('error');
      localStorage.setItem('error', 'You must have login for like product.');
      // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
      // this.props.history.push('/signin');
      setTimeout(this.props.history.push('/signin'), 1000);
    }

    //alert(user_review);
  }

  submitReview(e) {
    e.preventDefault();
    console.log('this.refs', $('#pform').serializeArray());
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    const answer_array = this.props.location.pathname.split('/');
    var user_review_current = $('#user_review').val();
    var user_review = $.trim(user_review_current);
    //alert(user_review);
    var user_id = localStorage.getItem('user_id');
    if (user_id != '' && user_id != null && user_id > 0) {
      if (user_review != '') {
        var message = 'Your review added successfully,once admin approval will display it.';

        // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
        fetch(myConstClass.BASE_URL + `review/saveNewsReviewFrontEnd/` + user_id + '/' + answer_array['2'], {
          method: 'POST',
          body: JSON.stringify($('#pform').serializeArray()),

        })
          .then(response => response.json())
          .then(response => {
            this.setState({ eventinfo: response })
            var status = this.state.eventinfo.status;
            var message = this.state.eventinfo.message;
            if (status == 'true') {
              toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
              //setTimeout(this.props.history.push('/products'),1000);
            } else {
              toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
              //toast("Wow so easy !");
            }
          })
          .catch(err => console.log(err))
        // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

        $('#user_review').val('');
      } else {
        var message = "Please enter review.";
        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
      }


    } else {
      //var error =  'You must have login for submitting review.';
      localStorage.removeItem('error');
      localStorage.setItem('error', 'You must have login for submitting review.');
      // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
      // this.props.history.push('/signin');
      setTimeout(this.props.history.push('/signin'), 1000);
    }

    //alert(user_review);
  }
  
    


  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  componentDidMount() {
    var current_language = getLanguage();
    localStorage.setItem('current_language', current_language);
    //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
    //   .then(response => response.json())
    //   .then(response =>this.setState({types: response}))
    //   .catch(err => console.log(err))

    var user_id = localStorage.getItem('user_id');
    const answer_array = this.props.location.pathname.split('/');
    // alert(answer_array);
    if (answer_array['2']) {

      fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinfo: response })
          var current_event_type_id = this.state.guestinfo.event_type_id;
          this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
          var pdf_url = this.state.guestinfo.pdf_url;
          if (pdf_url == '' || pdf_url == undefined) {
            $('.pdfDiv').css('display', 'none');
          }
          var total_topic = this.state.guestinfo.currentProductTopics.length;
          if (total_topic > 0) {
            var first_topic_id = this.state.guestinfo.currentProductTopics[0].value;
            // alert(first_topic_id);
            localStorage.removeItem('topic_id_duplicate');
            localStorage.setItem('topic_id_duplicate', first_topic_id);

          }

          var desc = (this.state.guestinfo.description);
          this.state.guestinfo.description = desc;

          var short_description = (this.state.guestinfo.short_description);
          this.state.guestinfo.short_description = short_description;
          var total_images = this.state.guestinfo.images_array.length;
          if (total_images < 2) {
            $('#imagePrev').css('display', 'none');
            $('#imageNext').css('display', 'none');
          }
          var descripotion_length = desc.length;
          if (descripotion_length < 440 || descripotion_length == undefined) {
            $('#readMoreButton').css('display', 'none');
          }
        })
        .then(response => {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(this.state.guestinfo.description))
            )
          })
        }).then(console.log('hi' + this.state.guestinfo.title))
        .catch(err => console.log(err))





      //Related information
      fetch(myConstClass.BASE_URL + 'news/getNewsRelatedInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          console.log("GuestInfo : ",response)
          if(response.status === "false")
            this.setState({ guestinforelated: [] })
          else
            this.setState({ guestinforelated: response })
          var status = this.state.guestinforelated.status;
          if (status == 'false') {
            $('.relatedResearchDiv').css('display', 'none');
            $('.relatedResearchNotAvailable').css('display', 'inline');
          }
          //   var current_event_type_id = this.state.guestinforelated.event_type_id;
          //   this.state.guestinforelated.current_event_type_id = this.state.guestinforelated.event_type_id;


          // var desc = (this.state.guestinforelated.description);
          // this.state.guestinforelated.description = desc;    

          // var short_description = (this.state.guestinforelated.short_description);
          // this.state.guestinforelated.short_description = short_description;    

          //   var descripotion_length = desc.length;
          //   if(descripotion_length < 440 || descripotion_length == undefined){
          //     $('#readMoreButton').css('display','none');
          //   }
        })
        //   .then(response =>{this.setState({editorState: EditorState.createWithContent(
        //     convertFromRaw(JSON.parse(this.state.guestinforelated.description))
        //   )})
        // })  .then(console.log('hi'+this.state.guestinforelated.title))   
        .catch(err => console.log(err))



    }


    fetch(myConstClass.BASE_URL + 'review/getResearchReviewFrontEnd/' + answer_array['2'] + '/1')
      .then(response => response.json())
      .then(response => {
        this.setState({ review: response })
        const total = this.state.review.length;
        if (total < 1) {
          $('#ShowAllReviewDiv').css('display', 'none');
        }


      })
      .catch(err => console.log(err))




    fetch(myConstClass.BASE_URL + 'news/getNewsVideoFrontEnd/' + answer_array['2'])
      .then(response => response.json())
      .then(response => {
        this.setState({ arrVideo: response })
        var numRow = this.state.arrVideo.length;
        if (numRow > 0) {
          $('#carouselExampleControls1').css('display', 'inline');
        } else {
          $('#carouselExampleControls1').css('display', 'none');
        }

        if (numRow < 2) {
          $('#videoPrev').css('display', 'none');
          $('#videoNext').css('display', 'none');
        } else {
          // $('#carouselExampleControls1').css('display','none');
        }
      })
      .catch(err => console.log(err))

    if (user_id == '' || user_id == undefined) {
      user_id = 0;
    }
    //var topic_id = 0;
    fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWiseResearch/' + answer_array['2'] + '/' + getLanguage() + '/' + user_id)
      .then(response => response.json())
      .then(response => this.setState({ arrProducts: response }))

      .catch(err => console.log(err))

    // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;



  }

  componentDidUpdate(prevProps) {

    const { flag } = this.state;
    var current_language = localStorage.getItem('current_language');

    if (current_language != getLanguage() && flag != 1 && current_language != null) {
      //alert(current_language);
      localStorage.removeItem('current_language');
      localStorage.setItem('current_language', getLanguage());
      this.setState({ flag: 1 });
    }
    var message = localStorage.getItem('success');
    if (message != '' && message != null) {
        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
        localStorage.removeItem('success');
    }
    if (getLanguage() == 'ma' && flag == 1) {
      this.setState({ flag: 0 })

      var user_id = localStorage.getItem('user_id');
      const answer_array = this.props.location.pathname.split('/');
      // alert(answer_array);
      if (answer_array['2']) {

        fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ guestinfo: response })
            var current_event_type_id = this.state.guestinfo.event_type_id;
            this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;


            var desc = (this.state.guestinfo.description);
            this.state.guestinfo.description = desc;

            var short_description = (this.state.guestinfo.short_description);
            this.state.guestinfo.short_description = short_description;

            var descripotion_length = desc.length;
            if (descripotion_length < 440 || descripotion_length == undefined) {
              $('#readMoreButton').css('display', 'none');
            }
          })
          .then(response => {
            this.setState({
              editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(this.state.guestinfo.description))
              )
            })
          }).then(console.log('hi' + this.state.guestinfo.title))
          .catch(err => console.log(err))



      }


      fetch(myConstClass.BASE_URL + 'review/getResearchReviewFrontEnd/' + answer_array['2'] + '/1')
        .then(response => response.json())
        .then(response => this.setState({ review: response }))
        .catch(err => console.log(err))




      fetch(myConstClass.BASE_URL + 'news/getNewsVideoFrontEnd/' + answer_array['2'])
        .then(response => response.json())
        .then(response => {
          this.setState({ arrVideo: response })
          var numRow = this.state.arrVideo.length;
          if (numRow > 0) {
            $('#carouselExampleControls1').css('display', 'inline');
          } else {
            $('#carouselExampleControls1').css('display', 'none');
          }
        })
        .catch(err => console.log(err))

      if (user_id == '' || user_id == undefined) {
        user_id = 0;
      }
      //var topic_id = 0;
      fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWiseResearch/' + answer_array['2'] + '/' + getLanguage() + '/' + user_id)
        .then(response => response.json())
        .then(response => this.setState({ arrProducts: response }))

        .catch(err => console.log(err))




      //Related information
      fetch(myConstClass.BASE_URL + 'news/getNewsRelatedInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinforelated: response })
          var status = this.state.guestinforelated.status;
          if (status == 'false') {
            $('.relatedResearchDiv').css('display', 'none');
            $('.relatedResearchNotAvailable').css('display', 'inline');
          }
          var current_event_type_id = this.state.guestinforelated.event_type_id;
          this.state.guestinforelated.current_event_type_id = this.state.guestinforelated.event_type_id;


          var desc = (this.state.guestinforelated.description);
          this.state.guestinforelated.description = desc;

          var short_description = (this.state.guestinforelated.short_description);
          this.state.guestinforelated.short_description = short_description;

          var descripotion_length = desc.length;
          if (descripotion_length < 440 || descripotion_length == undefined) {
            $('#readMoreButton').css('display', 'none');
          }
        })
        .then(response => {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(this.state.guestinforelated.description))
            )
          })
        }).then(console.log('hi' + this.state.guestinforelated.title))
        .catch(err => console.log(err))


      // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;


    }
    if (getLanguage() == 'en' && (flag == 1)) {
      this.setState({ flag: 0 })
      var user_id = localStorage.getItem('user_id');
      const answer_array = this.props.location.pathname.split('/');
      // alert(answer_array);
      if (answer_array['2']) {

        fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ guestinfo: response })
            var current_event_type_id = this.state.guestinfo.event_type_id;
            this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;


            var desc = (this.state.guestinfo.description);
            this.state.guestinfo.description = desc;

            var short_description = (this.state.guestinfo.short_description);
            this.state.guestinfo.short_description = short_description;

            var descripotion_length = desc.length;
            if (descripotion_length < 440 || descripotion_length == undefined) {
              $('#readMoreButton').css('display', 'none');
            }
          })
          .then(response => {
            this.setState({
              editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(this.state.guestinfo.description))
              )
            })
          }).then(console.log('hi' + this.state.guestinfo.title))
          .catch(err => console.log(err))



      }


      fetch(myConstClass.BASE_URL + 'review/getResearchReviewFrontEnd/' + answer_array['2'] + '/1')
        .then(response => response.json())
        .then(response => this.setState({ review: response }))
        .catch(err => console.log(err))




      fetch(myConstClass.BASE_URL + 'news/getNewsVideoFrontEnd/' + answer_array['2'])
        .then(response => response.json())
        .then(response => {
          this.setState({ arrVideo: response })
          var numRow = this.state.arrVideo.length;
          if (numRow > 0) {
            $('#carouselExampleControls1').css('display', 'inline');
          } else {
            $('#carouselExampleControls1').css('display', 'none');
          }
        })
        .catch(err => console.log(err))

      if (user_id == '' || user_id == undefined) {
        user_id = 0;
      }
      //var topic_id = 0;
      fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWiseResearch/' + answer_array['2'] + '/' + getLanguage() + '/' + user_id)
        .then(response => response.json())
        .then(response => this.setState({ arrProducts: response }))

        .catch(err => console.log(err))



      //Related information
      fetch(myConstClass.BASE_URL + 'news/getNewsRelatedInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinforelated: response })
          var status = this.state.guestinforelated.status;
          if (status == 'false') {
            $('.relatedResearchDiv').css('display', 'none');
            $('.relatedResearchNotAvailable').css('display', 'inline');
          }
        })

        .catch(err => console.log(err))


      // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;


    }

  }

  render() {

    const { photoIndex, lightboxOpen, isOpen, selected, guestinforelated, arrProducts, currentProductTopics, images, arrMagazine, cartEvent, machineCartEvent, arrVideo, feedback, review, scheduleEvent, posts, guestinfo, eventtopic } = this.state
    const { t } = this.props;
    return (

      <div className="App">
        <i style={{ cursor: 'pointer', display: 'none' }} onClick={this.closeGallery} className="fa fa-times" aria-hidden="true" id="videoCloseIcon2"></i>
        <div>
          {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
        <Header />
        <div className="container-fluid">

          <div className="row">
            <div className="col-lg-12">
              <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-white mb-0 ml-n2">
                    <li className="breadcrumb-item">
                      <Link to="/">{t('home.home')}</Link>
                    </li>
                    <li className="breadcrumb-item">

                      <Link to="/research">{t('home.Research')}</Link>

                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {guestinfo.title}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col">
                  <h4>{guestinfo.title}</h4>
                  {guestinfo.currentProductTopics.map((topicss, ii) => {
                    if (ii == 0) {
                      return (
                        <Link to={'/topics/' + topicss.label + '/' + topicss.value}>
                          <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{topicss.label}</button>
                        </Link>

                      )
                    } else {
                      return (
                        <Link to={'/topics/' + topicss.label + '/' + topicss.value}>
                          <button style={{ marginLeft: '5px' }} type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{topicss.label}</button>
                        </Link>

                      )
                    }

                  })
                  }
                </div>
              </div>
              
              <div className="row my-3">
                <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                  <span className="mr-2 d-inline-block pr-2">
                    <i className="fas fa-comment-dots orangeColor"></i> <span className="small">{guestinfo.total_review}</span>
                  </span>
                  <span className="d-inline-block pr-2">

                    <i className="fas fa-thumbs-up orangeColor"></i> <span className="small" id="productLikeCount">{guestinfo.total_like}</span>
                  </span>
                  <span className="d-inline-block pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                    <i className="fas fa-thumbs-up color-brown"></i> <br /> <span className="small d-block mt-n1" >{t('home.LIKE THIS')}</span>
                  </span>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-5 text-right pdfDiv">
                  <a style={{ cursor: 'pointer' }} target="_blank" href={guestinfo.pdf_url} download>
                    <label style={{ paddingRight: '10px', cursor: 'pointer', marginTop: '6px' }}>{t('home.Download PDF')}</label>
                    <i style={{ color: '#9d0b0e', fontSize: '33px', cursor: 'pointer', marginTop: '0px', position: 'absolute' }} className="fa fa-file-pdf" aria-hidden="true">

                    </i>
                  </a>
                </div>
              </div>


            </div>

            <div className="col-xl-9 col-lg-9 col-md-9 col-12">

              <div className="row mt-3">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">

                      {guestinfo.images_array.map((info, ii) => {
                        if (ii == 0) {
                          // return( <div className="carousel-item active"> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                          return (<div className="carousel-item active"> <img className="d-block w-100 img-fluid newsFitScreenImage" src={info.image_name} /></div>
                          )
                        } else {
                          // return( <div className="carousel-item "> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                          return (<div className="carousel-item "> <img className="d-block w-100 img-fluid newsFitScreenImage" src={info.image_name} /></div>
                          )
                        }
                      }

                      )}
                    </div>
                    <a id="imagePrev" className="carousel-control-prev" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="prev">
                      <i className="fas fa-chevron-left slide-ico"></i>
                      <span className="sr-only">{t('home.Previous')}</span>
                    </a>
                    <a id="imageNext" className="carousel-control-next" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="next">
                      <i className="fas fa-chevron-right slide-ico"></i>
                      <span className="sr-only">{t('home.Next')}</span>
                    </a>
                  </div>

                  <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">

                      {arrVideo.map((info, ii) => {
                        if (ii == 0) {
                          // return( <div className="carousel-item active"> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                          return ( <div class="carousel-item active mt-3 active wrapper">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div >
                                                                    <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGalleryVideo} src={info.you_tube_url} class="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                    <div class="overlay">
                                                                        <a href="#" class="icon" >
                                                                            <i class="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                          )
                        } else {
                          // return( <div className="carousel-item "> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                          return ( <div className="carousel-item ">
                            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div class="div1">
                                                                    <img style={{ cursor: 'pointer' }} onClick={this.openGalleryVideo} src={info.you_tube_url} class="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                    <div class="overlay">
                                                                        <a href="#" class="icon" >
                                                                            <i class="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div> </div>
                          )
                        }
                      }

                      )}
                      <a id="videoPrev" className="carousel-control-prev" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="prev">
                        <i className="fas fa-chevron-left slide-ico"></i>
                        <span className="sr-only">{t('home.Previous')}</span>
                      </a>
                      <a id="videoNext" className="carousel-control-next" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="next">
                        <i className="fas fa-chevron-right slide-ico"></i>
                        <span className="sr-only">{t('home.Next')}</span>
                      </a>
                    </div>

                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <p>
                    {/* {guestinfo.description} */}
                    <span id="ReadMoreDiv">
                      {/* {guestinfo.short_description} */}
                      {ReactHtmlParser(guestinfo.short_description)}
                      <br /> <button style={{ float: 'right' }} id="readMoreButton" onClick={this.readMore} className="btn orangeColor btn-link">{t('home.Read more')}</button>
                    </span>

                    <span id="ReadLessDiv" style={{ display: 'none' }}>
                      {ReactHtmlParser(guestinfo.description)}

                      <br /> <button style={{ float: 'right' }} onClick={this.readLess} className="btn orangeColor btn-link">{t('home.Read less')}</button>
                    </span>
                    <br />
                  </p>

                  <div className="mt-3">
                    <hr />
                    <h5>{t('home.Related Research and Studies')}</h5>
                    <div className="relatedResearchDiv mt-4">
                      {guestinforelated.map((news, ii) => {
                        return (
                          <Row className="my-2">
                            {news.images_array.map((info, ii) => {
                              return (
                                <Col xl="3" lg="3" md="3" sm="3" xs="3">
                                  <img src={info.image_name} style={{ width: '85px', height: '85px' }} />
                                </Col>
                              )
                            }
                            )}
                            <Col xl="9" lg="9" md="9" sm="9" xs="9">
                              <Row>
                                <Col xl="12" lg="12" md="12" sm="12" xs="12" className="text-right">
                                  <a style={{ cursor: 'pointer' }} target="_blank" href={news.pdf_url} download>
                                    <label>{t('home.Download PDF')}</label>
                                    <i style={{ color: '#9d0b0e', fontSize: '33px', cursor: 'pointer' }} className="fa fa-file-pdf" aria-hidden="true">
                                    </i>
                                  </a>
                                </Col>
                                <Col xl="12" lg="12" md="12" sm="12" xs="12" className="text-left font-size--smaller">
                                  <span>{t('home.By')}&nbsp;{news.author}</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl="12" className="font-weight-bold">
                              {news.title}
                            </Col>
                            <Col xl="12">
                              <span id={'ReadMoreDivRelated_' + news.news_article_id}>
                                {ReactHtmlParser(news.short_description)}
                                <button style={{ float: 'right' }} className={'btn orangeColor btn-link readMoreButtonRelated_' + news.news_article_id} id={'' + news.news_article_id} onClick={this.readMoreRelated}>{t('home.Read more')}</button>
                              </span>

                              <span id={'ReadLessDivRelated_' + news.news_article_id} style={{ display: 'none' }}>
                                {ReactHtmlParser(news.description)}
                                <button className={'btn orangeColor btn-link readLessButtonRelated_' + news.news_article_id} id={'' + news.news_article_id} style={{ float: 'right' }} onClick={this.readLessRelated} >{t('home.Read less')}</button>
                              </span>
                            </Col>
                          </Row>
                        )
                      }
                      )}
                    </div>
                    <p style={{ display: 'none' }} className="relatedResearchNotAvailable">
                      {t('home.Related research not available')}
                    </p>

                    <hr />

                    <h5>{t('home.REVIEW')}</h5>
                    {review.map((info, ii) => {
                      if (info.description.length < 440) {
                        return (
                          <div>
                            <p>{info.description}
                            </p>
                            {/* <p><a className="orangeColor text-right">
                            {t('home.Show more')}...
                                        </a></p> */}
                            <p className="font-weight-bolder">
                              {/* {info.first_name}   {info.last_name} */}
                              {info.name}
                            , {info.city_name} </p>
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <p id={'showMoreDiv_' + info.review_id} style={{ minHeight: '93px' }}>{info.short_description}
                              <span className="orangeColor" id={'showMoreSpan_' + info.review_id} onClick={this.showMore} style={{ cursor: 'pointer' }}><br />{t('home.Show more')}</span>
                            </p>

                            <p id={'showLessDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>{info.description}
                              <span className="orangeColor" id={'showLesSpan_' + info.review_id} onClick={this.showLess} style={{ cursor: 'pointer' }}><br />{t('home.Show less')}</span>
                            </p>

                            <p className="font-weight-bolder">{info.name}, {info.address} </p>

                          </div>

                        )
                      }
                    }

                    )}

                    <Row id="ShowAllReviewDiv">
                      <Col xl="4" lg="4" md="4" sm="12" xs="12">
                        <button className="btn btn-outline-brown btn-sm btn-block mb-3" onClick={this.showallReview}>
                          {t('home.SHOW ALL')}
                        </button>
                      </Col>
                    </Row>

                   {/*  <h5 className=" text-black-50"></h5> */}
                    <Form action="" id="pform" onSubmit={this.submitReview} method="post" encType="multipart/form-data">
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <textarea id="user_review" name="user_review" className="form-control" rows="4" placeholder={t('home.Please add your review')}></textarea>
                        </Col>
                        <Col xl="3" lg="3" md="3" sm="12" xs="12">
                          <button className="btn btn-outline-brown mt-3 btn-block" >{t('home.SUBMIT')}</button>
                        </Col>
                      </Row>
                    </Form>


                  </div>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-12 pt-3" id="eventRightMenuMain"  >
              <div className="row bg-light m-0 py-2" style={{ height: '54px', marginBottom: '15px !important' }}>

                <p style={{ textAlign: 'center', marginTop: '8px', marginLeft: '10px', fontSize: '17px', fontWeight: '530' }}>
                  <strong>{t('home.Related Literature')}</strong>

                </p>
              </div>
              <div className="mt-3">

              </div>
              {arrProducts.map((info, i) => {
                return (
                  <div className="col py-2 border border-warning bg-white box-shadow mb-3">


                    <div className="row d-flex">
                      <div className="col-5">
                        <Link to={"/products_details/" + info.title_url + '/' + info.product_id}>
                          <img className="image_css_product_research" src={info.image_name} /></Link>


                      </div>
                      <div className="col-7">
                        {info.short_title}
                        <br />
                        <p className="mb-1">{t('home.donation_contribution')}</p>
                        <p className="font-weight-bolder">{t('home.INR')} {info.price}/-</p>
                        <input type="hidden" className="count" value="1" name={"qty" + info.product_id} id={"qty" + info.product_id} />

                        {info.added_in_cart.map((carts, j) => {
                          if (carts.cart_id > 0) {
                            return (
                              <span>
                                {/* <button id={'addedInCart_'+info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button> */}
                                <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADDED TO CART')}</button>
                              </span>
                            )
                          } else {
                            return (
                              <span>
                                <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button>
                                <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                              </span>
                            )
                          }
                        })
                        }

                      </div>

                    </div>


                  </div>
                )
              })}
              <Row>
                <Col xl="5" lg="5" md="5" sm="12" xs="12">
                  <button className="btn btn-outline-brown btn-sm btn-block" onClick={this.setTopicId}>{t('home.SHOW ALL')}</button>
                </Col>
              </Row>


            </div>

          </div>

        </div>
        <Footer />
        
        
        <div id="openGallery" className="openGallerydiv" >
        <span ><i style={{ cursor: 'pointer' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon"></i></span>;

          {lightboxOpen && (
            <ReactImageVideoLightbox

              data={this.state.arrVideo}
              startIndex={this.state.startIndex}
              showResourceCount={true}
              onCloseCallback={this.callbackFunction} />
          )}

        </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex]['image_url']}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                )}
      </div>
    );
  }
}
Research_details.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Research_details);
