import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';

import checkoutImage from './assets/images/mycart.png';

import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    UncontrolledCollapse,
    FormGroup,
    Label,
    Dropdown
} from "reactstrap";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            cart: [],
            flag: 0,
            value: 1,
            allOrders: [],
            openOrders: [],
            pastOrders: [],
            cancelOrders: [],
            guestinfo: {

            },
            eventinfo: {


            },
            removeinfo: {
                message: '',
                status: '',
            },
            editorState: EditorState.createEmpty(),
            orderType: 'ALL ORDERS',
            dropdownOpen: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.togggleDropdown = this.toggleDropdown(this);

    }


    componentDidMount() {
        //this.state.guestinfo.product_total_price = 0;
        const answer_array = this.props.location.pathname.split('/');

        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });



            localStorage.removeItem('success');
        }

        var error = localStorage.getItem('error');
        if (error != '' && error != null) {
            toast('' + error, { containerId: 'B', type: toast.TYPE.ERROR });
            localStorage.removeItem('error');
        }

        var user_id = localStorage.getItem('user_id');
        if (user_id > 0) {
            fetch(myConstClass.BASE_URL + 'payment/getAllOrders/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ allOrders: response })
                    const numRows = this.state.allOrders.length;

                })


            fetch(myConstClass.BASE_URL + 'payment/getOpenOrders/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ openOrders: response })
                    const numRows = this.state.openOrders.length;

                })

            fetch(myConstClass.BASE_URL + 'payment/getPastOrders/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ pastOrders: response })
                    const numRows = this.state.pastOrders.length;

                })

            fetch(myConstClass.BASE_URL + 'payment/getCancelOrders/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ cancelOrders: response })
                    const numRows = this.state.cancelOrders.length;

                })



                // fetch(myConstClass.BASE_URL+'users/getBillingAddress/'+billing_address_id+'/'+user_id)
                // .then(response => response.json())
                // .then(response =>{this.setState({guestinfoarray: response})
                // const numRows = this.state.guestinfoarray.length;

                // })


                .catch(err => console.log(err))
        }



    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }


    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    render() {

        const { posts, guestinfo, cart, eventinfo, allOrders, openOrders, pastOrders, cancelOrders } = this.state
        const { t } = this.props;

        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    {/* <ol className="breadcrumb bg-white mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Order History</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Order History</li>
                        </ol> */}
                                    <ol className="breadcrumb bg-white mb-0">
                                        {/* <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Order History</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Order History</li> */}
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">{t('home.My Account')}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('home.Order History')}</li>

                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5 style={{ fontSize: '1.4rem' }}>{t('home.ORDER HISTORY')}</h5>
                                </div>
                            </div>
                            <div className="d-xl-block d-lg-block d-none">
                                <div className="row">
                                    <div className="col">
                                        <ul className="nav nav-tabs order-tabs border-bottom-0" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#all_orders">{t('home.ALL ORDERS')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#open_orders">{t('home.OPEN ORDERS')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#past_orders">{t('home.PAST ORDERS')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cancelled_orders">{t('home.CANCELLED ORDERS')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="d-xl-none d-lg-none d-block">
                                <div className="row">
                                    <div className="col">
                                        <a data-toggle="tab" href="#all_orders">{t('home.ALL ORDERS')}</a>
                                    </div>
                                    <div className="col">
                                        <a data-toggle="tab" href="#open_orders">{t('home.OPEN ORDERS')}</a>
                                    </div>
                                    <div className="col">
                                        <a data-toggle="tab" href="#past_orders">{t('home.PAST ORDERS')}</a>
                                    </div>
                                    <div className="col">
                                        <a data-toggle="tab" href="#cancelled_orders">{t('home.CANCELLED ORDERS')}</a>
                                    </div>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col">
                                    <div className="tab-content">
                                        <div id="all_orders" className="tab-pane active">
                                            <div className="d-xl-block d-lg-block d-none">
                                                <table className="table table-bordered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th scope="col ">{t('home.Placed On')}</th>
                                                            <th scope="col ">{t('home.Order ID')}</th>
                                                            <th scope="col ">{t('home.Events')}</th>
                                                            <th scope="col ">{t('home.Products')}</th>
                                                            <th scope="col ">{t('home.Expected Date of Product Delivery')}</th>
                                                            <th scope="col ">{t('home.Status')}</th>
                                                            <th scope="col ">{t('home.Courier Traking URL')}</th>
                                                            {/* <th scope="col ">{t('home.Booking Date')}</th> */}
                                                            <th scope="col ">{t('home.Donation Contribution')}</th>
                                                            <th scope="col " style={{ width: '10%' }}>{t('home.Action')}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allOrders.map((info, ii) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{ii+1}</td> */}
                                                                    <td>{info.created_on}</td>
                                                                    <td>
                                                                        {/* {info.order_id_main} */}
                                                                        <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                    </td>
                                                                    <td>
                                                                        {info.total_events * 1 ? <>{info.total_events * 1 + " " + t('home.Events')}<br /> </> : ""}&nbsp;&nbsp;
                                                                        {info.events}<br />
                                                                        {info.total_machine_test * 1 ? info.total_machine_test * 1 : ''}&nbsp;&nbsp;{info.total_machine_test * 1 ? t('home.Test') : ''}<br />
                                                                        {info.machine_test}
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        {info.total_products * 1 + " " + t('home.Products')}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                        </p>
                                                                        {info.products}
                                                                        <p>
                                                                            {info.total_magazines * 1 ? info.total_magazines * 1 : ''}&nbsp;&nbsp;
                                                                        {info.total_magazines * 1 ? t('home.Manashakti Magazine') : ''}</p>
                                                                        {info.package}
                                                                    </td>
                                                                    <td>{info.courier_date_format}</td>
                                                                    <td>{info.status}</td>
                                                                    <td>
                                                                        <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                    </td>
                                                                    <td>{t('home.INR')}{(info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1).toFixed(2)}</td>


                                                                    <td>

                                                                        <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                        <br />
                                                                        {info.arr_order_group_by_trust.map((trust, i) => {
                                                                            if (trust.trust_id_fk == 1) {
                                                                                var invoice_type = "invoice_A";
                                                                                var invoice_name = "Receipt A";
                                                                            } else if (trust.trust_id_fk == 2) {
                                                                                var invoice_type = "invoice_B";
                                                                                var invoice_name = "Receipt B";
                                                                            } else if (trust.trust_id_fk == 3) {
                                                                                var invoice_type = "invoice_C";
                                                                                var invoice_name = "Receipt C";
                                                                            } else {
                                                                                var invoice_type = "invoice_D";
                                                                                var invoice_name = "Receipt D";
                                                                            }
                                                                            //if(i == 0){
                                                                            return (
                                                                                <p>
                                                                                    <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                </p>
                                                                            )
                                                                            //}


                                                                        })
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                            <div className="d-xl-none d-lg-none d-block">
                                                <ListGroup>
                                                    {
                                                        allOrders.map((info, ii) =>

                                                            <ListGroupItem key={info.order_id} className="p-0 my-2">
                                                                <div className="bg-light py-3 px-3 clearfix" >
                                                                    <h6 className="float-left">
                                                                        {info.created_on}
                                                                    </h6>
                                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id={'orderDetails_' + info.order_id} />
                                                                </div>
                                                                <UncontrolledCollapse toggler={'orderDetails_' + info.order_id} className="px-3 py-3">
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Placed On')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.created_on}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Order ID')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        {
                                                                            info.total_events * 1 || info.total_machine_test * 1?
                                                                                <>
                                                                                    <Label className="font-weight-bold">
                                                                                        {t('home.Events')}&nbsp;:&nbsp;
                                                                                    </Label>
                                                                                    <br />
                                                                                </> : ''
                                                                        }

                                                                        <Label>
                                                                            {info.total_events * 1 != 0 ? info.total_events * 1 : ''}&nbsp;&nbsp;
                                                                            {info.total_events * 1 != 0 ? <>{t('home.Events')}<br /></> : ''}

                                                                            {info.events}<br />
                                                                            {info.total_machine_test * 1 ? info.total_machine_test * 1 : ''}&nbsp;&nbsp;{info.total_machine_test * 1 ? <>{t('home.Test')}<br /> </> : ''}
                                                                            {info.machine_test}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        {info.total_products * 1 ?
                                                                            <>
                                                                                <Label className="font-weight-bold">
                                                                                    {t('home.Products')}&nbsp;:&nbsp;
                                                                                </Label>
                                                                                <br />
                                                                            </> : ''}

                                                                        <Label>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        <p>
                                                                                            {info.total_products * 1 ? info.total_products * 1 : ''}&nbsp;&nbsp;
                                                                                {info.total_products * 1 ? t('home.Products') : ''}
                                                                                        </p>
                                                                                        {info.products}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        <p>

                                                                                            {info.total_magazines * 1 ? info.total_magazines * 1 : ''}&nbsp;&nbsp;
                                                        {info.total_magazines * 1 ? t('home.Manashakti Magazine') : ''}</p>
                                                                                        {info.package}
                                                                                    </> : ""
                                                                            }

                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Expected Date of Product Delivery')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label sm="6" xs="6">
                                                                            {info.courier_date}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Status')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.status}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Courier Traking URL')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Action')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                            <br />
                                                                            {info.arr_order_group_by_trust.map((trust, i) => {
                                                                                if (trust.trust_id_fk == 1) {
                                                                                    var invoice_type = "invoice_A";
                                                                                    var invoice_name = "Receipt A";
                                                                                } else if (trust.trust_id_fk == 2) {
                                                                                    var invoice_type = "invoice_B";
                                                                                    var invoice_name = "Receipt B";
                                                                                } else if (trust.trust_id_fk == 3) {
                                                                                    var invoice_type = "invoice_C";
                                                                                    var invoice_name = "Receipt C";
                                                                                } else {
                                                                                    var invoice_type = "invoice_D";
                                                                                    var invoice_name = "Receipt D";
                                                                                }
                                                                                return (
                                                                                    <p>
                                                                                        <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                    </p>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Label>
                                                                    </FormGroup>
                                                                </UncontrolledCollapse>
                                                            </ListGroupItem>


                                                        )

                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>
                                        <div id="open_orders" className="tab-pane">
                                            <div className="d-xl-block d-lg-block d-none">
                                                <table className="table table-bordered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th scope="col ">{t('home.Placed On')}</th>
                                                            <th scope="col ">{t('home.Order ID')}</th>
                                                            <th scope="col ">{t('home.Events')}</th>
                                                            <th scope="col ">{t('home.Products')}</th>
                                                            <th scope="col ">{t('home.Expected Date of Product Delivery')}</th>
                                                            <th scope="col ">{t('home.Status')}</th>
                                                            <th scope="col ">{t('home.Courier Traking URL')}</th>
                                                            {/* <th scope="col ">{t('home.Booking Date')}</th> */}
                                                            <th scope="col ">{t('home.Donation Contribution')}</th>
                                                            <th scope="col " style={{ width: '10%' }}>{t('home.Action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {openOrders.map((info, ii) => {
                                                            return (
                                                                <tr>
                                                                    <td>{info.created_on}</td>
                                                                    <td>
                                                                        {/* {info.order_id_main} */}
                                                                        <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                    </td>
                                                                    <td>
                                                                        {info.total_events * 1 ? <>{info.total_events * 1 + " " + t('home.Events')}<br /> </> : ""}&nbsp;&nbsp;
                                                                        {info.events}<br />
                                                                        {info.total_machine_test * 1 ? <>{info.total_machine_test + " " + t('home.Test')}</> : ""}&nbsp;&nbsp;<br />
                                                                        {info.machine_test}
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        {info.total_products * 1 + " " + t('home.Products')}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                        &nbsp;&nbsp;
                                                </p>
                                                                        {info.products}
                                                                        <p>
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>{info.total_magazines * 1 + " " + t('home.Manashakti Magazine')}</>
                                                                                    : ""
                                                                            }&nbsp;&nbsp;
                                                                            </p>
                                                                        {info.package}
                                                                    </td>
                                                                    <td>{info.courier_date_format}</td>
                                                                    <td>{info.status}</td>
                                                                    <td>
                                                                        <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                    </td>
                                                                    <td>{t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}</td>

                                                                    <td>

                                                                        <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                        <br />
                                                                        {info.arr_order_group_by_trust.map((trust, i) => {
                                                                            if (trust.trust_id_fk == 1) {
                                                                                var invoice_type = "invoice_A";
                                                                                var invoice_name = "Receipt A";
                                                                            } else if (trust.trust_id_fk == 2) {
                                                                                var invoice_type = "invoice_B";
                                                                                var invoice_name = "Receipt B";
                                                                            } else if (trust.trust_id_fk == 3) {
                                                                                var invoice_type = "invoice_C";
                                                                                var invoice_name = "Receipt C";
                                                                            } else {
                                                                                var invoice_type = "invoice_D";
                                                                                var invoice_name = "Receipt D";
                                                                            }
                                                                            //if(i == 0){
                                                                            return (
                                                                                <p>
                                                                                    <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                </p>
                                                                            )
                                                                            //}


                                                                        })
                                                                        }
                                                                    </td>
                                                                </tr>


                                                            )

                                                        })
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-xl-none d-lg-none d-block">
                                                <ListGroup>
                                                    {
                                                        openOrders.map((info, ii) =>

                                                            <ListGroupItem key={info.order_id} className="p-0 my-2">
                                                                <div className="bg-light py-3 px-3 clearfix" >
                                                                    <h6 className="float-left">
                                                                        {info.created_on}
                                                                    </h6>
                                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id={'orderDetails_' + info.order_id} />
                                                                </div>
                                                                <UncontrolledCollapse toggler={'orderDetails_' + info.order_id} className="px-3 py-3">
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Placed On')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.created_on}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Order ID')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    {
                                                                        info.total_events * 1 || info.total_machine_test * 1?
                                                                            <FormGroup>
                                                                                <Label className="font-weight-bold">
                                                                                    {t('home.Events')}&nbsp;:&nbsp;
                                                                                </Label>
                                                                                <br />
                                                                                <Label>
                                                                                    {info.total_events * 1 != 0 ? info.total_events * 1 : ''}&nbsp;&nbsp;
                                            {info.total_events * 1 != 0 ? t('home.Events') : ''}<br />

                                                                                    {info.events}<br />
                                                                                    {info.total_machine_test * 1 ? info.total_machine_test * 1 : ''}&nbsp;&nbsp;{info.total_machine_test * 1 ? <>{t('home.Test')}<br /></> : ''}
                                                                                    {info.machine_test}
                                                                                </Label>
                                                                            </FormGroup>
                                                                            : ""
                                                                    }

                                                                    <FormGroup>
                                                                        {
                                                                            info.total_products * 1 ?
                                                                                <>
                                                                                    <Label className="font-weight-bold">
                                                                                        {t('home.Products')}&nbsp;:&nbsp;
                                                                                </Label>
                                                                                    <br />
                                                                                </> : ""
                                                                        }

                                                                        <Label>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        <p>
                                                                                            {info.total_products * 1 ? info.total_products * 1 : ''}&nbsp;&nbsp;
                                                                                {info.total_products * 1 ? t('home.Products') : ''}
                                                                                        </p>
                                                                                        {info.products}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        <p>
                                                                                            {info.total_magazines * 1 ? info.total_magazines * 1 : ''}&nbsp;&nbsp;
                                                                                {info.total_magazines * 1 ? t('home.Manashakti Magazine') : ''}
                                                                                        </p>
                                                                                        {info.package}
                                                                                    </>
                                                                                    :""
                                                                        }
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Expected Date of Product Delivery')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label sm="6" xs="6">
                                                                            {info.courier_date}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Status')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.status}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Courier Traking URL')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Action')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                            <br />
                                                                            {info.arr_order_group_by_trust.map((trust, i) => {
                                                                                if (trust.trust_id_fk == 1) {
                                                                                    var invoice_type = "invoice_A";
                                                                                    var invoice_name = "Receipt A";
                                                                                } else if (trust.trust_id_fk == 2) {
                                                                                    var invoice_type = "invoice_B";
                                                                                    var invoice_name = "Receipt B";
                                                                                } else if (trust.trust_id_fk == 3) {
                                                                                    var invoice_type = "invoice_C";
                                                                                    var invoice_name = "Receipt C";
                                                                                } else {
                                                                                    var invoice_type = "invoice_D";
                                                                                    var invoice_name = "Receipt D";
                                                                                }
                                                                                return (
                                                                                    <p>
                                                                                        <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                    </p>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Label>
                                                                    </FormGroup>
                                                                </UncontrolledCollapse>
                                                            </ListGroupItem>


                                                        )

                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>

                                        <div id="past_orders" className="tab-pane">
                                            <div className="d-xl-block d-lg-block d-none">
                                                <table className="table table-bordered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th scope="col ">{t('home.Placed On')}</th>
                                                            <th scope="col ">{t('home.Order ID')}</th>
                                                            <th scope="col ">{t('home.Events')}</th>
                                                            <th scope="col ">{t('home.Products')}</th>
                                                            <th scope="col ">{t('home.Expected Date of Product Delivery')}</th>
                                                            <th scope="col ">{t('home.Status')}</th>
                                                            <th scope="col ">{t('home.Courier Traking URL')}</th>
                                                            {/* <th scope="col ">{t('home.Booking Date')}</th> */}
                                                            <th scope="col ">{t('home.Donation Contribution')}</th>

                                                            <th scope="col " style={{ width: '10%' }}>{t('home.Action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pastOrders.map((info, ii) => {
                                                            return (
                                                                <tr>
                                                                    <td>{info.created_on}</td>
                                                                    <td>
                                                                        {/* {info.order_id_main} */}
                                                                        <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            info.total_events * 1 ?
                                                                                <>{info.total_events * 1 + " " + t('home.Events')}</>
                                                                                : ""
                                                                        }&nbsp;&nbsp;
                                                                        <br />

                                                                        {info.events}<br />
                                                                        {
                                                                            info.total_machine_test * 1 ?
                                                                                <>{info.total_machine_test * 1 + " " + t('home.Test')}</> : ""
                                                                        }&nbsp;&nbsp;<br />
                                                                        {info.machine_test}
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {info.total_products * 1 ?
                                                                                <>
                                                                                    {info.total_products * 1 + " " + t('home.Products')}
                                                                                </> : ""}
                                                                            &nbsp;&nbsp;
                                                                            </p>
                                                                        {info.products}
                                                                        <p>
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        {info.total_products * 1 + " " + t('home.Manashakti Magazine')}
                                                                                    </> :
                                                                                    ""
                                                                            }&nbsp;&nbsp;
                                                                        </p>
                                                                        {info.package}
                                                                    </td>
                                                                    <td>{info.courier_date_format}</td>
                                                                    <td>{info.status}</td>
                                                                    <td>
                                                                        <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                    </td>
                                                                    <td>{t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}</td>

                                                                    <td>

                                                                        <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                        <br />
                                                                        {info.arr_order_group_by_trust.map((trust, i) => {
                                                                            if (trust.trust_id_fk == 1) {
                                                                                var invoice_type = "invoice_A";
                                                                                var invoice_name = "Receipt A";
                                                                            } else if (trust.trust_id_fk == 2) {
                                                                                var invoice_type = "invoice_B";
                                                                                var invoice_name = "Receipt B";
                                                                            } else if (trust.trust_id_fk == 3) {
                                                                                var invoice_type = "invoice_C";
                                                                                var invoice_name = "Receipt C";
                                                                            } else {
                                                                                var invoice_type = "invoice_D";
                                                                                var invoice_name = "Receipt D";
                                                                            }
                                                                            //if(i == 0){
                                                                            return (
                                                                                <p>
                                                                                    <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                </p>
                                                                            )
                                                                            //}


                                                                        })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>

                                            <div className="d-xl-none d-lg-none d-block">
                                                <ListGroup>
                                                    {
                                                        pastOrders.map((info, ii) =>

                                                            <ListGroupItem key={info.order_id} className="p-0 my-2">
                                                                <div className="bg-light py-3 px-3 clearfix" >
                                                                    <h6 className="float-left">
                                                                        {info.created_on}
                                                                    </h6>
                                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id={'orderDetails_' + info.order_id} />
                                                                </div>
                                                                <UncontrolledCollapse toggler={'orderDetails_' + info.order_id} className="px-3 py-3">
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Placed On')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.created_on}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Order ID')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                    {
                                                                            info.total_events * 1 || info.total_machine_test * 1?
                                                                                <>
                                                                                    <Label className="font-weight-bold">
                                                                                        {t('home.Events')}&nbsp;:&nbsp;
                                                                                    </Label>
                                                                                    <br />
                                                                                </> : ''
                                                                        }
                                                                        <Label>
                                                                        {info.total_events * 1 != 0 ? info.total_events * 1 : ''}&nbsp;&nbsp;
                                                                            {info.total_events * 1 != 0 ? <>{t('home.Events')}<br /></> : ''}

                                                                            {info.events}<br />
                                                                            {info.total_machine_test * 1 ? info.total_machine_test * 1 : ''}&nbsp;&nbsp;{info.total_machine_test * 1 ? <>{t('home.Test')}<br /> </> : ''}
                                                                            {info.machine_test}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                    {info.total_products * 1 ?
                                                                            <>
                                                                                <Label className="font-weight-bold">
                                                                                    {t('home.Products')}&nbsp;:&nbsp;
                                                                                </Label>
                                                                                <br />
                                                                            </> : ''}
                                                                            <Label>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        <p>
                                                                                            {info.total_products * 1 ? info.total_products * 1 : ''}&nbsp;&nbsp;
                                                                                {info.total_products * 1 ? t('home.Products') : ''}
                                                                                        </p>
                                                                                        {info.products}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        <p>

                                                                                            {info.total_magazines * 1 ? info.total_magazines * 1 : ''}&nbsp;&nbsp;
                                                        {info.total_magazines * 1 ? t('home.Manashakti Magazine') : ''}</p>
                                                                                        {info.package}
                                                                                    </> : ""
                                                                            }

                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Expected Date of Product Delivery')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label sm="6" xs="6">
                                                                            {info.courier_date}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Status')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.status}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Courier Traking URL')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Action')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                            <br />
                                                                            {info.arr_order_group_by_trust.map((trust, i) => {
                                                                                if (trust.trust_id_fk == 1) {
                                                                                    var invoice_type = "invoice_A";
                                                                                    var invoice_name = "Receipt A";
                                                                                } else if (trust.trust_id_fk == 2) {
                                                                                    var invoice_type = "invoice_B";
                                                                                    var invoice_name = "Receipt B";
                                                                                } else if (trust.trust_id_fk == 3) {
                                                                                    var invoice_type = "invoice_C";
                                                                                    var invoice_name = "Receipt C";
                                                                                } else {
                                                                                    var invoice_type = "invoice_D";
                                                                                    var invoice_name = "Receipt D";
                                                                                }
                                                                                return (
                                                                                    <p>
                                                                                        <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                    </p>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Label>
                                                                    </FormGroup>
                                                                </UncontrolledCollapse>
                                                            </ListGroupItem>


                                                        )

                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>
                                        <div id="cancelled_orders" className="tab-pane">
                                            <div className="d-xl-block d-lg-block d-none">
                                                <table className="table table-bordered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            {/* <th scope="col-3">{t('home.Sr No')}</th> */}
                                                            <th scope="col ">{t('home.Placed On')}</th>
                                                            <th scope="col ">{t('home.Order ID')}</th>
                                                            <th scope="col ">{t('home.Events')}</th>
                                                            <th scope="col ">{t('home.Products')}</th>
                                                            <th scope="col ">{t('home.Expected Date of Product Delivery')}</th>
                                                            <th scope="col ">{t('home.Status')}</th>
                                                            <th scope="col ">{t('home.Courier Traking URL')}</th>
                                                            {/* <th scope="col ">{t('home.Booking Date')}</th> */}
                                                            <th scope="col ">{t('home.Donation Contribution')}</th>

                                                            <th scope="col " style={{ width: '10%' }}>{t('home.Action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cancelOrders.map((info, ii) => {
                                                            return (
                                                                <tr>
                                                                    <td>{info.created_on}</td>
                                                                    <td>
                                                                        {/* {info.order_id_main} */}
                                                                        <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            info.total_events * 1 ?
                                                                                <>{info.total_events * 1 + " " + t('home.Events')}</>
                                                                                : ""
                                                                        }&nbsp;&nbsp;
                                            <br />

                                                                        {info.events}<br />
                                                                        {
                                                                            info.total_machine_test * 1 ?
                                                                                <>
                                                                                    {info.total_machine_test * 1 + " " + t('home.Test')}</> : ""}&nbsp;&nbsp;<br />
                                                                        {info.machine_test}
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {info.total_products * 1 ?
                                                                                <>
                                                                                    {info.total_products * 1 + " " + t('home.Products')}
                                                                                </> : ""}
                                                                            &nbsp;&nbsp;
                                                                            </p>
                                                                        {info.products}
                                                                        <p>
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        {info.total_products * 1 + " " + t('home.Manashakti Magazine')}
                                                                                    </> :
                                                                                    ""
                                                                            }&nbsp;&nbsp;
                                                                        </p>
                                                                        {info.package}
                                                                    </td>
                                                                    <td>{info.courier_date_format}</td>
                                                                    <td>{info.status}</td>
                                                                    <td>
                                                                        <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                    </td>
                                                                    <td>{t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}</td>

                                                                    <td>

                                                                        <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                        <br />
                                                                        {/* {info.arr_order_group_by_trust.map((trust, i) => {
                                                            if(i == 0){
                                                                var invoice_type = "invoice_A";
                                                                var invoice_name = "Invoice A";
                                                            }else if(i == 1){
                                                                var invoice_type = "invoice_B";
                                                                var invoice_name = "Invoice B";
                                                            }else if(i == 2){
                                                                var invoice_type = "invoice_C";
                                                                var invoice_name = "Invoice C";
                                                            }else{
                                                                var invoice_type = "invoice_D";
                                                                var invoice_name = "Invoice D";
                                                            } 
                                                           
                                                                return(
                                                                    <p>
                                                                    <a target="_blank" href={myConstClass.BASE_URL+'invoice/downloadOrderInvoice/'+invoice_type+'/'+Base64.encode(info.order_id)} >{invoice_name}</a>    
                                                                    </p>
                                                                    )
                                                            })
                                                        } */}
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>

                                            <div className="d-xl-none d-lg-none d-block">
                                                <ListGroup>
                                                    {
                                                        cancelOrders.map((info, ii) =>

                                                            <ListGroupItem key={info.order_id} className="p-0 my-2">
                                                                <div className="bg-light py-3 px-3 clearfix" >
                                                                    <h6 className="float-left">
                                                                        {info.created_on}
                                                                    </h6>
                                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id={'orderDetails_' + info.order_id} />
                                                                </div>
                                                                <UncontrolledCollapse toggler={'orderDetails_' + info.order_id} className="px-3 py-3">
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Placed On')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.created_on}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Order ID')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id} style={{ cursor: 'pointer' }}>{info.order_id_main}</Link>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                    {
                                                                            info.total_events * 1 || info.total_machine_test * 1?
                                                                                <>
                                                                                    <Label className="font-weight-bold">
                                                                                        {t('home.Events')}&nbsp;:&nbsp;
                                                                                    </Label>
                                                                                    <br />
                                                                                </> : ''
                                                                        }
                                                                        <Label>
                                                                        {info.total_events * 1 != 0 ? info.total_events * 1 : ''}&nbsp;&nbsp;
                                                                            {info.total_events * 1 != 0 ? <>{t('home.Events')}<br /></> : ''}

                                                                            {info.events}<br />
                                                                            {info.total_machine_test * 1 ? info.total_machine_test * 1 : ''}&nbsp;&nbsp;{info.total_machine_test * 1 ? <>{t('home.Test')}<br /> </> : ''}
                                                                            {info.machine_test}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                    {info.total_products * 1 ?
                                                                            <>
                                                                                <Label className="font-weight-bold">
                                                                                    {t('home.Products')}&nbsp;:&nbsp;
                                                                                </Label>
                                                                                <br />
                                                                            </> : ''}
                                                                            <Label>
                                                                            {
                                                                                info.total_products * 1 ?
                                                                                    <>
                                                                                        <p>
                                                                                            {info.total_products * 1 ? info.total_products * 1 : ''}&nbsp;&nbsp;
                                                                                {info.total_products * 1 ? t('home.Products') : ''}
                                                                                        </p>
                                                                                        {info.products}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                info.total_magazines * 1 ?
                                                                                    <>
                                                                                        <p>

                                                                                            {info.total_magazines * 1 ? info.total_magazines * 1 : ''}&nbsp;&nbsp;
                                                        {info.total_magazines * 1 ? t('home.Manashakti Magazine') : ''}</p>
                                                                                        {info.package}
                                                                                    </> : ""
                                                                            }

                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Expected Date of Product Delivery')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label sm="6" xs="6">
                                                                            {info.courier_date}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Status')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {info.status}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Courier Traking URL')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <a target="_blank" href={info.courier_url}>{info.courier_url}</a>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <Label>
                                                                            {t('home.INR')}{info.total_price * 1 + info.event_total_price * 1 + info.test_total_price * 1 + info.magazine_total_price * 1 + info.shipping_charges * 1}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className="font-weight-bold">
                                                                            {t('home.Action')}&nbsp;:&nbsp;
                                                                        </Label>
                                                                        <br />
                                                                        <Label>
                                                                            <Link to={'/order-details/' + info.order_id}><i className="fa fa-eye" style={{ cursor: 'pointer' }}></i></Link>
                                                                            <br />
                                                                            {info.arr_order_group_by_trust.map((trust, i) => {
                                                                                if (trust.trust_id_fk == 1) {
                                                                                    var invoice_type = "invoice_A";
                                                                                    var invoice_name = "Receipt A";
                                                                                } else if (trust.trust_id_fk == 2) {
                                                                                    var invoice_type = "invoice_B";
                                                                                    var invoice_name = "Receipt B";
                                                                                } else if (trust.trust_id_fk == 3) {
                                                                                    var invoice_type = "invoice_C";
                                                                                    var invoice_name = "Receipt C";
                                                                                } else {
                                                                                    var invoice_type = "invoice_D";
                                                                                    var invoice_name = "Receipt D";
                                                                                }
                                                                                return (
                                                                                    <p>
                                                                                        <a target="_blank" href={myConstClass.BASE_URL + 'invoice/downloadOrderInvoice/' + invoice_type + '/' + Base64.encode(info.order_id)} >{invoice_name}</a>
                                                                                    </p>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Label>
                                                                    </FormGroup>
                                                                </UncontrolledCollapse>
                                                            </ListGroupItem>


                                                        )

                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}
Orders.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Orders);
