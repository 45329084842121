import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import icon from './assets/images/calendar.png';
import call from './assets/images/call.png';
import mail from './assets/images/mail.png';
import address from './assets/images/address.png';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Media } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';

import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ListGroup,
    ListGroupItem,
    UncontrolledCollapse
} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';
import checkoutImage from './assets/images/mycart.png';
import ContactForm from './components/ContactForm.jsx';


// ...or load this specific CSS file using a <link> tag in your document

const intAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'doc', 'docx', 'bmp', 'tif', 'pdf', 'xls', 'xlsx', 'odt'];
const intUploadFolderFlag = 0;
const initMultiple = false;
const intItemLimit = 1;


class Contactus extends Component {

    constructor(props) {

        super(props);
        const { allowedExtensions, multiple, itemLimit } = this.props;
        this.product_id = 0;
        this.state = {
            branchState: [],
            branchStateAll: [],
            pictures: [],
            arrMagazine: [],
            arr_userInfo: [],
            profile_picture: '',
            userinfo: {
                profile_picture: 'profile.jpg',
            },
            startDate: '',
            flag: 0,
            guestinfo: [],
            guestinfo: {


            },
            eventinfo: {


            },
            removeinfo: {
                message: '',
                status: '',
            },
            country: [],
            city: [],
            mhState: [],
            mhCityAll: [],
            mhOutState: [],
            mhOutStateAll: [],
            inOutState: [],
            inOutStateAll: [],
        }


        this.validator = new SimpleReactValidator();
        //   this.goToEdit = this.goToEdit.bind(this);
        var user_id = localStorage.getItem('user_id');

        this.applyFilterBranch = this.applyFilterBranch.bind(this);
        this.applyFilterCoutIn = this.applyFilterCoutIn.bind(this);

    }




    componentDidMount() {

        var user_id = localStorage.getItem('user_id');
        // this.getCountry();

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))
            .catch(err => console.log(err));


        // API for branches
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateBranches' + '/' + getLanguage() + '/22')
            .then(response => response.json())
            .then(response => this.setState({ branchState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'cms_contact/getStateAllBranches' + '/' + getLanguage() + '/22')
            .then(response => response.json())
            .then(response => this.setState({ branchStateAll: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        // API for Centres in MH
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterInMaharashtra' + '/' + getLanguage() + '/22/2763')
            .then(response => response.json())
            .then(response => this.setState({ mhState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'cms_contact/getCityAll' + '/' + getLanguage() + '/22/2')
            .then(response => response.json())
            .then(response => this.setState({ mhCityAll: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        // API for Centres in India outside MH
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterOutsideMaharashtra' + '/' + getLanguage() + '/22')
            .then(response => response.json())
            .then(response => this.setState({ mhOutState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'cms_contact/getStateAllOutsideOfMaharashtra' + '/' + getLanguage() + '/22')
            .then(response => response.json())
            .then(response => this.setState({ mhOutStateAll: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        // API for Centres outside India
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterOutsideIndia' + '/' + getLanguage() + '/101')
            .then(response => response.json())
            .then(response => this.setState({ inOutState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'cms_contact/getCountryAllOutsideOfIndia' + '/' + getLanguage() + '/101')
            .then(response => response.json())
            .then(response => this.setState({ inOutStateAll: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))
    }

    applyFilterBranch = () => {
        var state_id = $('#branch_state_id').val();
        if (state_id == '' || state_id == undefined) {
            state_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateBranches' + '/' + getLanguage() + '/' + state_id)
            .then(response => response.json())
            .then(response => this.setState({ branchState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))
    }

    applyFilterCin = () => {
        var city_id = $('#cin_city_id').val();
        if (city_id == '' || city_id == undefined) {
            city_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterInMaharashtra' + '/' + getLanguage() + '/22/' + city_id)
            .then(response => response.json())
            .then(response => this.setState({ mhState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))
    }

    applyFilterCOutMh = () => {
        var state_id = $('#comh_state_id').val();
        if (state_id == '' || state_id == undefined) {
            state_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterOutsideMaharashtra' + '/' + getLanguage() + '/22/' + state_id)
            .then(response => response.json())
            .then(response => this.setState({ mhOutState: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))
    }

    applyFilterCoutIn() {
        var country_id = $('#coin_country_id').val();
        if (country_id == '' || country_id == undefined) {
            country_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'cms_contact/getStateCenterOutsideIndia' + '/' + getLanguage() + '/101/' + country_id)
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.setState({ inOutState: response })
            })
            .catch(err => console.log(err))

            .catch(err => console.log(err))
    }

    getCityStateWise = _ => {
        var shipping_stateid = $('#state').val();
        // alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getCityStateWise/' + shipping_stateid)
            .then(response => response.json())
            .then(response => this.setState({ city: response }))
            .catch(err => console.log(err))

    }


    getStateCountryWise = _ => {
        var shipping_countryid = $('#country').val();
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))

    }
    getStateCountryWiseEdit(shipping_countryid) {
        //var shipping_countryid = $('#country').val();
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))

    }

    getCityStateWiseEdit(shipping_stateid) {
        fetch(myConstClass.BASE_URL + 'getCityStateWise/' + shipping_stateid)
            .then(response => response.json())
            .then(response => this.setState({ city: response }))
            .catch(err => console.log(err))
    }

    getCountry = _ => {
        fetch(myConstClass.BASE_URL + 'getAllCountry')
            .then(response => response.json())
            .then(response => this.setState({ country: response }))
            .catch(err => console.log(err))
        // console.log("this.setState.data",this.state.country)

    }

    componentDidUpdate(prevProps) {

        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

        }
    }




    render() {

        const { arrMagazine, userinfo, country, shipping_countrystate, city, eventinfo, posts, guestinfo, toast, branchState, branchStateAll, mhState, mhCityAll, mhOutState, mhOutStateAll, inOutState, inOutStateAll } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                <Header />

                {/* Desktop Design */}
                <div class="container-fluid d-xl-block d-lg-block d-md-block d-none">

                    <div class="container-fluid ">
                        <div class="row">
                            <div class="col-lg-12 " id="about_nav_bar">
                                <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                    <nav aria-label="breadcrumb" id="breadcrumb">
                                        <ol class="breadcrumb bg-white pb-0 mb-0 ml-n2 pl-2">
                                            <li class="breadcrumb-item ">
                                                <Link to="/">{t('home.home')}</Link>
                                            </li>
                                            <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0)">

                                                {t('home.Contact Us')}
                                            </a></li>
                                        </ol>
                                        <p>{t('home.Contact Us')}</p>
                                        <div>
                                            <Link to="/contact-us">
                                                <button type="button" class="btn btn-link active_2  pl-0">{t('home.MAIN CENTER')}</button>
                                            </Link>
                                            <Link to="/branches">
                                                <button type="button" class="btn btn-link orangeColor">{t('home.BRANCHES')}</button>
                                            </Link>
                                            <Link to="/centers-in-maharashtra">
                                                <button type="button" class="btn btn-link orangeColor">{t('home.CENTRES IN MAHARASHTRA')}</button>
                                            </Link>
                                            <Link to="/centers-outside-of-maharashtra">
                                                <button type="button" class="btn btn-link orangeColor">{t('home.CENTRES OUTSIDE MAHARASHTRA')}</button>
                                            </Link>
                                            <Link to="/centers-outside-india">
                                                <button type="button" class="btn btn-link orangeColor">{t('home.CENTRES OUTSIDE INDIA')}</button>
                                            </Link>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row py-1 ">
                                    <h4 class=""><b> {t('home.Manashakti ResearchCenter, Lonavala contactus')}</b>
                                    </h4>
                                        <ContactForm />
                                        {/* <p style={{ marginTop: '3px' }}>
                                            {t('home.contactus main address')}
                                            <br />
                                            <span style={{ marginTop: '2px', position: 'absolute' }}>
                                                {t('home.contactus main email')}
                                            </span>
                                        </p> */}

                                    
                                </div>
                                <div class="row pb-1 mt-2">
                                    <div class="col-lg-3 col-md-6 col-sm-12 p-0">
                                        <div class="p-0">
                                            <h6> <b>{t('home.MAIN OFFICE')}</b></h6>
                                            <p>{t('home.Phone')}: {t('home.02114 - 234330, 234331')}<br />
                                                {t('home.Mb')}. {t('home.main_office_mobile')}<br />
                                                {t('home.main_office_time')}<br />
                                                {t('home.main_office_email')}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12  p-0 " >
                                        <div class="py-0 pl-0 pr-2">
                                            <h6> <b>
                                                {t('home.REGISTRATION OF EVENTS')}
                                            </b></h6>
                                            <p style={{ wordBreak: 'break-all' }}>
                                                {t('home.event_time')}
                                                <br />
                                                {t('home.Mb')}. {t('home.event_mobile')}<br />
                                                {t('home.event_time_only')} <br />
                                                {t('home.Email')}:&nbsp;{t('home.manashakti_navnond@gmail_com')}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-md-6 col-sm-12 p-0">
                                        <div class="p-0">
                                            <h6> <b>
                                                {t('home.REGISTRATION OF MACHINE TESTS')}

                                            </b></h6>
                                            <p>
                                                {t('home.+91-2114-234320, 234321, 8975157035')}<br />
                                                {t('home.Mb')}. {t('home.machine_test_mobile')}
                                                <br />
                                                {t('home.machine_test_time')}<br />
                                                {t('home.Email')}:&nbsp; {t('home.manashaktitest@gmail_com')}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12 p-0 ">
                                        <div class="p-0">
                                            <h6> <b>
                                                {t('home.MAGAZINE SUBSCRIPTION')}
                                            </b></h6>
                                            <p>{t('home.magazine_phone_time')}
                                                <br />
                                                {t('home.Mb')}. {t('home.magazine_mobile')}<br />
                                                {t('home.magazine_time')}<br />
                                                {t('home.Email')}:&nbsp; {t('home.magazine_email')}<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                {/* <div class="row py-2">
                                    <img src={mail} width="24px" class="mr-2" /> <b>{t('home.Email')} : </b>
                                    <spam class="ml-4"> {t('home.gsk@manashakti_org')}</spam>
                                </div>

                                <div class="row py-3">
                                    <img src={address} width="24px" class="mr-2" /> <b>{t('home.Address')} : </b>
                                    <div class="col-lg-4 col-md-6 col-sm-12 p-0 ml-2">
                                        <span class="">{t('home.manashakti_rest_new_way_address')}
                                        </span>
                                    </div>
                                </div>
                            */}

                                <div class="row py-3">
                                    <div class="col-md-6 col-lg-6 col-sm-12 ">
                                        <p class="ml-2"><b>
                                            {t('home.How to reach Manashakti Research Center')}
                                        </b></p>
                                        <div>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.8876748992466!2d73.43092531437212!3d18.758553666749556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be801b0f5b96829%3A0x7c2597b76084838a!2sManashakti%20Research%20Centre!5e0!3m2!1sen!2sin!4v1574102875505!5m2!1sen!2sin"
                                                frameborder="0"
                                                allowfullscreen="" style={{ border: '0', width: '100%', height: '350px' }}></iframe>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-sm-12">
                                        <p class="ml-2"><b>
                                            {t('home.How to reach Manashanti Ashram, Lonavala')}
                                        </b></p>
                                        <div>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.7461478380114!2d73.43939221437226!3d18.764872666555576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be801ca0992fb25%3A0x79f7b4fd4244ece0!2sManashanti%20New%20Way%20Ashram!5e0!3m2!1sen!2sin!4v1574102761465!5m2!1sen!2sin"
                                                frameborder="0" style={{ border: '0', width: '100%', height: '350px' }}
                                                allowfullscreen=""></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-3" id="eventRightMenuMain">
                                {arrMagazine.map((cell, i) => {
                                    if (cell.magazine_id == 2) {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}
                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>
                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                        return (
                                                                            <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/magazine'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                            )}
                                                            <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}
                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>
                                                            {cell.language}
                                                        </p>
                                                        <p>
                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                } else {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}


                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Design */}
                <div className="container-fluid d-xl-none d-lg-none d-md-none d-block my-5 ">
                    <ListGroup className="overflow-auto">
                        {/* Main center */}
                        <ListGroupItem className="p-0 mb-2">
                            <div className="bg-light py-3 px-3 clearfix" >
                                <h6 className="float-left">
                                    {t('home.MAIN CENTER')}
                                </h6>
                                <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id='mainCenter' />
                            </div>
                            <UncontrolledCollapse toggler='mainCenter' className="px-4 py-4">
                                <div class="row py-1 ">
                                    <h4 class="">{t('home.Manashakti ResearchCenter, Lonavala contactus')}</h4>
                                    <p style={{ marginTop: '3px' }}>
                                        {t('home.contactus main address')}
                                        <br />
                                        <span style={{ marginTop: '2px', position: 'absolute' }}>
                                            {t('home.contactus main email')}
                                        </span>
                                    </p>
                                </div>
                                <div class="row pb-1 ">
                                    <div class="col-lg-3 col-md-6 col-sm-12 p-0">
                                        <div class="p-0 mt-3">
                                            <h6> <b>{t('home.MAIN OFFICE')}</b></h6>
                                            <p>{t('home.Phone')}: {t('home.02114 - 234330, 234331')}<br />
                                                {t('home.Mb')}. {t('home.main_office_mobile')}<br />
                                                {t('home.main_office_time')}<br />
                                                {t('home.main_office_email')}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12  p-0 " >
                                        <div class="py-0 pl-0 pr-2">
                                            <h6> <b>
                                                {t('home.REGISTRATION OF EVENTS')}
                                            </b></h6>
                                            <p style={{ wordBreak: 'break-all' }}>
                                                {t('home.event_time')}
                                                <br />
                                                {t('home.Mb')}. {t('home.event_mobile')}<br />
                                                {t('home.event_time_only')} <br />
                                                {t('home.Email')}:&nbsp;{t('home.manashakti_navnond@gmail_com')}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 col-sm-12 p-0">
                                        <div class="p-0">
                                            <h6> <b>
                                                {t('home.REGISTRATION OF MACHINE TESTS')}

                                            </b></h6>
                                            <p>
                                                {t('home.+91-2114-234320, 234321, 8975157035')}<br />
                                                {t('home.Mb')}. {t('home.machine_test_mobile')}
                                                <br />
                                                {t('home.machine_test_time')}<br />
                                                {t('home.Email')}:&nbsp; {t('home.manashaktitest@gmail_com')}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 col-sm-12 p-0 ">
                                        <div class="p-0">
                                            <h6> <b>
                                                {t('home.MAGAZINE SUBSCRIPTION')}
                                            </b></h6>
                                            <p>{t('home.magazine_phone_time')}
                                                <br />
                                                {t('home.Mb')}. {t('home.magazine_mobile')}<br />
                                                {t('home.magazine_time')}<br />
                                                {t('home.Email')}:&nbsp; {t('home.magazine_email')}<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>




                                <div class="row py-3">
                                    <div class="col-md-6 col-lg-6 col-sm-12 ">
                                        <p class="ml-2"><b>
                                            {t('home.How to reach Manashakti Research Center')}
                                        </b></p>
                                        <div>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.8876748992466!2d73.43092531437212!3d18.758553666749556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be801b0f5b96829%3A0x7c2597b76084838a!2sManashakti%20Research%20Centre!5e0!3m2!1sen!2sin!4v1574102875505!5m2!1sen!2sin"
                                                frameborder="0"
                                                allowfullscreen="" style={{ border: '0', width: '100%', height: '350px' }}></iframe>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-sm-12">
                                        <p class="ml-2"><b>
                                            {t('home.How to reach Manashanti Ashram, Lonavala')}
                                        </b></p>
                                        <div>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.7461478380114!2d73.43939221437226!3d18.764872666555576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be801ca0992fb25%3A0x79f7b4fd4244ece0!2sManashanti%20New%20Way%20Ashram!5e0!3m2!1sen!2sin!4v1574102761465!5m2!1sen!2sin"
                                                frameborder="0" style={{ border: '0', width: '100%', height: '350px' }}
                                                allowfullscreen=""></iframe>
                                        </div>
                                    </div>
                                </div>
                            </UncontrolledCollapse>
                        </ListGroupItem>


                        {/* Branches */}
                        <ListGroupItem className="p-0 mb-2">
                            <div className="row bg-light py-3 px-3 clearfix" >
                                <div className="col-10">
                                    <h6 className="float-left">
                                        {t('home.BRANCHES')}
                                    </h6>
                                </div>
                                <div className="col-2">
                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id='branches' />
                                </div>
                            </div>
                            <UncontrolledCollapse toggler='branches' className="px-4 py-4">
                                {/* <FormGroup>
                                    <Label>{t('home.Select State')}</Label>
                                    <Input type="select" className="border-top-0 border-left-0 border-right-0" id="branch_state_id" name="branch_state_id" onChange={this.applyFilterBranch}>
                                        <option value="">{t('home.Select State')}</option>
                                        {branchStateAll.map((info, ii) => {
                                            if (this.state.current_state == info.id) {
                                                return (
                                                    <option value={info.id} selected="selected">{info.name}</option>
                                                )
                                            } else {
                                                return (
                                                    <option value={info.id}>{info.name}</option>
                                                )
                                            }

                                        })
                                        }
                                    </Input>
                                </FormGroup> */}

                                {branchState.map((state, i) => {
                                    return (

                                        <span>
                                            {state.arr_city.map((city, ii) => {
                                                return (
                                                    <div class="row">
                                                        <table class="views-table cols-3" style={{ minWidth: '100%' }}>
                                                            <thead>
                                                                <th>
                                                                    <h4 style={{ color: '#ed9a16' }}>{city.name}</h4>
                                                                </th>
                                                            </thead>
                                                            <tbody>
                                                                {city.arr_branches.map((branch, iii) => {
                                                                    if (branch.display_title != '') {
                                                                        return (

                                                                            <tr class="odd views-row-first">
                                                                                <td class="views-field views-field-body" style={{ width: '75%', borderBottom: '1px solid #dee2e6' }}>
                                                                                    <span></span><span>
                                                                                        <h4 style={{ color: '#9d0b0e' }}>{branch.display_title}</h4>
                                                                                        <br />
                                                                                        <p>
                                                                                            {branch.address}
                                                                                        </p>
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left', verticalAlign: 'top' }} class="views-field views-field-field-contact-number-value">
                                                                                    <p>{branch.mobile}</p>
                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    } else {
                                                                        return (

                                                                            <tr class="odd views-row-first">
                                                                                <td class="views-field views-field-body" style={{ width: '75%', borderBottom: '1px solid #dee2e6' }}>
                                                                                    <span>
                                                                                        <p>
                                                                                            {branch.address}
                                                                                        </p>
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left', verticalAlign: 'top' }} class="views-field views-field-field-contact-number-value">
                                                                                    <p>{branch.mobile}</p>
                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    }

                                                                })
                                                                }
                                                                {city.arr_centers.map((centers, iii) => {
                                                                    return (


                                                                        <tr class="even">
                                                                            <td style={{ width: '75%', borderBottom: '1px solid #dee2e6' }} class="views-field views-field-body">
                                                                                <span> <p>{centers.contact_person}</p>
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left', verticleAlign: 'top' }} class="views-field views-field-field-contact-number-value">
                                                                                <p>{centers.mobile}</p>
                                                                            </td>
                                                                            {/* <td class="views-field views-field-edit-node">
                                    </td> */}
                                                                        </tr>


                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                            }
                                        </span>
                                    )
                                })
                                }</UncontrolledCollapse>
                        </ListGroupItem>

                        {/* Centres in MH*/}
                        <ListGroupItem className="p-0 mb-2">
                            <div className="row bg-light py-3 px-3 clearfix">
                                <div className="col-10">
                                    <h6 className="float-left">
                                        {t('home.CENTRES IN MAHARASHTRA')}
                                    </h6>
                                </div>
                                <div className="col-2">
                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id='cinmh' />
                                </div>
                            </div>
                            <UncontrolledCollapse toggler='cinmh' className="px-4 py-4">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">{t('home.Select City')}</label>
                                                <select class="form-control" id="cin_city_id" name="city_id" onChange={this.applyFilterCin}>
                                                    <option value="">{t('home.Select City')}</option>
                                                    {mhCityAll.map((info, ii) => {
                                                        if (info.id == 2763) {
                                                            return (
                                                                <option value={info.id} selected="selected">{info.name}</option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={info.id}>{info.name}</option>
                                                            )
                                                        }

                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {mhState.map((state, i) => {
                                    return (

                                        <span>




                                            {state.arr_city.map((city, ii) => {
                                                return (
                                                    <div class="row">
                                                        <table class="views-table cols-3" style={{ minWidth: '100%' }}>
                                                            <thead>
                                                                <th>
                                                                    <h4 style={{ color: '#ed9a16' }}>{city.name}</h4>
                                                                </th>
                                                            </thead>
                                                            <tbody>

                                                                {city.arr_centers.map((centers, iii) => {
                                                                    return (


                                                                        <tr class="even">
                                                                            <td style={{ width: '75%', borderBottom: '1px solid #dee2e6' }} class="views-field views-field-body">
                                                                                <span> <p>{centers.contact_person}</p>
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left' }} class="views-field views-field-field-contact-number-value">
                                                                                <p>{centers.mobile}</p>
                                                                            </td>

                                                                        </tr>


                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                            }


                                        </span>
                                    )
                                })
                                }</UncontrolledCollapse>
                        </ListGroupItem>

                        {/* Centres in IN*/}
                        <ListGroupItem className="p-0 mb-2">
                            <div className="row bg-light py-3 px-3 clearfix" >
                                <div className="col-10">
                                    <h6 className="float-left">
                                        {t('home.CENTRES OUTSIDE MAHARASHTRA')}
                                    </h6>
                                </div>
                                <div className="col-2">
                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id='comh' />
                                </div>
                            </div>
                            <UncontrolledCollapse toggler='comh' className="px-4 py-4">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">{t('home.Select State')}</label>
                                                <select class="form-control" id="comh_state_id" name="state_id" onChange={this.applyFilterCOutMh}>
                                                    <option value="">{t('home.Select State')}</option>
                                                    {mhOutStateAll.map((info, ii) => {
                                                        if (info.id == 2763) {
                                                            return (
                                                                <option value={info.id} selected="selected">{info.name}</option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={info.id}>{info.name}</option>
                                                            )
                                                        }

                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {mhOutState.map((state, i) => {
                                    return (

                                        <span>
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <h4>{state.name} </h4>
                                                </div>
                                            </div>


                                            {state.arr_city.map((city, ii) => {
                                                return (
                                                    <div class="row">
                                                        <table class="views-table cols-3" style={{ minWidth: '100%' }}>
                                                            <thead>
                                                                <th>
                                                                    <h4 style={{ color: '#ed9a16' }}>{city.name}</h4>
                                                                </th>
                                                            </thead>
                                                            <tbody>

                                                                {city.arr_centers.map((centers, iii) => {
                                                                    return (


                                                                        <tr class="even">
                                                                            <td style={{ width: '75%', borderBottom: '1px solid #dee2e6' }} class="views-field views-field-body">
                                                                                <span> <p>{centers.contact_person}</p>
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left' }} class="views-field views-field-field-contact-number-value">
                                                                                <p>{centers.mobile}</p>
                                                                            </td>

                                                                        </tr>


                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                            }

                                            {/* <div class="row">
                    {state.arr_city.map((city, ii) => {
                         var str = city.name;
                         var city_name = str.charAt(0);
                         return(     
                    <label>
                   
                            <div class="col-lg-12 col-md-12 col-sm-12 pl-0">
                            <div class="col-12 border border-dark mt-1 mb-3 mr-1 OUTSIDE_center ">
                                <div class="initial_character ">
                                    <h5>{city_name}</h5>
                                </div>
                                {city.arr_centers.map((centers, iii) => {
                                    if(iii == 0){
                                        return(
                                        <div class="ml-5">
                                            <h4 class="pt-3 pb-1">{city.name}</h4>
                                            <p>
                                                <span> {centers.contact_person} </span> <span class="ml-3">{centers.mobile} </span>

                                            </p>
                                        </div>
                                        )
                                    }else{
                                        return(
                                        <div class="ml-5">
                                            
                                            <p>
                                                <span> {centers.contact_person} </span> <span class="ml-3">{centers.mobile} </span>

                                            </p>
                                        </div>
                                        ) 
                                    }
                               
                                
                                    
                                })
                                }
                            </div>
                            </div>
                                    
                            </label>
                        
                                ) 
                            })
                        }
                        </div> */}
                                        </span>
                                    )
                                })
                                }</UncontrolledCollapse>
                        </ListGroupItem>

                        {/* Centres in Outside IN*/}
                        <ListGroupItem className="p-0 mb-2">
                            <div className="row bg-light py-3 px-3 clearfix">
                                <div className="col-10">
                                    <h6 className="float-left">
                                        {t('home.CENTRES OUTSIDE INDIA')}
                                    </h6>
                                </div>
                                <div className="col-2">
                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id='coi' />
                                </div>
                            </div>
                            <UncontrolledCollapse toggler='coi' className="px-4 py-4">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">{t('home.Select Country')}</label>
                                                <select class="form-control" id="coin_country_id" name="country_id" onChange={this.applyFilterCoutIn}>
                                                    <option value="">{t('home.Select Country')}</option>
                                                    {inOutStateAll.map((info, ii) => {
                                                        if (info.id == 2763) {
                                                            return (
                                                                <option value={info.id} selected="selected">{info.name}</option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={info.id}>{info.name}</option>
                                                            )
                                                        }

                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {inOutState.map((state, i) => {
                                    return (
                                        <span>
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <h4>{state.name} </h4>
                                                </div>
                                            </div>

                                            {state.arr_city.map((city, ii) => {
                                                return (
                                                    <div class="row">
                                                        <table class="views-table cols-3" style={{ minWidth: '100%' }}>
                                                            <thead>
                                                                <th>
                                                                    <h4 style={{ color: '#ed9a16' }}>{city.name}</h4>
                                                                </th>
                                                            </thead>
                                                            <tbody>

                                                                {city.arr_centers.map((centers, iii) => {
                                                                    return (


                                                                        <tr class="even">
                                                                            <td style={{ width: '75%', borderBottom: '1px solid #dee2e6' }} class="views-field views-field-body">
                                                                                <span> <p>{centers.contact_person}</p>
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderBottom: '1px solid #dee2e6', textAlign: 'left' }} class="views-field views-field-field-contact-number-value">
                                                                                <p>{centers.mobile}</p>
                                                                            </td>

                                                                        </tr>


                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                            }

                                        </span>
                                    )
                                })
                                }
                            </UncontrolledCollapse>
                        </ListGroupItem>

                    </ListGroup>
                </div>
                <Footer />
            </div>
        );
    }
}
Contactus.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Contactus);

