import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./ContactForm.css";
import { toast } from "react-toastify";
const BASE_URL = "https://prepod.manashakti.org/api/contact-us";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/^\d+$/, "Phone number must be digits")
    .required("Phone number is required"),
  message: yup.string().required("Message is required"),
});

export default function ContactForm() {
  const [submitStatus, setSubmitStatus] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
    //   const response = await axios.post("http://localhost:4000/api/contact-us", data);
      const response = await axios.post(`${BASE_URL}`, data);
      setSubmitStatus("Message sent successfully!");
      reset();
      if (response?.data?.data) {
        toast.success('Message send successfully');
      } else {
        toast.error('Internal server error');
      }
      const res = await response.json();
      console.log('res2: ', res);
    } catch (error) {
      setSubmitStatus("Error sending message. Please try again.");
    }
  };

  return (
    <div className="contact-container-unique">
      <div className="contact-left-unique">
        <h2>Let's Talk</h2>
        {/* <h3>Manashakti Research Centre, Lonavala.</h3> */}
        <p className="mt-4">
          <strong>LOCATION</strong>
        </p>
        <p className="address">
          NEW WAY UDYOGSHAKTI CHARITABLE TRUST, <br/>S NO 21 A B GAN NO 70 1,
          MANSHAKTI CAMPUS PUNE MUMBAI ROAD, VARSOLI, PUNE,<br/> MAHARASHTRA, 410403
          INDIA
        </p>
        <p>
          <strong>EMAIL US</strong>
        </p>
        {/* <p>gsk@manashakti.org</p> */}
        {/* <p>store@manashakti.org</p> */}
        <a className="contact-details-mail-and-phone" href={`mailto:gsk@manashakti.org`}>gsk@manashakti.org</a>
        <a className="contact-details-mail-and-phone" href={`mailto:store@manashakti.org`}>store@manashakti.org</a>
        <br />

        <p>
          <strong>CALL US</strong>
        </p>
        {/* <p>+91 9619132401</p> */}
        <a className="contact-details-mail-and-phone" href={`tel:9619132401`}>+91 9619132401</a>
      </div>

      <div className="contact-right-unique">
        <h2>Send a Message</h2>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-with-name-and-phone">
            <div>
              <label className="input-label-unique">Name<span className="required-star">*</span></label>
              <input
                type="text"
                maxLength={60}
                placeholder="Name"
                {...register("name")}
                className="input-half-unique"
              />
              {errors.name && (
                <p className="error-message">{errors.name.message}</p>
              )}
            </div>

            <div>
              <label className="input-label-unique">Phone<span className="required-star">*</span></label>
              <input
                type="text"
                maxLength={12}
                placeholder="Phone"
                {...register("phone")}
                className="input-half-unique"
              />
              {errors.phone && (
                <p className="error-message">{errors.phone.message}</p>
              )}
            </div>
          </div>

          <div className="form-group-unique" style={{ marginTop: "10px" }}>
            <label className="input-label-unique">Email<span className="required-star">*</span></label>
            <input
              type="email"
              maxLength={60}
              placeholder="Email"
              {...register("email")}
              className="input-full-unique"
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
          </div>
          <div className="form-group-unique">
            <label className="input-label-unique">Message<span className="required-star">*</span></label>
            <textarea
              maxLength={1000}
              placeholder="Message"
              {...register("message")}
              className="textarea-unique"
            />
            {errors.message && (
              <p className="error-message">{errors.message.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="submit-button-unique"
            disabled={isSubmitting}
          >
            Send Message
          </button>
        </form>
        {/* {submitStatus && <p className="submit-status">{submitStatus}</p>} */}
      </div>
    </div>
  );
}
