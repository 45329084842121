import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor} from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Translation Higher Order Component
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,  
    Label,
    Row,
    
  } from 'reactstrap';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';



// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// //import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

class Eshopping extends Component {
    constructor(props) {
      super(props);
      this.event_id=0;
      this.state = {
        faq_id:'1',
        posts: [],
        types :[],
        english_pdf_url:'',
        hindi_pdf_url:'',
        marathi_pdf_url:'',
        selected:3,
        scheduleEvent:[],
        eventtopic:[],
        flag:0,
        review:[],
        feedback:[],
        arrVideo:[],
        arrImages:[],
        arrSection:[],
        cartEvent:[],
        arrMagazine:[],
        machineCartEvent:[],
        guestinfo: {
          pdfUrl:'',
            type_id: '',
            no_of_pages: '',
            total_like:'',
            total_review:'',
            title: '',
            author:'',
            quantity:'',
            price:'',
            description:'',
            images_array:[],
            arr_event_languages:[],
            current_event_type_id:'',
          },
          editorState: EditorState.createEmpty(),
      }
      this.handleChange = this.handleChange.bind(this);
      this.submitReview = this.submitReview.bind(this);
      this.submitLike = this.submitLike.bind(this);

      this.readMore = this.readMore.bind(this);
      this.readLess = this.readLess.bind(this);

      this.showMore = this.showMore.bind(this);
      this.showLess = this.showLess.bind(this);
      this.showallReview = this.showallReview.bind(this);

      this.showMoreFeedback = this.showMoreFeedback.bind(this);
      this.showLessFeedback = this.showLessFeedback.bind(this);
      this.changeURL = this.changeURL.bind(this);
  }

  changeURL(){
    var radioValue = $("input[name='gridRadios']:checked").val();
    var english_pdf_url = this.state.english_pdf_url;
    var hindi_pdf_url = this.state.hindi_pdf_url;
    var marathi_pdf_url = this.state.marathi_pdf_url;
    if(radioValue == 1){
      window.open(marathi_pdf_url,'_blank');
    }else if(radioValue == 2){
      window.open(hindi_pdf_url,'_blank');
    }else{
      window.open(english_pdf_url,'_blank');
    }
    
    
  }

  showallReview(){
    $('#ShowAllReviewDiv').css('display','none');
    const answer_array = this.props.location.pathname.split('/');
    fetch(myConstClass.BASE_URL+'review/getNewsReviewFrontEnd/'+answer_array['2']+'/200')
    .then(response => response.json())
    .then(response =>this.setState({review: response}))
    .catch(err => console.log(err))
          
}


  showMore(e){
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_'+review_id).css('display','none');
    $('#showLessDiv_'+review_id).css('display','block');
  }

  showLess(e){
   var review_id_all = e.target.id;
   var arr_review_id = review_id_all.split('_');
   var review_id = arr_review_id[1];
   $('#showMoreDiv_'+review_id).css('display','block');
   $('#showLessDiv_'+review_id).css('display','none');
 }

 showMoreFeedback(e){
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_'+review_id).css('display','none');
    $('#showLessFeedbackDiv_'+review_id).css('display','block');
  }

  showLessFeedback(e){
   var review_id_all = e.target.id;
   var arr_review_id = review_id_all.split('_');
   var review_id = arr_review_id[1];
   $('#showMoreFeedbackDiv_'+review_id).css('display','block');
   $('#showLessFeedbackDiv_'+review_id).css('display','none');
 }

  readMore(e){
    // var product_id = e.target.id;
     $('#ReadMoreDiv').css('display','none');
     $('#ReadLessDiv').css('display','block');
   }
 
   readLess(e){
     // var product_id = e.target.id;
      $('#ReadMoreDiv').css('display','block');
      $('#ReadLessDiv').css('display','none');
    }



  submitLike(){
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    if(user_id != '' && user_id != null && user_id > 0){
            
    fetch(myConstClass.BASE_URL+'review/saveNewLikeFrontEnd/'+user_id+'/'+answer_array['2'])
    .then(response => response.json())
    .then(response =>{this.setState({eventinfo: response})
        var status =  this.state.eventinfo.status;
        var message =  this.state.eventinfo.message;
        if(status == 'true'){
           var productLikeCount =  $('#productLikeCount').html();
            var productLikeCountNew = parseInt(productLikeCount)+1;
            $('#productLikeCount').html(productLikeCountNew);
            toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
            //setTimeout(this.props.history.push('/products'),1000);
        }else{
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            //toast("Wow so easy !");
        }
    })
    .catch(err => console.log(err))
   // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

    
    }else{
        //var error =  'You must have login for submitting review.';
        localStorage.removeItem('error');
        localStorage.setItem('error','You must have login for like product.');
       // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
       // this.props.history.push('/signin');
        setTimeout(this.props.history.push('/signin'),1000);
    }
    
    //alert(user_review);
  }

  submitReview(e){
    e.preventDefault();
    console.log('this.refs',$('#pform').serializeArray());
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    const answer_array = this.props.location.pathname.split('/');
    var user_review_current = $('#user_review').val();
    var user_review = $.trim(user_review_current);
    //alert(user_review);
    var user_id = localStorage.getItem('user_id');
    if(user_id != '' && user_id != null && user_id > 0){
        if(user_review != ''){
        var message =  'Your review added successfully,once admin approval will display it.';
            
       // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
        fetch(myConstClass.BASE_URL+`review/saveNewsReviewFrontEnd/`+user_id+'/'+answer_array['2'], {
            method: 'POST',
            body: JSON.stringify($('#pform').serializeArray()),
        
        })
    .then(response => response.json())
    .then(response =>{this.setState({eventinfo: response})
        var status =  this.state.eventinfo.status;
        var message =  this.state.eventinfo.message;
        if(status == 'true'){
            toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
            //setTimeout(this.props.history.push('/products'),1000);
        }else{
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            //toast("Wow so easy !");
        }
    })
    .catch(err => console.log(err))
   // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

    $('#user_review').val('');
        }else{
            var message = "Please enter review.";
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
        }


    }else{
        //var error =  'You must have login for submitting review.';
        localStorage.removeItem('error');
        localStorage.setItem('error','You must have login for submitting review.');
       // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
       // this.props.history.push('/signin');
        setTimeout(this.props.history.push('/signin'),1000);
    }
    
    //alert(user_review);
  }




handleChange(event) {
    this.setState({value: event.target.value});
  }

  
  componentDidMount() {
    var current_language = getLanguage(); 
    localStorage.setItem('current_language',current_language);
    //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
    //   .then(response => response.json())
    //   .then(response =>this.setState({types: response}))
    //   .catch(err => console.log(err))
          
    var user_id = localStorage.getItem('user_id');  
      const answer_array = this.props.location.pathname.split('/');
      
    //  
    //  localStorage.setItem('old_product_type',current_product_type);
     //alert(this.state.faq_id);
  fetch(myConstClass.BASE_URL+'eshopping/getEshoppingInfoFrontEnd/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({guestinfo: response})
  
// var desc = Base64.decode(this.state.guestinfo.description);
this.state.guestinfo.description = this.state.guestinfo.description;
// console.log('english_pdf_url'+this.state.guestinfo.english_pdf_url);
// alert(this.state.guestinfo.description);
// alert('hi');
// $('#container').text(this.state.guestinfo.description);

// var short_description = Base64.decode(this.state.guestinfo.short_description);
// this.state.guestinfo.short_description = short_description;    

//   if(this.state.guestinfo.short_description == ''){
//     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';
  
 
//alert(desc);
  // var descripotion_length = desc.length;
  // if(descripotion_length < 320 || descripotion_length == undefined){
  //   $('#readMoreButton').css('display','none');
  // }
    })
  .then(response =>{this.setState({editorState: EditorState.createWithContent(
    convertFromRaw(JSON.parse(this.state.guestinfo.description))
  )})
})  .then(console.log('hi'+this.state.guestinfo.title))   
  .catch(err => console.log(err))

  

    



    fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))

          
}

  componentDidUpdate(prevProps) {
    const answer_array = this.props.location.pathname.split('/');
    const { flag} = this.state  
    var current_product_type = answer_array['2'];

    $(".cmsClass").addClass("orangeColor");
    $(".cmsClass").removeClass("aboutUSCMS");

    $(".cmsClass").css('font-size','16px');
   

    var current_language =  localStorage.getItem('current_language');
        
      if(current_language != getLanguage() && flag != 1 && current_language != null){
        //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language',getLanguage());
            this.setState({flag:1});
       }
  
    var user_id = localStorage.getItem('user_id');
    if(getLanguage()=='ma' && flag==1){
        this.setState({flag:0})
          fetch(myConstClass.BASE_URL+'eshopping/getEshoppingInfoFrontEnd/'+getLanguage())
          .then(response => response.json())
          .then(response =>{this.setState({guestinfo: response})
          
        // var desc = Base64.decode(this.state.guestinfo.description);
        this.state.guestinfo.description = this.state.guestinfo.description;
       
            })
          .then(response =>{this.setState({editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.state.guestinfo.description))
          )})
        })  .then(console.log('hi'+this.state.guestinfo.title))   
          .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))

        
    }

    if(getLanguage()=='en' && (flag==1)){ 
        this.setState({flag:0})
      
          
          fetch(myConstClass.BASE_URL+'eshopping/getEshoppingInfoFrontEnd/'+getLanguage())
          .then(response => response.json())
          .then(response =>{this.setState({guestinfo: response})
          
        // var desc = Base64.decode(this.state.guestinfo.description);
        this.state.guestinfo.description = this.state.guestinfo.description;
       
            })
          .then(response =>{this.setState({editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.state.guestinfo.description))
          )})
        })  .then(console.log('hi'+this.state.guestinfo.title))   
          .catch(err => console.log(err))
        
        

    fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))


  }
  }
  
  render() {
      
    const {arrSection,selected,arrMagazine,cartEvent,machineCartEvent,arrImages,arrVideo,feedback, review,scheduleEvent,posts,guestinfo,eventtopic} = this.state  
    const { t } = this.props;
  return (
    
    <div className="App">
         <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    <div class="container-fluid">
     
    <div class="row">
            <div class="col-lg-9 ">
                <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                    <nav aria-label="breadcrumb"  id="breadcrumb">
                        <ol class="breadcrumb bg-white mb-0 ml-n2">
                            <li class="breadcrumb-item"> <Link    to="/">{t('home.home')}</Link></li>
                            <li class="breadcrumb-item active" aria-current="page"> 
                            <a href="javascript:void(0)">{t('home.E-Shopping Help')}</a>
                            {/* <Link   to="/about-us/6">{t('home.About Us')}</Link> */}
                            
                            </li>
                            
                        </ol>
                    </nav>
                </div>
               
            </div>
         </div>



    </div>

    <div class="container-fluid" id="text_style">
            <div class="row my-2 mt-3">
                

                <div  class="col-lg-9 col-md-9 col-sm-12 col-xs-12 ulPaddingForDescription" id="container">
                    
                { ReactHtmlParser(guestinfo.description) }
                    {/* {guestinfo.description} */}
                    {/* <div class="row">
                        <p class=" col-md-12 ">
                            The renowned Manashakti Research Center for peace of mind is situated among the pleasant hills of Lonavla (India). For past 50+ years, lakhs of people have found solace through electronic, computerized machine tests; study courses; workshops; seminars; books etc. The Research Center is one of the activities of Manashakti REST New Way Trust (REST= Research, Education, Sanatorium Trust), a social trust registered with the Charity Commissioner, Pune.
                        </p>
                    </div>


                    <div id="about_mid_text" class="col-md-12">
                        <div class="row">
                            <div class=" ">
                                    <h4>Swami Vijnananand - The First Thinker (1918-1993)</h4>
                                    <p>
                                        Swami Vijnananand is the Founder, 
                                        First Thinker and Experimenter of Manashakti Research Centre.
                                         After self-realization in 1957, he put forth a rational 
                                         'New Way Philosophy' (see blog), combining science and spirituality 
                                         for perpetual happiness and peace of mind. Swamiji took Prakash Samadhi
                                          for world peace on 18 November 1993. (See Comments of World Thinkers
                                           about Swamiji)
                                    </p>
                            </div>

                            <div class=" ">
                                <h4>Mission Statement</h4>
                                <p>
                                    To help people achieve tension-free success and peace of mind through 
                                    scientific means and social welfare activities.
                                </p>
                            </div>

                            <div class="">
                                <h4>Trusts</h4>
                                    <ul>
                                        <li>Machine Tests</li>
                                        <li>Psycho-Feedback Therapy</li>
                                        <li>Study Courses</li>
                                        <li>Workshops</li>
                                        <li>Poojas</li>
                                    </ul>
                                <p>
                                     A Board of Trustees looks after these trusts. While trustees of other trusts are usually nominated as a democratic tradition, the trustees for Manashakti REST New Way are elected every two years by the Seekers (Sadhakas) of Manashakti.
                                </p>
                            </div>
                            
                            <div class="">
                                <h4>Activities</h4>
                                <div class="row">
                                    <div class="col-md-6 py-2">
                                        <ul>
                                            <li>Machine Tests</li>
                                            <li>Psycho-Feedback Therapy</li>
                                            <li>Study Courses</li>
                                            <li>Workshops</li>
                                            <li>Poojas</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 py-2">
                                        <ul>
                                            <li>Publications</li>
                                            <li>Experimental Products</li>
                                            <li>Ayurvedic Products</li>
                                            <li>Adivasi Welfare Projects</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div class="">
                                <h4 class="">Establishments</h4>
                                    <div class="row">
                                        <div class="col-md-6 py-2">
                                            <ul>
                                                <li>Manashakti Centre, Lonavla (Main Centre)</li>
                                                <li>Manashanti Ashram, Lonavla</li>
                                                <li>Girgaon</li>
                                                <li>Borivali</li>
                                                <li>Thane</li>
                                                <li>Dombivali</li>
                                                <li>Kharghar ( Navi Mumbai / Vashi)</li> 
                                            </ul>
                                        </div>
                                        <div class="col-md-6 py-2">
                                            <ul>
                                                <li>Talegaon</li>
                                                <li>Chakan</li>
                                                <li>Pune</li>
                                                <li>Nashik</li>
                                                <li>Pandharpur</li>
                                                <li>Kolhapu</li>
                                                <li>Local Centers
                                                (Maharashtra & INDIA)</li> 
                                                <li>Foreign Contacts</li>
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>


                   


                    <div id="accordion" role="tablist" class="p-2">
                        <div class="card mt-4 border-dark">
                          <div class="card-header" role="tab" id="headingOne" 
                          data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h5 class="mb-0">
                              
                               Swami Vijnananand - The First Thinker (1918-1993)
                              
                            </h5>
                      </div>
                      <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                               <div class=" ">
                                    <p>
                                        Swami Vijnananand is the Founder, 
                                        First Thinker and Experimenter of Manashakti Research Centre.
                                         After self-realization in 1957, he put forth a rational 
                                         'New Way Philosophy' (see blog), combining science and spirituality 
                                         for perpetual happiness and peace of mind. Swamiji took Prakash Samadhi
                                          for world peace on 18 November 1993. (See Comments of World Thinkers
                                           about Swamiji)
                                    </p>
                            </div>
                            </div>
                      </div>
                      </div>
                      <div class="card mt-4 border-dark">
                          <div class="card-header" role="tab" id="headingTwo" class="collapsed" 
                          data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                            <h5 class="mb-0">
                              
                                Mission Statement
                              
                            </h5>
                      </div>
                      <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                               To help people achieve tension-free success and peace of mind through scientific means and social welfare activities.
                            </div>
                      </div>
                      </div>
                      <div class="card mt-4 border-dark">
                          <div class="card-header" role="tab" id="headingThree" class="collapsed" 
                          data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h5 class="mb-0">
                              
                                Trusts
                              
                            </h5>
                      </div>
                      <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                             <ul>
                                        <li>Machine Tests</li>
                                        <li>Psycho-Feedback Therapy</li>
                                        <li>Study Courses</li>
                                        <li>Workshops</li>
                                        <li>Poojas</li>
                                    </ul>
                                <p>
                                     A Board of Trustees looks after these trusts. While trustees of other trusts are usually nominated as a democratic tradition, the trustees for Manashakti REST New Way are elected every two years by the Seekers (Sadhakas) of Manashakti.
                                </p>
                            </div>
                      </div>
                      </div>
                      <div class="card mt-4 border-dark">
                        <div class="card-header" role="tab" id="headingFour" class="collapsed" 
                        data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          <h5 class="mb-0">
                            
                              Activities
                            
                          </h5>
                    </div>
                    <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
                          <div class="card-body">
                                <ul>
                                        <li>Machine Tests</li>
                                        <li>Psycho-Feedback Therapy</li>
                                        <li>Study Courses</li>
                                        <li>Workshops</li>
                                        <li>Poojas</li>
                                        <li>Publications</li>
                                        <li>Experimental Products</li>
                                        <li>Ayurvedic Products</li>
                                        <li>Adivasi Welfare Projects</li>
                                </ul>
                          </div>
                    </div>
                    </div>
                    <div class="card mt-4 border-dark">
                        <div class="card-header" role="tab" id="headingFive" class="collapsed" 
                        data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          <h5 class="mb-0">
                            
                              Establishments
                            
                          </h5>
                    </div>
                    <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
                          <div class="card-body">
                                <ul>
                                        <li>Manashakti Centre, Lonavla (Main Centre)</li>
                                        <li>Manashanti Ashram, Lonavla</li>
                                        <li>Girgaon</li>
                                        <li>Borivali</li>
                                        <li>Thane</li>
                                        <li>Dombivali</li>
                                        <li>Kharghar ( Navi Mumbai / Vashi)</li>
                                        <li>Talegaon</li>
                                        <li>Chakan</li>
                                        <li>Pune</li>
                                        <li>Nashik</li>
                                        <li>Pandharpur</li>
                                        <li>Kolhapu</li>
                                        <li>Local Centers
                                            (Maharashtra & INDIA)</li> 
                                        <li>Foreign Contacts</li>
                                </ul>
                          </div>
                    </div>
                    </div>
                      </div> */}

                      
                

                </div>

                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 " style={{marginTop:'-10px'}}>
                    
                {arrMagazine.map((cell, i) => {
                if(cell.magazine_id == 2){
                return(
                <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                 
                    <div class="row d-flex">
                        <div class="col-12">
                        <strong>
                            {cell.magazine_title}
                            </strong>
                        <p>
                        { ReactHtmlParser(cell.description) }     
                        
                        </p>
                        <p>
                            <strong>{t('home.Language')}:</strong> 

                            {cell.language}
                        </p>
                        <p>

                        {cell.images_array.map((info, ii) => {
                            if(ii == 0){
                               if(info.issue_id > 0 && info.issue_id != undefined){
                                    return( 
                                        <Link to={'/magazine_details/'+info.magazine_id+'/'+info.issue_id}>
                                            <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                        </Link>
                                        
                                     )
                                }else{
                                    return( 
                                        <Link to={'/magazine'}>
                                            <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                        </Link>
                                        
                                     )
                                }
                            }else{
                                return( 
                                    <Link to={'/magazine'}>
                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                    </Link>
                                 )
                            }
                            }
                             )} 

                        
                      
                        <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/magazine-subscription/'+cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                        </p>
                        </div>
                    </div>
                </div>
                    )
                }else{
                    return(
                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                         
                            <div class="row d-flex">
                                <div class="col-12">
                                <strong>
                                    {cell.magazine_title}
                                    </strong>
                                <p>
                                { ReactHtmlParser(cell.description) }     
                                
                                </p>
                                <p>
                                    <strong>{t('home.Language')}:</strong> 
        
                                    {cell.language}
                                </p>
                                <p>
        
                                {cell.images_array.map((info, ii) => {
                                    if(ii == 0){
                                        if(info.product_id > 0){
                                            return( 
                                                <Link to={'/products_details/'+info.title+'/'+info.product_id}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                    
                                                    <p >
                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/products_details/'+info.title+'/'+info.product_id}>{t('home.BUY NOW')}</Link>
                                                    </p>
                                                </Link>
                                             )
                                        }else{
                                            return( 
                                                <Link to={'/products'}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                    <p >
                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/products'}>{t('home.BUY NOW')}</Link>
                                                    </p>
                                                </Link>
                                             )
                                        }
                                        
                                    }else{
                                        if(info.product_id > 0){
                                            return( 
                                                <Link to={'/products_details/'+info.title+'/'+info.product_id}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                </Link>
                                             )
                                        }else{
                                            return( 
                                                <Link to={'/products'}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                </Link>
                                             )
                                        }
                                    }
                                    }
                                     )} 
                                </p>
                               
                                </div>
                            </div>
                        </div>
                            )
                }
                })}
                    
                </div>
            </div>
        </div>
    <Footer />
    </div>
  );
}
}
Eshopping.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Eshopping);
