import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEnglish from './assets/images/research-bg-en.jpg';
import backgroundMarathi from './assets/images/research-bg-ma.jpg';
import Research_mr from './assets/images/RESEARCH_m_mr.jpg';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
//import { toast } from 'react-toastify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Research from './assets/images/Research.jpg';
import {
    FormGroup,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";


class Researchmain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            Research: '',
            limit: '',
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrProducts: [],
            arr_news_article: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],
            year: [],

            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.showMoreNews = this.showMoreNews.bind(this);
        this.showMore = this.showMore.bind(this);
        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }



    showMore(e) {
        e.preventDefault();
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 15;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.showMoreNews();
    }


    addToCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_quantity > 0 && product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addProductToCart/' + product_id + '/' + product_quantity + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {
                        // $('#'+product_id).css('display','none');
                        // $('#addedInCart_'+product_id).css('display','inherit');
                        $('#' + product_id).removeClass('btn-brown');
                        $('#' + product_id).addClass('btn-light');
                        //$('#' + product_id).html('ADDED TO CART');

                        if (getLanguage() == 'en') {
                            $('#' + product_id).html('ADD MORE');
                        } else {
                            $('#' + product_id).html('अधिक घ्या');
                        }


                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    readMore(e) {
        var str_news_id = e.target.id;
        const arr_news = str_news_id.split('_');
        var news_id = arr_news[1];

        $('#ReadMoreDiv' + news_id).css('display', 'none');
        $('#ReadLessDiv' + news_id).css('display', 'block');
    }

    readLess(e) {
        var str_news_id = e.target.id;
        const arr_news = str_news_id.split('_');
        var news_id = arr_news[1];
        $('#ReadMoreDiv' + news_id).css('display', 'block');
        $('#ReadLessDiv' + news_id).css('display', 'none');
    }

    showMoreNews() {
        var limit = this.state.limit;
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var language_id = $('#language_id').val();

        if (language_id == '' || language_id == undefined) {
            language_id = 0;
        }

        fetch(myConstClass.BASE_URL + 'news/getNewsResearchFrontEnd' + '/' + getLanguage() + '/2/' + topic_id + '/' + language_id + '/' + limit)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                $('#showMoreButton').css('display', 'none');
                const numRows = this.state.arr_news_article.length;
                if (numRows < 1) {
                    $('#noRecordsAvailable').css('display', 'inline');

                } else {
                    $('#noRecordsAvailable').css('display', 'none');
                }
            })
    }


    componentDidMount() {
        var current_language = getLanguage();
        if (getLanguage() == 'ma') {
            $('#backgroundMarathi').css('display', 'block');
            $('#backgroundEnglish').css('display', 'none');
        } else {
            $('#backgroundMarathi').css('display', 'none');
            $('#backgroundEnglish').css('display', 'block');
        }
        if (getLanguage() == 'ma') {
            this.state.Research = Research_mr;
        } else {
            this.state.Research = Research;
        }

        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        var year = currentTime.getFullYear();
        this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        // var topic_id =  answer_array['2'];
        // if(topic_id == '' || topic_id == undefined){
        //     topic_id = 0;
        // }
        if (getLanguage() == 'ma') {
            this.state.guestinfo.researchTitle = "संशोधन";
        } else {
            this.state.guestinfo.researchTitle = "Research";
        }

        //alert(research);
        fetch(myConstClass.BASE_URL + 'news/getNewsResearchFrontEnd' + '/' + getLanguage() + '/2')
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                var total = this.state.arr_news_article.length;
                if (total >= 15) {
                    $('#showMoreButton').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'none');
                }

                const numRows = this.state.arr_news_article.length;
                if (numRows < 1) {
                    $('#noRecordsAvailable').css('display', 'inline');

                } else {
                    $('#noRecordsAvailable').css('display', 'none');
                }
            })

        fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
            .then(response => response.json())
            .then(response => this.setState({ languages: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))




            .catch(err => console.log(err))

        if (user_id == '' || user_id == undefined) {
            user_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWise/' + '0' + '/' + getLanguage() + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ arrProducts: response })
                var totalProduct = this.state.arrProducts.length;
                if (totalProduct > 0) {
                    $('.showAllBtn').css('display', 'inline');
                    $('.productNotAvailableResearch').css('display', 'none');
                } else {
                    $('.showAllBtn').css('display', 'none');
                    $('.productNotAvailableResearch').css('display', 'inline');
                }

            })

            .catch(err => console.log(err))



    }

    componentDidUpdate(prevProps) {
        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            if (getLanguage() == 'ma') {
                this.state.Research = Research_mr;
            } else {
                this.state.Research = Research;
            }
            if (getLanguage() == 'ma') {
                $('#backgroundMarathi').css('display', 'block');
                $('#backgroundEnglish').css('display', 'none');
            } else {
                $('#backgroundMarathi').css('display', 'none');
                $('#backgroundEnglish').css('display', 'block');
            }
            var user_id = localStorage.getItem('user_id');
            this.setState({ flag: 0 })

            if (getLanguage() == 'ma') {
                this.state.guestinfo.researchTitle = "संशोधन";
            } else {
                this.state.guestinfo.researchTitle = "Research";
            }

            //alert(research);
            fetch(myConstClass.BASE_URL + 'news/getNewsResearchFrontEnd' + '/' + getLanguage() + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_news_article: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    var total = this.state.arr_news_article.length;
                    if (total >= 15) {
                        $('#showMoreButton').css('display', 'inline');
                    } else {
                        $('#showMoreButton').css('display', 'none');
                    }

                    const numRows = this.state.arr_news_article.length;
                    if (numRows < 1) {
                        $('#noRecordsAvailable').css('display', 'inline');

                    } else {
                        $('#noRecordsAvailable').css('display', 'none');
                    }
                })

            fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
                .then(response => response.json())
                .then(response => this.setState({ languages: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))




                .catch(err => console.log(err))

            if (user_id == '' || user_id == undefined) {
                user_id = 0;
            }
            fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWise/' + '0' + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrProducts: response })
                    var totalProduct = this.state.arrProducts.length;
                    if (totalProduct > 0) {
                        $('.showAllBtn').css('display', 'inline');
                        $('.productNotAvailableResearch').css('display', 'none');
                    } else {
                        $('.showAllBtn').css('display', 'none');
                        $('.productNotAvailableResearch').css('display', 'inline');
                    }

                })

                .catch(err => console.log(err))

        }
        if (getLanguage() == 'en' && (flag == 1)) {
            if (getLanguage() == 'ma') {
                this.state.Research = Research_mr;
            } else {
                this.state.Research = Research;
            }
            if (getLanguage() == 'ma') {
                $('#backgroundMarathi').css('display', 'block');
                $('#backgroundEnglish').css('display', 'none');
            } else {
                $('#backgroundMarathi').css('display', 'none');
                $('#backgroundEnglish').css('display', 'block');
            }
            var user_id = localStorage.getItem('user_id');
            this.setState({ flag: 0 })
            if (getLanguage() == 'ma') {
                this.state.guestinfo.researchTitle = "संशोधन";
            } else {
                this.state.guestinfo.researchTitle = "Research";
            }

            //alert(research);
            fetch(myConstClass.BASE_URL + 'news/getNewsResearchFrontEnd' + '/' + getLanguage() + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_news_article: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    var total = this.state.arr_news_article.length;
                    if (total >= 15) {
                        $('#showMoreButton').css('display', 'inline');
                    } else {
                        $('#showMoreButton').css('display', 'none');
                    }

                    const numRows = this.state.arr_news_article.length;
                    if (numRows < 1) {
                        $('#noRecordsAvailable').css('display', 'inline');

                    } else {
                        $('#noRecordsAvailable').css('display', 'none');
                    }
                })

            fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
                .then(response => response.json())
                .then(response => this.setState({ languages: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))




                .catch(err => console.log(err))

            if (user_id == '' || user_id == undefined) {
                user_id = 0;
            }
            fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWise/' + '0' + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrProducts: response })
                    var totalProduct = this.state.arrProducts.length;
                    if (totalProduct > 0) {
                        $('.showAllBtn').css('display', 'inline');
                        $('.productNotAvailableResearch').css('display', 'none');
                    } else {
                        $('.showAllBtn').css('display', 'none');
                        $('.productNotAvailableResearch').css('display', 'inline');
                    }

                })

                .catch(err => console.log(err))
        }
    }



    applyFilters(e) {
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val() || $('#topic_id_m').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var language_id = $('#language_id').val() || $('#language_id_m').val();

        if (language_id == '' || language_id == undefined) {
            language_id = 0;
        }
        // alert(language_id);
        var limit = this.state.limit;
        fetch(myConstClass.BASE_URL + 'news/getNewsResearchFrontEnd' + '/' + getLanguage() + '/2/' + topic_id + '/' + language_id + '/' + limit)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                const numRows = this.state.arr_news_article.length;
                if (numRows < 1) {
                    $('#showMoreButton').css('display', 'none');
                    $('#noRecordsAvailable').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'inline');
                    $('#noRecordsAvailable').css('display', 'none');
                }

                if (numRows >= 15) {
                    $('#showMoreButton').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'none');
                }
            })


        var user_id = localStorage.getItem('user_id');

        if (user_id == '' || user_id == undefined) {
            user_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'product/getRelatedProductsTopicWise/' + topic_id + '/' + getLanguage() + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ arrProducts: response })
                var totalProduct = this.state.arrProducts.length;
                if (totalProduct > 0) {
                    $('.showAllBtn').css('display', 'inline');
                    $('.productNotAvailableResearch').css('display', 'none');
                } else {
                    $('.showAllBtn').css('display', 'none');
                    $('.productNotAvailableResearch').css('display', 'inline');
                }

            })

            .catch(err => console.log(err))


    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { month, year, arrProducts, arr_news_article, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript:void(0)">{guestinfo.researchTitle}
                                            </a>
                                        </li>
                                    </ol>
                                </nav>

                            </div>


                            <div className="row my-3">
                                <div className="col d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div id="backgroundEnglish" className="col p-3 text-white mb-4" style={{ backgroundImage: "url(" + backgroundEnglish + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                        <h1 className="m-0 font-weight-light">
                                        </h1>
                                    </div>

                                    <div id="backgroundMarathi" className="col p-3 text-white mb-4" style={{ backgroundImage: "url(" + backgroundMarathi + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                        <h1 className="m-0 font-weight-light">

                                        </h1>
                                    </div>
                                </div>
                                <div className="col d-xl-none d-lg-none d-md-none d-flex">
                                    <img src={this.state.Research} height={77} />
                                </div>
                            </div>

                            <div className="row bg-light m-0 py-2">
                                <div className="d-flex align-items-center col-xl-2 col-lg-2 col-md-2 col-12">
                                    <span>
                                        {t('home.Select_filters_to_refine_the_results_below')}

                                    </span>
                                </div>

                                {/* Desktop Design */}
                                <div className="col-4 d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Topic of Research')}</label>
                                        <select id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                            <option value="">{t('home.Select Topic of Research')}</option>
                                            {topic.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3 d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Language')}</label>
                                        <select id="language_id" name="language_id" className="form-control form-control-sm">
                                            <option value="">{t('home.Select Language')}</option>
                                            {languages.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* Mobile Design */}
                                <div className="col-8 d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup>
                                        <Label>
                                            {t('home.Topic of Research')}
                                        </Label>
                                        <Input type="select" id="topic_id_m" name="topic_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Topic of Research')}</option>
                                            {topic.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-4 d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup>
                                        <Label>
                                            {t('home.Language')}
                                        </Label>
                                        <Input type="select" id="language_id_m" name="language_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Language')}</option>
                                            {languages.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>


                                <div className="col-xl-2 col-lg-2 col-md-2 col-12 " onClick={this.applyFilters}>
                                    <div className="form-group">
                                        <label for="" className="invisible">{t('home.Apply Filter')}</label>
                                        <button id="applyFilterBtn" className="btn btn-sm btn btn-outline-brown btn-block ">{t('home.APPLY FILTERS')}</button>
                                        <img id="loader" style={{ display: 'none', marginTop: '0px' }} src={myConstClass.IMAGE_URL + 'loader.gif'} />
                                    </div>
                                </div>


                            </div>
                            <div style={{ maxHeight: '750px', overflowY: 'scroll', overflowX: 'hidden', borderLeft: 'none !important', borderTop: '1px solid #dee2e6' }} className="mt-3">
                                {arr_news_article.map((cell, ii) => {
                                    if (ii == 0) {
                                        return (
                                            <Row className="font-size--smaller">
                                                <div className="col-12 py-2 border" style={{ borderLeft: 'none !important' }}>
                                                    <div className="d-xl-none d-lg-none d-md-none d-block">
                                                        <div className="row">
                                                            <div className="col-5 text-left">
                                                                <span className="mr-2">
                                                                    <i className="far fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                </span>
                                                                <span>
                                                                    <i className="fas fa-thumbs-up orangeColor"></i> 
                                                                    <span className="small">{cell.total_like}</span>
                                                                </span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                {cell.date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5 col-xl-3 col-lg-3 col-md-3">
                                                            <p className="mb-2 mt-2">
                                                                <Link style={{ cursor: 'pointer' }} to={'/research-details/' + cell.news_article_id}>
                                                                    <img className="articleImageNew" src={cell.image_name} />
                                                                </Link>
                                                            </p>

                                                        </div>
                                                        <div className="col-7 col-xl-9 col-lg-9 col-md-9">
                                                            <div className="d-xl-block d-lg-block d-md-block d-none">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        {cell.date}
                                                                    </div>
                                                                    <div className="col-8 text-right">
                                                                        <span className="mr-2">
                                                                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                        </span>
                                                                        <span>
                                                                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <strong>
                                                                        <Link style={{ cursor: 'pointer', color: 'black' }} to={'/research-details/' + cell.news_article_id}>
                                                                            {cell.title}
                                                                        </Link>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div id={'ReadMoreDiv' + cell.news_article_id}>
                                                                        {ReactHtmlParser(cell.short_description+'...')}
                                                                        <Link className="btn orangeColor btn-link" style={{ cursor: 'pointer', color: 'black', paddingLeft: '0px', marginTop: '-10px' }} to={'/research-details/' + cell.news_article_id}>
                                                                            {t('home.Read more')}
                                                                        </Link>
                                                                    </div>

                                                                    <div id={'ReadLessDiv' + cell.news_article_id} style={{ display: 'none' }}>

                                                                        {ReactHtmlParser(cell.description)}
                                                                        <br /> <button id={'readLessButton_' + cell.news_article_id} onClick={this.readLess} className="btn orangeColor btn-link">{t('home.Read less')}</button>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Row>
                                        )
                                    } else {
                                        return (
                                            <div className="row font-size--smaller">
                                                <div className="col-12 py-2 border" style={{ borderLeft: 'none !important' }}>
                                                    <div className="d-xl-none d-lg-none d-md-none d-block">
                                                        <div className="row">
                                                            <div className="col-5 text-left">
                                                                <span className="mr-2">
                                                                    <i className="far fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                </span>
                                                                <span>
                                                                    <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                                </span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                {cell.date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5 col-xl-3 col-lg-3 col-md-3">
                                                            <p className="mb-2 mt-2">
                                                                <Link style={{ cursor: 'pointer' }} to={'/research-details/' + cell.news_article_id}>
                                                                    <img className="articleImageNew" src={cell.image_name} />
                                                                </Link>
                                                            </p>

                                                        </div>
                                                        <div className="col-7 col-xl-9 col-lg-9 col-md-9">
                                                            <div className="d-xl-block d-lg-block d-md-block d-none">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        {cell.date}
                                                                    </div>
                                                                    <div className="col-8 text-right">
                                                                        <span className="mr-2">
                                                                            <i className="far fa-comment-dots orangeColor"></i> 
                                                                            <span className="small">{cell.total_review}</span>
                                                                        </span>
                                                                        <span>
                                                                            <i className="fas fa-thumbs-up orangeColor"></i> 
                                                                            <span className="small">{cell.total_like}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <strong>
                                                                        <Link style={{ cursor: 'pointer', color: 'black' }} to={'/research-details/' + cell.news_article_id}>
                                                                            {cell.title}
                                                                        </Link>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div id={'ReadMoreDiv' + cell.news_article_id}>

                                                                        {ReactHtmlParser(cell.short_description+'...')}

                                                                        <Link className="btn orangeColor btn-link" style={{ cursor: 'pointer', color: 'black' }} to={'/research-details/' + cell.news_article_id}>
                                                                            {t('home.Read more')}
                                                                        </Link>


                                                                    </div>

                                                                    <div id={'ReadLessDiv' + cell.news_article_id} style={{ display: 'none' }}>
                                                                        {ReactHtmlParser(cell.description)}
                                                                        <br /> <button id={'readLessButton_' + cell.news_article_id} onClick={this.readLess} className="btn orangeColor btn-link">{t('home.Read less')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                }
                                <div className="row" id="noRecordsAvailable" style={{ display: 'none' }}>
                                    <div className="col text-center">
                                        {t('home.No records available')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center">
                                        <button style={{ marginTop: '10px' }} className="btn btn-outline-brown" id="showMoreButton" onClick={this.showMore}>{t('home.SHOW MORE')}</button>

                                    </div>
                                    <input type="hidden" name="limit" id="limit" value={this.state.limit} />
                                </div>
                            </div>




                        </div>











                        <div className="col-lg-3 pt-3" id="eventRightMenuMain" style={{ marginTop: '170px' }}>
                            <div className="row bg-light m-0 py-2" style={{ height: '94px' }}>

                                <p style={{ textAlign: 'center', marginLeft: '16px', marginTop: '31px', fontSize: '18px;' }}>
                                    <strong>{t('home.Experimentals Products Research')}</strong>

                                </p>
                            </div>
                            {arrProducts.map((info, i) => {
                                if (i == 0) {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3" style={{ marginTop: '14px' }}>

                                            <div className="row d-flex">
                                                <div className="col-5">
                                                    <Link to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                        <img className="image_css_product_research" src={info.image_name} />
                                                    </Link>
                                                </div>
                                                <div className="col-7">
                                                    {info.short_title}
                                                    <br />
                                                    <p className="mb-1">{t('home.donation_contribution')}</p>
                                                    <p className="font-weight-bolder">{t('home.INR')} {info.price}/-</p>
                                                    <input type="hidden" className="count" value="1" name={"qty" + info.product_id} id={"qty" + info.product_id} />

                                                    {info.added_in_cart.map((carts, j) => {
                                                        if (carts.cart_id > 0) {
                                                            return (
                                                                <span>
                                                                    {/* <button id={'addedInCart_'+info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button> */}
                                                                    <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADDED TO CART')}</button>
                                                                </span>
                                                            )
                                                        } else {
                                                            return (
                                                                <span>
                                                                    <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button>
                                                                    <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                    }

                                                </div>

                                            </div>


                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row d-flex">
                                                <div className="col-5">
                                                    <Link to={"/products_details/" + info.title_url + '/' + info.product_id}><img className="image_css_product_research" src={info.image_name} /></Link>


                                                </div>
                                                <div className="col-7">
                                                    {info.short_title}
                                                    <br />
                                                    <p className="mb-1">{t('home.donation_contribution')}</p>
                                                    <p className="font-weight-bolder">{t('home.INR')} {info.price}/-</p>
                                                    <input type="hidden" className="count" value="1" name={"qty" + info.product_id} id={"qty" + info.product_id} />

                                                    {info.added_in_cart.map((carts, j) => {
                                                        if (carts.cart_id > 0) {
                                                            return (
                                                                <span>
                                                                    {/* <button id={'addedInCart_'+info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button> */}
                                                                    <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADDED TO CART')}</button>
                                                                </span>
                                                            )
                                                        } else {
                                                            return (
                                                                <span>
                                                                    <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} className="btn btn-sm btn-light">{t('home.ADDED TO CART')}</button>
                                                                    <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                    }

                                                </div>

                                            </div>


                                        </div>
                                    )
                                }

                            })}

                            <div class="row" style={{ marginTop: '10px' }}>
                                <p class="productNotAvailableResearch" id="productNotAvailableResearch" style={{ display: 'none', marginLeft: '20px' }}>
                                    {t('home.No products available')}
                                </p>
                            </div>
                            <Link style={{ fontWeight: '500' }} className="btn btn-outline-brown btn-sm color-brown showAllBtn" to="/product_type/experimental/4">{t('home.SHOW ALL')}</Link>

                        </div>











                    </div>
                </div>
                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}

Researchmain.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Researchmain);

