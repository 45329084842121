import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';

import {
    Row,
    Col,
    Card,
    ListGroup,
    ListGroupItem
} from "reactstrap";
class Orderdetails extends Component {

    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            cart: [],
            eventitem: [],
            eventTestitem: [],
            country: [],
            country_state: [],
            city: [],
            event: [],
            productitem: [],
            magazineitem: [],
            getOrderShippingAddress: [],
            getOrderBillingAddress: [],
            detailsarray: [],
            flag: 0,
            eventinfo: {
                transaction_id: '',
                status: '',
                total_price: '',
                comment: '',
                created_on: '',
                name: '',
                mobile: '',
                order_id: '',
                order_id_main: ''
            },
            cancelinfo: {
                status: '',
                message: '',
            },

            editorState: EditorState.createEmpty(),
        }

        this.handleChange = this.handleChange.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.cancelOrderConfirm = this.cancelOrderConfirm.bind(this);

    }

    componentDidMount() {
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

            localStorage.removeItem('success');
        }

        var error = localStorage.getItem('error');
        if (error != '' && error != null) {
            toast('' + error, { containerId: 'B', type: toast.TYPE.ERROR });
            localStorage.removeItem('error');
        }
        const answer_array = this.props.location.pathname.split('/');
        if (answer_array['2'] > 0) {

        } else {
            //alert('hihere');
            //this.props.history.push('/order-list');
        }
        //alert(answer_array['3']);
        if (answer_array['3'] != '' && answer_array['3'] != undefined) {
            var message = answer_array['3'];
            if (message == 'Your Order has failed') {
                toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            }

        }

        if (answer_array['2']) {
            //alert(answer_array['2']);
            this.user_id = answer_array['2'];
            this.shouldHide = 1;
            fetch(myConstClass.BASE_URL + 'payment/getOrderDetails/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    if(response[0].user_id_fk == localStorage.getItem('user_id')){
                    this.setState({ detailsarray: response })
                    this.setState({
                        eventinfo: {
                            ...this.state.eventinfo,
                            transaction_id: response[0].transaction_id,
                            order_id_main: response[0].order_id_main
                        }
                    })

                    var status = this.state.detailsarray[0].status;
                    //alert(status);

                    if (status == 'Dispatched' || status == 'Confirm') {
                        $('#cancelOrderButton').css('display', 'block');
                    } else {
                        $('#cancelOrderButton').css('display', 'none');
                    }

                    if (status == 'Pending') {

                        $('#scuccessFail').css('display', 'block');
                        $('#scuccessOrder').css('display', 'none');

                        $('.orderSuccessfulMessage').css('display', 'none');
                        $('.orderFailedMessage').css('display', 'inline');
                    } else if (status == 'Cancelled') {
                        $('#scuccessCancelled').css('display', 'block');
                        $('#scuccessOrder').css('display', 'none');

                        $('.orderSuccessfulMessage').css('display', 'none');
                        $('.orderCancelledMessage').css('display', 'inline');
                    }
                    else {
                        $('.orderSuccessfulMessage').css('display', 'inline');
                        $('.orderFailedMessage').css('display', 'none');
                    }
                } else{
                    this.props.history.push('/order-history');
                }

                })

            fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsItem/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ productitem: response })

                })

            fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMagazineItem/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ magazineitem: response })

                })

            fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsEventItem/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventitem: response })

                })

            fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMachineTestEventItem/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventTestitem: response })
                })


            fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ 
                    getOrderBillingAddress: response.find(address=> address.type==="1" || address.type==="3")? [response.find(address=> address.type==="1" || address.type==="3")]: [],
                    getOrderShippingAddress: response.find(address=> address.type==="2") ? [response.find(address=> address.type==="2")] : []
                })

                })

            // fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/2')
            //     .then(response => response.json())
            //     .then(response => {
            //         this.setState({ getOrderShippingAddress: response })

            //     })


            //     .catch(err => console.log(err))


        }
        console.log(this.props);
        // this.getCountry();
        // this.getEvent();

    }

    componentDidUpdate(prevProps) {
        const { flag } = this.state
        const answer_array = this.props.location.pathname.split('/');
        var current_language = localStorage.getItem('current_language');
        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })

            const answer_array = this.props.location.pathname.split('/');

            if (answer_array['2']) {
                //alert(answer_array['2']);
                this.user_id = answer_array['2'];
                this.shouldHide = 1;
                fetch(myConstClass.BASE_URL + 'payment/getOrderDetails/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ detailsarray: response })
                        this.state.eventinfo.transaction_id = this.state.detailsarray[0].transaction_id;
                        this.state.eventinfo.order_id_main = this.state.detailsarray[0].order_id_main;
                        var status = this.state.detailsarray[0].status;
                        //alert(status);

                        if (status == 'Dispatched' || status == 'Confirm') {
                            $('#cancelOrderButton').css('display', 'block');
                        } else {
                            $('#cancelOrderButton').css('display', 'none');
                        }

                        if (status == 'Pending') {

                            $('#scuccessFail').css('display', 'block');
                            $('#scuccessOrder').css('display', 'none');

                            $('.orderSuccessfulMessage').css('display', 'none');
                            $('.orderFailedMessage').css('display', 'inline');
                        } else if (status == 'Cancelled') {
                            $('#scuccessCancelled').css('display', 'block');
                            $('#scuccessOrder').css('display', 'none');

                            $('.orderSuccessfulMessage').css('display', 'none');
                            $('.orderCancelledMessage').css('display', 'inline');
                        }
                        else {
                            $('.orderSuccessfulMessage').css('display', 'inline');
                            $('.orderFailedMessage').css('display', 'none');
                        }


                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ productitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMagazineItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ magazineitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsEventItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMachineTestEventItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventTestitem: response })
                    })


                fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/1')
                    .then(response => response.json())
                    .then(response => {
                        this.setState(
                            { 
                                getOrderBillingAddress: [response.find(address=> address.type==="1")],
                                getOrderShippingAddress: [response.find(address=> address.type==="2")]
                            }
                        )

                    })

                // fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/2')
                //     .then(response => response.json())
                //     .then(response => {
                //         this.setState({ getOrderShippingAddress: response })

                //     })

                //     .catch(err => console.log(err))
            }

        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })

            const answer_array = this.props.location.pathname.split('/');

            if (answer_array['2']) {
                //alert(answer_array['2']);
                this.user_id = answer_array['2'];
                this.shouldHide = 1;
                fetch(myConstClass.BASE_URL + 'payment/getOrderDetails/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ detailsarray: response })
                        this.state.eventinfo.transaction_id = this.state.detailsarray[0].transaction_id;
                        this.state.eventinfo.order_id_main = this.state.detailsarray[0].order_id_main;
                        var status = this.state.detailsarray[0].status;
                        //alert(status);

                        if (status == 'Dispatched' || status == 'Confirm') {
                            $('#cancelOrderButton').css('display', 'block');
                        } else {
                            $('#cancelOrderButton').css('display', 'none');
                        }

                        if (status == 'Pending') {

                            $('#scuccessFail').css('display', 'block');
                            $('#scuccessOrder').css('display', 'none');

                            $('.orderSuccessfulMessage').css('display', 'none');
                            $('.orderFailedMessage').css('display', 'inline');
                        } else if (status == 'Cancelled') {
                            $('#scuccessCancelled').css('display', 'block');
                            $('#scuccessOrder').css('display', 'none');

                            $('.orderSuccessfulMessage').css('display', 'none');
                            $('.orderCancelledMessage').css('display', 'inline');
                        }
                        else {
                            $('.orderSuccessfulMessage').css('display', 'inline');
                            $('.orderFailedMessage').css('display', 'none');
                        }


                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ productitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMagazineItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ magazineitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsEventItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventitem: response })

                    })

                fetch(myConstClass.BASE_URL + 'payment/getOrderDetailsMachineTestEventItem/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventTestitem: response })
                    })


                fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/1')
                    .then(response => response.json())
                    .then(response => {
                        this.setState(
                            { 
                                getOrderBillingAddress: [response.find(address=> address.type==="1")],
                                getOrderShippingAddress: [response.find(address=> address.type==="2")]
                            }
                        )

                    })

                // fetch(myConstClass.BASE_URL + 'payment/getOrderBillingAddressBackend/' + answer_array['2'] + '/2')
                //     .then(response => response.json())
                //     .then(response => {
                //         this.setState({ getOrderShippingAddress: response })

                //     })

                //     .catch(err => console.log(err))
            }

        }


    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }


    cancelOrder() {
        $('#cancelOrderModal').modal('show');
    }

    cancelOrderConfirm() {
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        if (answer_array['2'] > 0 && user_id > 0) {
            //alert(answer_array['2']);

            fetch(myConstClass.BASE_URL + 'payment/cancelOrder/' + answer_array['2'] + '/' + user_id + '/3')
                .then(response => response.json())
                .then(response => {
                    this.setState({ cancelinfo: response })
                    var message = this.state.cancelinfo.message;
                    var status = this.state.cancelinfo.status;
                    $('#cancelOrderButton').css('display', 'none');
                    if (status == 'true') {
                        $('#cancelOrderModal').modal('hide');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                })

                .catch(err => console.log(err))


        }

    }

    render() {

        const { eventTestitem, eventitem, getOrderBillingAddress, getOrderShippingAddress, productitem, magazineitem, detailsarray, event, posts, guestinfo, cart, eventinfo } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0">
                                        <li class="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <Link to="/order-history">{t('home.Order History')}</Link>

                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">{t('home.Order ID')} {eventinfo.order_id_main} </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5 style={{ textAlign: 'center' }}>
                                        <span class="orderSuccessfulMessage">
                                            {t('home.THANK YOU FOR ORDERING')}
                                        </span>
                                        <span class="orderFailedMessage" style={{ display: 'none' }}>
                                            {t('home.SOMETHING WENT WRONG')}
                                        </span>
                                        <br />
                                        <div id="scuccessOrder">
                                            <i style={{ fontSize: '25px', color: 'green', marginTop: '8px' }} class="fa fa-check-circle"></i>
                                            <span style={{ fontSize: '16px', marginLeft: '10px', marginTop: '8px', color: 'green' }}>{t('home.ORDER SUCCESSFUL')}</span>
                                        </div>
                                        <div style={{ display: 'none' }} id="scuccessFail">
                                            {/* <i style={{fontSize:'25px',color:'red',marginTop:'8px'}} class="fa fa-clock-o" aria-hidden="true"></i> */}
                                            <i class="fas fa-ban" style={{ fontSize: '25px', color: 'red', marginTop: '8px' }}></i>
                                            <span style={{ fontSize: '16px', marginLeft: '10px', marginTop: '8px', color: 'red' }}>{t('home.ORDER FAILED')}</span>
                                        </div>

                                        <div style={{ display: 'none' }} id="scuccessCancelled">
                                            {/* <i style={{fontSize:'25px',color:'red',marginTop:'8px'}} class="fa fa-clock-o" aria-hidden="true"></i> */}
                                            <i class="fas fa-ban" style={{ fontSize: '25px', color: 'red', marginTop: '8px' }}></i>
                                            <span style={{ fontSize: '16px', marginLeft: '10px', marginTop: '8px', color: 'red' }}>{t('home.ORDER CANCELLED')}</span>
                                        </div>
                                    </h5>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <p >
                                        <span class="orderSuccessfulMessage">  {t('home.Your order is successful')}.</span>
                                        <span style={{ display: 'none' }} class="orderFailedMessage">{t('home.Your order is failed')}.</span>
                                        <span style={{ display: 'none' }} class="orderCancelledMessage">{t('home.Your order is cancelled')}.</span>
                       &nbsp;<strong>{t('home.Your order number is ')}&nbsp;{eventinfo.order_id_main}.</strong>
                                        <br />
                                        {t('home.You will receive following two email confirmations on your email id')}
                                        <br />
                        1.{t('home.From CCAvenue (E-commerce Payment Gateway)')}
                                        <br />
                        2.{t('home.From Manashakti Research Center,Lonavla')}
                                        <br />
                        3.{t('home.you will receive sms')}
                                    </p>
                                    <p>
                                        {t('home.To continue browsing')}.
                                    <Link to={'/'} style={{ textDecoration: 'underline' }}>{t('home.Return to Home Page')}</Link>

                                    </p>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <h5>{t('home.Order ID')} {eventinfo.order_id_main}</h5>
                                </div>
                            </div>

                            {/* Desktop Design */}
                            <div className="d-xl-block d-lg-block d-md-block d-none">
                                <div class="row">
                                    <div class="col">
                                        <table class="table bg-light">
                                            <tr>
                                                <th>{t('home.Placed On')}</th>
                                                <th>{t('home.Order ID')}</th>
                                                {/* <th>Expected Date of <br/>Product Deilvery</th> */}
                                                <th>{t('home.Expected Date of Product Delivery')}</th>
                                                <th>{t('home.Status')}</th>
                                                <th>{t('home.Courier Traking URL')}</th>
                                                {/* <th>Courier Tracking URL</th> */}
                                                {/* <th>Courier Tracking ID</th> */}
                                                <th>{t('home.Donation Contribution')}</th>
                                                <th>{t('home.Shipping Charges')}</th>
                                                <th>{t('home.Billing Details')}</th>
                                                <th>{t('home.Shipping Details')}</th>
                                            </tr>
                                            {detailsarray.map((info, ii) => {
                                                if (info.courier_url_available == 1) {
                                                    return (
                                                        <tr>
                                                            <td>{info.created_on}</td>
                                                            <td>{info.order_id_main}</td>
                                                            <td>{info.courier_date_format}</td>
                                                            <td>
                                                                {info.status}
                                                            </td>
                                                            <td><a target="_blank" href={info.courier_url}>{info.courier_url}</a> </td>

                                                            <td>{t('home.INR')} {info.total_price * 1 - info.shipping_charges * 1}/-</td>
                                                            <td>{t('home.INR')} {info.shipping_charges}/-</td>

                                                            <td>
                                                                {getOrderBillingAddress.map((info, ii) => {
                                                                    return (
                                                                        <div >
                                                                            {info.company}<br />
                                                                            {info.address_line_1} <br />
                                                                            {info.address_line_2}<br />
                                                                            {info.landmark}<br />
                                                                            {info.city_name},{info.state_name}- &nbsp; {info.pincode}<br />
                                                                            {info.country_name}.<br />

                                                                            {t('home.Email')}: {info.email}<br />
                                                                            {t('home.Phone')}: {info.phone}<br />
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </td>
                                                            <td>
                                                                {getOrderShippingAddress.map((info, ii) => {
                                                                    return (
                                                                        <div >
                                                                        
                                                                            {info.company}<br />
                                                                            {info.address_line_1} <br />
                                                                            {info.address_line_2}<br />
                                                                            {info.landmark}<br />
                                                                            {info.city_name},{info.state_name}- &nbsp; {info.pincode}<br />
                                                                            {info.country_name}.<br />

                                                                            {t('home.Email')}: {info.email}<br />
                                                                            {t('home.Phone')}: {info.phone}<br />
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </td>
                                                            <td>
                                                                {/* <button id="cancelOrderButton" onClick={this.cancelOrder} class="btn btn-outline-brown bg-white" style={{color:'black',display:'none'}}>{t('home.Cancel Order')}</button> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr>
                                                            <td>{info.created_on}</td>
                                                            <td>{info.order_id_main}</td>
                                                            <td>{info.order_type !== "2"?info.courier_date_format:'NA'}</td>
                                                            <td>
                                                                {info.status}
                                                            </td>
                                                            <td>
                                                                {info.order_type !== "2"?info.courier_url:'NA'}
                                                            </td>

                                                            <td>{t('home.INR')} {info.total_price * 1 - info.shipping_charges * 1}/-</td>
                                                            <td>
                                                                {info.order_type !== "2"?t('home.INR')+' '+info.shipping_charges+' /-':'NA'}
                                                            </td>

                                                            <td>
                                                                {getOrderBillingAddress.map((info, ii) => {
                                                                    return (
                                                                        <div >

                                                                            {info.company}<br />
                                                                            {info.address_line_1} <br />
                                                                            {info.address_line_2}<br />
                                                                            {info.landmark}<br />
                                                                            {info.city_name},{info.state_name}- &nbsp; {info.pincode}<br />
                                                                            {info.country_name}.<br />

                                                                            {t('home.Email')}: {info.email}<br />
                                                                            {t('home.Phone')}: {info.phone}<br />
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </td>
                                                            
                                                            <td>
                                                                {getOrderShippingAddress.map((info, ii) => {
                                                                    return (
                                                                        <div >

                                                                            {info.company}<br />
                                                                            {info.address_line_1} <br />
                                                                            {info.address_line_2}<br />
                                                                            {info.landmark}<br />
                                                                            {info.city_name},{info.state_name}- &nbsp; {info.pincode}<br />
                                                                            {info.country_name}.<br />

                                                                            {t('home.Email')}: {info.email}<br />
                                                                            {t('home.Phone')}: {info.phone}<br />
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </td>
                                                            <td>
                                                                {/* <button id="cancelOrderButton" onClick={this.cancelOrder} class="btn btn-outline-brown bg-white" style={{color:'black',display:'none'}}>{t('home.Cancel Order')}</button> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            })
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* Mobile Design */}
                            <div className="d-xl-none d-lg-none d-md-none d-block mb-4">
                                {
                                    detailsarray.map(info =>

                                        <Card className="bg-light p-4">
                                            <Row>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Placed On')}&nbsp;:&nbsp;
                                                    </span>
                                                    {info.created_on}
                                                </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Order ID')}&nbsp;:&nbsp;
                                                    </span>
                                                    {info.order_id_main}
                                                </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Status')}&nbsp;:&nbsp;
                                                    </span>
                                                    {info.status}
                                                </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Courier Traking URL')}&nbsp;:&nbsp;
                                                    </span>
                                                    {info.courier_url_available == 1 ? <a target="_blank" href={info.courier_url}>{info.courier_url}</a> : info.courier_url}
                                                </Col>

                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Expected Date of Product Delivery')}&nbsp;:&nbsp;
                                                    </span>
                                                    {info.courier_date_format}
                                                </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                    </span>
                                                    {t('home.INR')} {info.total_price * 1 - info.shipping_charges * 1}/-
                                                    </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Shipping Charges')}&nbsp;:&nbsp;
                                                    </span>
                                                    {t('home.INR')} {info.shipping_charges}/-
                                                    </Col>
                                                <Col sm="12" xs="12">
                                                    <span className="font-weight-bold">
                                                        {t('home.Shipping Details')}&nbsp;:&nbsp;
                                                    </span>
                                                    {getOrderShippingAddress.map((info, ii) => {
                                                        return (
                                                            <div >
                                                                {info.company}<br />
                                                                {info.address_line_1} <br />
                                                                {info.address_line_2}<br />
                                                                {info.landmark}<br />
                                                                {info.city_name},{info.state_name}- &nbsp; {info.pincode}<br />
                                                                {info.country_name}.<br />

                                                                {t('home.Email')}: {info.email}<br />
                                                                {t('home.Phone')}: {info.phone}<br />
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                }
                            </div>


                            {/* Desktop Design */}
                            <div className="d-xl-block d-lg-block d-md-block d-none">
                                <div class="row">
                                    <div class="col">
                                        <table class="table table-sm table-bordered" >
                                            <thead class="bg-light">
                                                <tr>
                                                    <th >{t('home.No')}</th>
                                                    <th scope="col-2">{t('home.Item')}</th>
                                                    <th scope="col-2">{t('home.Type')}</th>
                                                    <th scope="col-2">{t('home.Language')}</th>
                                                    <th scope="col-2">{t('home.Details')}</th>
                                                    <th scope="col">{t('home.Quantity')}</th>
                                                    <th scope="col">{t('home.No of Participants')}</th>
                                                    <th scope="col ">{t('home.donation_contribution')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {eventTestitem.map((info, j) => {
                                                    return (
                                                        <tr>
                                                            <td>{j + 1}</td>
                                                            <td>

                                                                {info.title}</td>
                                                            <td>
                                                                {t('home.Test')}:<br />
                                                                {info.event_type}</td>
                                                            <td>{info.event_language}</td>
                                                            <td>
                                                                {t('home.NA')}
                                                            </td>
                                                            <td>{t('home.NA')}</td>
                                                            <td>
                                                                {info.parent * 1 + info.child * 1}
                                                                {/* {info.venue_address} */}
                                                            </td>

                                                            <td>
                                                                {t('home.INR')} {info.price * info.parent + info.second_price * info.child}
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                                }



                                                {eventitem.map((info, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{eventTestitem.length + i + 1}</td>
                                                            <td>

                                                                {info.title}</td>
                                                            <td>
                                                                {t('home.Event')}:<br />
                                                                {info.event_type}</td>
                                                            <td>{info.event_language}</td>
                                                            <td>
                                                                {info.city_name}<br />
                                                                {info.start_date}
                                                                <br />
                                                                {
                                                                    info.event_type === '1-Day Workshops' ?
                                                                        (null)
                                                                        :
                                                                        info.end_date
                                                                }

                                                            </td>
                                                            <td>{t('home.NA')}</td>
                                                            <td>
                                                                {info.parent * 1 + info.child * 1}
                                                                {/* {info.venue_address} */}
                                                            </td>

                                                            <td>
                                                                {t('home.INR')} {info.price * info.parent + info.second_price * info.child}
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                                }


                                                {productitem.map((info, ii) => {
                                                    return (
                                                        <tr>
                                                            <td>{eventTestitem.length + eventitem.length + ii + 1}</td>
                                                            <td>{info.title}</td>
                                                            <td>
                                                                {t('home.Product')}:<br />
                                                                {info.type_name}</td>
                                                            <td>{info.product_language}</td>
                                                            <td>{t('home.NA')}</td>

                                                            <td>{info.quantity}</td>
                                                            <td>{t('home.NA')}</td>
                                                            <td>
                                                                {t('home.INR')} {info.price}
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                                }


                                                {magazineitem.map((info, iii) => {
                                                    return (
                                                        <tr>
                                                            <td>{productitem.length + eventTestitem.length + eventitem.length + iii + 1}</td>
                                                            <td>{info.package_title}</td>
                                                            <td>
                                                                {t('home.Magazine')}:<br />
                                                                {info.magazine_title}</td>
                                                            <td>{info.magazine_language}</td>
                                                            <td>{t('home.NA')}</td>

                                                            <td>-</td>
                                                            <td>{t('home.NA')}</td>
                                                            <td>{t('home.INR')} {info.price * 1}</td>
                                                        </tr>
                                                    )

                                                })
                                                }


                                            </tbody>
                                        </table>




                                    </div>
                                </div>
                            </div>

                            {/* Mobile Design */}
                            <div className="d-xl-none d-lg-none d-md-none d-block">
                                <ListGroup>
                                    <ListGroupItem className="bg-light font-weight-bold">
                                        ORDER DETAILS
                                    </ListGroupItem>

                                    {/* Event Test */}
                                    {
                                        eventTestitem.map(info =>
                                            <ListGroupItem >
                                                <Row>
                                                    <Col sm="12" xs="12" style={{ textDecoration: 'underline' }}>
                                                        <span className="font-weight-bold">
                                                            {info.title}
                                                        </span>
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Type')}
                                                        </span>
                                                        {info.event_type}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Language')}&nbsp;:&nbsp;

                                                        </span>
                                                        {info.event_language}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Details')}&nbsp;:&nbsp;

                                                        </span>
                                                        {t('home.NA')}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Quantity')}&nbsp;:&nbsp;

                                                        </span>
                                                        {t('home.NA')}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.No of Participants')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.parent * 1 + info.child * 1}
                                                    </Col>

                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                        </span>
                                                        {t('home.INR')} {info.price * info.parent + info.second_price * info.child}
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }

                                    {/* Events */}
                                    {
                                        eventitem.map(info =>
                                            <ListGroupItem >
                                                <Row>
                                                    <Col sm="12" xs="12" style={{ textDecoration: 'underline' }}>
                                                        <span className="font-weight-bold">
                                                            {info.title}
                                                        </span>
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Type')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.event_type}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Language')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.event_language}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Details')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.city_name}<br />
                                                        {info.start_date}
                                                        <br />
                                                        {
                                                            info.event_type === '1-Day Workshops' ?
                                                                (null)
                                                                :
                                                                info.end_date
                                                        }
                                                    </Col>

                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Quantity')}&nbsp;:&nbsp;
                                                        </span>
                                                        {t('home.NA')}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.No of Participants')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.parent * 1 + info.child * 1}
                                                    </Col>

                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                        </span>
                                                        {t('home.INR')} {info.price * info.parent + info.second_price * info.child}
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }

                                    {/* Products */}
                                    {
                                        productitem.map(info =>
                                            <ListGroupItem >
                                                <Row>
                                                    <Col sm="12" xs="12" style={{ textDecoration: 'underline' }}>
                                                        <span className="font-weight-bold">
                                                            {info.title}
                                                        </span>
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Type')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.type_name}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Language')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.product_language}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Quantity')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.quantity}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                        </span>
                                                        {/* {t('home.INR')} {info.price * info.quantity} */}
                                                        {t('home.INR')} {info.price}
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }


                                    {/* Magazine */}
                                    {
                                        magazineitem.map(info =>
                                            <ListGroupItem >
                                                <Row>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {info.magazine_title}
                                                        </span>
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Type')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.package_title}
                                                    </Col>

                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Language')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.magazine_language}
                                                    </Col>
                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Quantity')}&nbsp;:&nbsp;
                                                        </span>
                                                        {info.quantity}
                                                    </Col>

                                                    <Col sm="12" xs="12">
                                                        <span className="font-weight-bold">
                                                            {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                                        </span>
                                                        {t('home.INR')} {info.price * 1}
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="modal fade z-index--99999" id="cancelOrderModal" style={{ marginTop: '0px' }}>
                    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-l" role="document">
                        <div class="modal-content bg-light">
                            <div class="modal-header" style={{ background: '#9d0b0e', height: '8%' }}>
                                <h5 class="modal-title" id="exampleModalLabel" style={{ marginTop: '-11px', color: 'white', fontSize: '15px' }}>

                                    {t('home.CANCEL ORDER')}
                                </h5>
                                <button style={{ marginTop: '-28px' }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i class="far fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">

                                    <div class="col">

                                        {t('home.Are you sure you want to cancel this order?')}



                                    </div>
                                </div>
                                <div class="row" style={{ marginTop: '20px' }}>
                                    <div class="col">
                                        <button type="button" class="btn btn-outline-brown bg-white btn-sm mb-2" style={{ color: 'black' }} onClick={this.cancelOrderConfirm}>
                                            {t('home.Cancel Order')}

                                        </button>

                                        <span style={{ color: 'white', cursor: 'pointer', marginLeft: '30px' }} class="btn btn-brown btn-sm mb-2" data-dismiss="modal" aria-label="Close">
                                            {t('home.Dont Cancel')}

                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="modal-footer">
                    <span data-dismiss="modal" aria-label="Close">Cancel</span>
                    <button type="button" class="btn btn-outline-brown bg-white btn-sm pl-4 pr-4">SAVE</button>
                </div> */}
                        </div>
                    </div>
                </div>



                <Footer />
            </div>
        );
    }
}
Orderdetails.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Orderdetails);
