import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://prepod.manashakti.org/api/saved_address";
// const BASE_URL = "http://localhost:4000/api/saved_address";

export const fetchAddresses = createAsyncThunk("addresses/fetch", async () => {
  let user_id = localStorage.getItem("user_id");
  try {
    const response = await fetch(`${BASE_URL}/${user_id}`, {
      method: "GET",
      credentials: "include"
    });
    if (!response.ok) {
      throw new Error("failed to fetch the user addresses");
    }
    const data = await response.json();
    data.reverse();
    return data;
  } catch (error) {
    console.log("saved address error: ", error);
    throw new Error(error.message);
  }
});

export const postAddress = createAsyncThunk(
  "addresses/post",
  async (newaddressdata) => {
    try {
      const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newaddressdata),
      });
      // console.log('post data: ',newaddressdata);
      if (!response.ok) {
        throw new Error("failed to send the address data");
      }
      const data = await response.json();
      // console.log('post request: ', data);
      return data;
    } catch (error) {
      // console.log('post error: ', error);
      throw new Error(error.message);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "addresses/update",
  async (addressData) => {
    try {
      const response = await fetch(`${BASE_URL}/${addressData.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addressData),
      });
      console.log("body updated_data: ", addressData);
      if (!response.ok) {
        throw new Error("Failed to update address");
      }
      const data = await response.json();
      console.log("res updated data: ", addressData);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "addresses/delete",
  async (addressId) => {
    try {
      const response = await fetch(`${BASE_URL}/${addressId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete the address data");
      }
      return "successfully deleted the address";
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

// reducers
const addressSlice = createSlice({
  name: "saved_address",
  initialState: {
    addresses: [],
    status: "idle",
    error: null,
  },
  // reducers cases
  reducers: {},
  extraReducers: (builder) => {
    builder
      // for get billing addresses
      .addCase(fetchAddresses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.status = "success";
        state.addresses = action.payload;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.status = "failure";
        state.error = action.error.message;
      })
      .addCase(postAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses.push(action.payload);
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.status = "success";
        const addressId = action.payload;
        state.addresses = state.addresses.filter((el) => el.id !== addressId);
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedAddress = action.payload;
        const existingIndex = state.addresses.findIndex(
          (address) => address.id === updatedAddress.id
        );
        if (existingIndex !== -1) {
          state.addresses[existingIndex] = updatedAddress;
        }
      });
  },
});

export default addressSlice.reducer;
