import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEnglish from './assets/images/news-bg-en.jpg';
import backgroundMarathi from './assets/images/news-bg-ma.jpg';

import LatestNews from './assets/images/LatestNews.jpg';
import LatestNews_mr from './assets/images/Latest_News_m_mr.jpg';

import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

class Research extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share_current_image_url: '',
            share_image_url: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {

                social_description: '',

                format_date: '',
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            arr_news_article: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],
            year: [],

            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.getNews = this.getNews.bind(this);
        this.getNewsYearly = this.getNewsYearly.bind(this);
        this.showMoreNews = this.showMoreNews.bind(this);
        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);

        this.getNewsDesktop = this.getNewsDesktop.bind(this);
        this.getNewsYearlyDesktop = this.getNewsYearlyDesktop.bind(this);

        this.getNewsMobile = this.getNewsMobile.bind(this);
        this.getNewsYearlyMobile = this.getNewsYearlyMobile.bind(this);
    }

    readMore(e) {
        var str_news_id = e.target.id;
        const arr_news = str_news_id.split('_');
        var news_id = arr_news[1];

        $('#ReadMoreDiv' + news_id).css('display', 'none');
        $('#ReadLessDiv' + news_id).css('display', 'block');
    }

    readLess(e) {
        var str_news_id = e.target.id;
        const arr_news = str_news_id.split('_');
        var news_id = arr_news[1];
        $('#ReadMoreDiv' + news_id).css('display', 'block');
        $('#ReadLessDiv' + news_id).css('display', 'none');
    }

    showMoreNews() {
        const answer_array = this.props.location.pathname.split('/');
        var research = answer_array['2'];
        var month = $('#month').val() || $('#month_m').val();
        if (month == '' || month == undefined) {
            month = 0;
        }
        var year = $('#year').val();
        if (year == '' || year == undefined) {
            year = 0;
        }
        fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research + '/300/' + month + '/' + year)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                response.map((cell, ii) => {
                    // alert(cell);
                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        const { guestinfo } = this.state;
                        guestinfo.social_description = cell.social_description;
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert(this.state.guestinfo.social_description);
                    }
                });
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                $('#showMoreButton').css('display', 'none');
                const numRows = this.state.arr_news_article.length;
                // alert(numRows);
                if (numRows < 1) {
                    $('#noRecordsAvailable').css('display', 'inline');

                } else {
                    $('#noRecordsAvailable').css('display', 'none');
                }
            })
    }

    getNewsDesktop = () => {
        this.getNews($('#month').val());
    }

    getNewsYearlyDesktop = () => {
        this.getNewsYearly($('#year').val());
    }

    getNewsMobile = () => {
        this.getNews($('#month_m').val());
    }

    getNewsYearlyMobile = () => {
        this.getNewsYearly($('#year_m').val());
    }


    getNews(month) {
        $('#year option:first').prop('selected', true);
        const answer_array = this.props.location.pathname.split('/');
        var research = answer_array['2'];
        var arr_year = month.split('-');
        this.state.guestinfo.currentYear = arr_year[0];
        // alert(month);
        fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research + '/0/' + month)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                response.map((cell, ii) => {
                    // alert(cell);
                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        const { guestinfo } = this.state;
                        guestinfo.social_description = cell.social_description;
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert(this.state.guestinfo.social_description);
                    }
                });
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                const numRows = this.state.arr_news_article.length;
                if (numRows < 1) {
                    $('#showMoreButton').css('display', 'none');
                    $('#noRecordsAvailable').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'inline');
                    $('#noRecordsAvailable').css('display', 'none');
                }

                var total = this.state.arr_news_article.length;
                if (total >= 15) {
                    $('#showMoreButton').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'none');
                }

            })
    }


    getNewsYearly(year) {
        const answer_array = this.props.location.pathname.split('/');
        var research = answer_array['2'];
        $('#month option:first').prop('selected', true);
        $('#month_m option:first').prop('selected', true);
        fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research + '/0/0/' + year)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                response.map((cell, ii) => {
                    // alert(cell);
                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        const { guestinfo } = this.state;
                        guestinfo.social_description = cell.social_description;
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert(this.state.guestinfo.social_description);
                    }
                });
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                const numRows = this.state.arr_news_article.length;
                if (numRows < 1) {
                    $('#showMoreButton').css('display', 'none');
                    $('#noRecordsAvailable').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'inline');
                    $('#noRecordsAvailable').css('display', 'none');
                }

                var total = this.state.arr_news_article.length;
                if (total >= 15) {
                    $('#showMoreButton').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'none');
                }
            })
    }

    componentDidMount() {
        var current_language = getLanguage();
         if(getLanguage() == 'ma'){
             this.state.LatestNews = LatestNews_mr;
            }else{
                this.state.LatestNews = LatestNews;
            }
        if (getLanguage() == 'ma') {
            $('#backgroundMarathi').css('display', 'block');
            $('#backgroundEnglish').css('display', 'none');
        } else {
            $('#backgroundMarathi').css('display', 'none');
            $('#backgroundEnglish').css('display', 'block');
        }
        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        var year = currentTime.getFullYear();
        this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        var research = answer_array['2'];
        if (research == 1) {
            if (getLanguage() == 'ma') {
                this.state.guestinfo.researchTitle = "ताज्या बातम्या ";
            } else {
                this.state.guestinfo.researchTitle = "Latest News";
            }

        } else {
            if (getLanguage() == 'ma') {
                this.state.guestinfo.researchTitle = "लेख";
            } else {
                this.state.guestinfo.researchTitle = "Article";
            }

        }

        //alert(research);
        fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_news_article: response })
                response.map((cell, ii) => {
                    // alert(cell);
                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        const { guestinfo } = this.state;
                        guestinfo.social_description = cell.social_description;
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert(this.state.guestinfo.social_description);
                    }
                });
                var total = this.state.arr_news_article.length;
                if (total >= 15) {
                    $('#showMoreButton').css('display', 'inline');
                } else {
                    $('#showMoreButton').css('display', 'none');
                }


                const numRows = this.state.arr_news_article.length;
                // alert(numRows);
                if (numRows < 1) {
                    $('#noRecordsAvailable').css('display', 'inline');

                } else {
                    $('#noRecordsAvailable').css('display', 'none');
                }
            })


        fetch(myConstClass.BASE_URL + 'news/getNewsMonth/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ month: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'news/getNewsYear')
            .then(response => response.json())
            .then(response => this.setState({ year: response }))
            .catch(err => console.log(err))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))
            .catch(err => console.log(err))

    }

    componentDidUpdate(prevProps) {

        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }

        if (getLanguage() == 'ma' && flag == 1) {
             if(getLanguage() == 'ma'){
             this.state.LatestNews = LatestNews_mr;
            }else{
                this.state.LatestNews = LatestNews;
            }
            if (getLanguage() == 'ma') {
                $('#backgroundMarathi').css('display', 'block');
                $('#backgroundEnglish').css('display', 'none');
            } else {
                $('#backgroundMarathi').css('display', 'none');
                $('#backgroundEnglish').css('display', 'block');
            }
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];
            if (research == 1) {
                if (getLanguage() == 'ma') {
                    this.state.guestinfo.researchTitle = "ताज्या बातम्या";
                } else {
                    this.state.guestinfo.researchTitle = "Latest News";
                }

            } else {
                if (getLanguage() == 'ma') {
                    this.state.guestinfo.researchTitle = "लेख";
                } else {
                    this.state.guestinfo.researchTitle = "Article";
                }

            }

            fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_news_article: response })
                    response.map((cell, ii) => {
                        // alert(cell);
                        if (ii == '0') {
                            this.setState({ share_image_url: cell.image_name });
                            const { guestinfo } = this.state;
                            guestinfo.social_description = cell.social_description;
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            // alert(this.state.guestinfo.social_description);
                        }
                    });
                    var total = this.state.arr_news_article.length;
                    if (total >= 15) {
                        $('#showMoreButton').css('display', 'inline');
                    } else {
                        $('#showMoreButton').css('display', 'none');
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'news/getNewsMonth/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {
             if(getLanguage() == 'ma'){
             this.state.LatestNews = LatestNews_mr;
            }else{
                this.state.LatestNews = LatestNews;
            }
            if (getLanguage() == 'ma') {
                $('#backgroundMarathi').css('display', 'block');
                $('#backgroundEnglish').css('display', 'none');
            } else {
                $('#backgroundMarathi').css('display', 'none');
                $('#backgroundEnglish').css('display', 'block');
            }
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];
            if (research == 1) {
                if (getLanguage() == 'ma') {
                    this.state.guestinfo.researchTitle = "ताज्या बातम्या";
                } else {
                    this.state.guestinfo.researchTitle = "Latest News";
                }

            } else {
                if (getLanguage() == 'ma') {
                    this.state.guestinfo.researchTitle = "लेख";
                } else {
                    this.state.guestinfo.researchTitle = "Article";
                }

            }

            //alert(research);
            fetch(myConstClass.BASE_URL + 'news/getNewsArticleFrontEnd' + '/' + getLanguage() + '/' + research)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_news_article: response })
                    response.map((cell, ii) => {
                        // alert(cell);
                        if (ii == '0') {
                            this.setState({ share_image_url: cell.image_name });
                            const { guestinfo } = this.state;
                            guestinfo.social_description = cell.social_description;
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            // alert(this.state.guestinfo.social_description);
                        }
                    });
                    var total = this.state.arr_news_article.length;
                    if (total >= 15) {
                        $('#showMoreButton').css('display', 'inline');
                    } else {
                        $('#showMoreButton').css('display', 'none');
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'news/getNewsMonth/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))

        }
    }



    applyFilters() {
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var language_id = $('#language_id').val();
        if (language_id == '') {
            language_id = 0;
        }
        var event_type = answer_array['3'];
        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/' + language_id;
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/' + language_id;
        } else {
            var path = "Events/0" + '/' + topic_id + '/' + language_id;
        }
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/events/' + path);
        }

    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { month, year, arrMagazine, arr_news_article, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript:void(0)">{guestinfo.researchTitle}
                                            </a>
                                        </li>
                                    </ol>
                                </nav>

                            </div>


                            <div className="row my-3">
                                <div className="col d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div id="backgroundEnglish" className="col p-3 text-white mb-4" style={{ backgroundImage: "url(" + backgroundEnglish + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                        <h1 className="m-0 font-weight-light">
                                        </h1>
                                    </div>

                                    <div id="backgroundMarathi" className="col p-3 text-white mb-4" style={{ backgroundImage: "url(" + backgroundMarathi + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                        <h1 className="m-0 font-weight-light">
                                        </h1>
                                    </div>
                                </div>
                                <div className="col d-xl-none d-lg-none d-md-none d-flex">
                                    <img src={this.state.LatestNews} height={77} />
                                </div>
                            </div>

                            <Row className="bg-light m-0 py-2">
                                <Col xl="3" lg="3" md="3" sm="3" xs="3" className="align-items-center">
                                    <div>
                                        <label style={{ fontWeight: '490' }}>{t('home.Year')}</label>
                                    </div>
                                    <div>
                                        <strong>{guestinfo.currentYear}</strong>
                                    </div>
                                </Col>

                                {/* Desktop Design */}
                                <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <FormGroup className="w-100">
                                        <label for="" style={{ fontWeight: '490' }}>{t('home.Month')}</label>
                                        <Input type="select" id="month" name="month" onChange={this.getNewsDesktop}>
                                            <option value="">{t('home.Select Month')}</option>
                                            {month.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <FormGroup className="w-100">
                                        <label style={{ fontWeight: '490' }} for="">{t('home.Archived')}</label>
                                        <Input type="select" id="year" name="year" onChange={this.getNewsYearlyDesktop}>
                                            <option value="">{t('home.Select Year')}</option>
                                            {year.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                {/* Mobile Design */}
                                <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup className="w-100">
                                        <label for="" style={{ fontWeight: '490' }}>{t('home.Month')}</label>
                                        <Input type="select" id="month_m" name="month" onChange={this.getNewsMobile} className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Month')}</option>
                                            {month.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup className="w-100">
                                        <label style={{ fontWeight: '490' }} for="">{t('home.Archived')}</label>
                                        <Input type="select" id="year_m" name="year" onChange={this.getNewsYearlyMobile} className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Year')}</option>
                                            {year.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <div style={{ maxHeight: '750px', overflowY: 'scroll', overflowX: 'hidden', borderLeft: 'none !important' }} className="mt-3 border-top">
                                {arr_news_article.map((cell, ii) => {
                                    return (
                                        <Row className="border-bottom my-3">
                                            <Col xl="3" lg="3" md="3">
                                                <p className="mb-2 mt-2">
                                                    <Link style={{ cursor: 'pointer' }} to={'/news-details/' + cell.news_article_id}>
                                                        <img className="articleImageNew" src={cell.image_name} height="100px" width="100px" />
                                                    </Link>
                                                </p>
                                            </Col>
                                            <Col xl="9" lg="9" md="9" className="px-4">
                                                <Row>
                                                    <Col xl="6" lg="6" md="6" sm="6" xs="6" className="text-left" style={{ color: '#C0C0C0', fontWeight: '500' }}>
                                                        {cell.date}
                                                    </Col>
                                                    <Col xl="6" lg="6" md="6" sm="6" xs="6" className="text-right">
                                                        <span className="mr-2">
                                                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                        </span>
                                                        <span>
                                                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                        </span>
                                                    </Col>
                                                    <Col xl="12" lg="12" md="12" className="text-left">
                                                        <strong>
                                                            <Link style={{ cursor: 'pointer', color: 'black' }} to={'/news-details/' + cell.news_article_id}>
                                                                {cell.title}
                                                            </Link>

                                                        </strong>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12" id={'ReadMoreDiv' + cell.news_article_id}>
                                                        <div className="newsDescriptionDiv">
                                                            {ReactHtmlParser(cell.short_description+'...')}
                                                        </div>
                                                        <Link className="btn orangeColor btn-link newsReadMoreReadLess" to={'/news-details/' + cell.news_article_id}>
                                                            {t('home.Read more')}
                                                        </Link>
                                                    </Col>
                                                    <Col xl="12">
                                                        <div id={'ReadLessDiv' + cell.news_article_id} style={{ display: 'none' }}>

                                                            {ReactHtmlParser(cell.description)}
                                                            <br /> <button id={'readLessButton_' + cell.news_article_id} onClick={this.readLess} className="btn orangeColor btn-link newsReadMoreReadLess">{t('home.Read less')}</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    )
                                })
                                }

                                <div className="row" id="noRecordsAvailable" style={{ display: 'none' }}>
                                    <div className="col text-center">
                                        {t('home.No records available')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center">
                                        <button style={{ marginTop: '10px' }} className="btn btn-outline-brown" id="showMoreButton" onClick={this.showMoreNews}>{t('home.SHOW MORE')}</button>
                                    </div>
                                </div>
                            </div>




                        </div>


                        <div className="col-lg-3 pt-3" id="eventRightMenuMain" style={{ marginTop: '243px' }}>
                            {arrMagazine.map((cell, i) => {
                                if (cell.magazine_id == 2) {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row d-flex">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                    return (
                                                                        <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <Link to={'/magazine'}>
                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                    </Link>
                                                                )
                                                            }
                                                        }
                                                        )}



                                                        <Link className="btn btn-brown btn-block btn-sm mb-2 magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row d-flex">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                            <p >
                                                                                <Link className="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            <p >
                                                                                <Link className="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                }

                                                            } else {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <Footer share_image_url={this.state.share_image_url} description={guestinfo.social_description} share_current_image_url={this.state.share_current_image_url} />
            </div >
        );
    }
}

Research.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Research);

