import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Truncate } from 'read-more-react';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import video_image from './assets/images/video_image.jpg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import 'react-image-lightbox/style.css';
// Translation Higher Order Component
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';


class Products_detail extends Component {
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            productquantity: 1,
            startIndex: 0,
            arrVideo: [],
            lightboxOpen: false,
            images: [],
            imagesonly:[],
            isOpen: false,
            share_current_image_url: '',
            share_image_url: '',
            social_description: '',
            eventinfo: {
                productreview: '',
                status: '',
                message: '',
            },
            posts: [],
            types: [],
            review: [],
            country: [],
            shipping_countrystate: [],
            flag: 0,
            value: 1,

            guestinfo: {
                type_id: '',
                no_of_pages: '',
                title: '',
                author: '',
                quantity: '',
                price: '',
                description: '',
                short_description: '',
                related_product_background: '',
                country_id: 101,

                images_array: [],
                currentProductTopics: [],
                added_in_cart: [],

            },
            editorState: EditorState.createEmpty(),

            downSlide: 0,
            upSlide: 0
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.submitLike = this.submitLike.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIncrement = this.handleChangeIncrement.bind(this);
        this.handleChangeDecrement = this.handleChangeDecrement.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.decreaseQuantity = this.decreaseQuantity.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);

        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);
        this.calculateShipping = this.calculateShipping.bind(this);
        this.upClick = this.upClick.bind(this);
        this.downClick = this.downClick.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.openGalleryVideo = this.openGalleryVideo.bind(this);
        this.closeGallery = this.closeGallery.bind(this);
    }


    openGalleryVideo(e) {
        $('.jss1').attr("style", "display: none !important");
        $("#openGallery").css({'display':'flex'});
        this.setState({ lightboxOpen: true });
        // var id = e.target.id;
        $('html,body').animate({
            scrollTop: 0
        }, 700);
        $('.slide-ico').css('display', 'none');
        this.state.photoIndex = e.target.id;
        $('#videoCloseIcon').css('display', 'inline');
        $('body').css('overflow', 'hidden');
        // $("#openGallery > div").css("z-index","99999"); 
        // $("#openGallery > div").css("z-index","99999 !important");
        // $( "#openGallery div" ).first().css("z-index","99999 !important"); 
        $('.fixed-top').css('z-index', '-9999999');
        $('.jss1').css('display', 'none !important');
    }
    closeGallery() {
        this.setState({ lightboxOpen: false });
        $('#videoCloseIcon').css('display', 'none');
        $('.slide-ico').css('display', 'block');
        $('body').css('overflow', 'scroll');
        // $('.fixed-top').css('z-index','9999999');
        $('.fixed-top').css('z-index', '9999999');
        $('#openGallery').css('display', 'none');
    }


    openGallery(e) {
        $('.jss1').attr("style", "display: none !important");
     //   $("#openGallery").css({'display':'flex'});
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }

    upClick() {
        localStorage.removeItem('scrolledDown');
        // alert('hi');
        var scrolled = localStorage.getItem('scrolled');
        if (scrolled == '' || scrolled == undefined) {
            var scrolled = 0;
        } else {
            var total = this.state.guestinfo.images_array.length * -100 + 500;
            if (total < scrolled) {
                var scrolled = localStorage.getItem('scrolled') - 100;
            } else {
                scrolled = 0;
            }

        }

        //    alert(scrolled);

        // var scrolled = scrolled - 100;
        localStorage.setItem('scrolled', scrolled);
        //alert(scrolled);
        // $(".vert-thumb").animate({
        //     scrollTop: scrolled
        // });

        $('.firstItem').css('margin-top', scrolled + 'px');
    }
    downClick() {
        //   alert('hi');
        localStorage.removeItem('scrolled');
        var scrolled = localStorage.getItem('scrolledDown');
        if (scrolled == '' || scrolled == undefined) {
            var scrolled = 0;
        } else {
            var total = this.state.guestinfo.images_array.length * 100 - 500;
            if (total > scrolled) {
                var scrolled = parseInt(localStorage.getItem('scrolledDown')) + 100;
            } else {
                scrolled = 0;
            }
            var scrolled = parseInt(localStorage.getItem('scrolledDown')) + 100;
        }
        //  alert(scrolled);

        localStorage.setItem('scrolledDown', scrolled);
        scrolled = scrolled + 100;



        $(".vert-thumb").animate({
            scrollTop: scrolled
        });
        console.log(scrolled);
        $('.firstItem').css('margin-top', scrolled + 'px');
    }
    calculateShipping() {
        

      
        var country = $('#country').val();
        var state = $('#state').val();
        if (state == '' || state == undefined) {
            var state = 0;
        }
        if (country == 101) {
            $('#foreignChargesDiv').css('display', 'none');
            $('#indiaChargesDiv').css('display', 'inline');
        } else {
            $('#foreignChargesDiv').css('display', 'inline');
            $('#indiaChargesDiv').css('display', 'none');
        }
        const answer_array = this.props.location.pathname.split('/');
        var product_id = answer_array['3'];
        var currentvalue = $('#qty'+product_id).val();
         
        fetch(myConstClass.BASE_URL + 'product/getProductShippingCharges/' + product_id + '/' + country + '/' + state+'/'+currentvalue)
            .then(response => response.json())
            .then(response => {
                this.setState({ eventinfo: response })
                var message = this.state.eventinfo.message;
                var status = this.state.eventinfo.status;
                if (status == 'true') {
                    var totalShippingChanrges = this.state.eventinfo.total_shipping_charges;
                    if(getLanguage() == 'ma'){
                        $('#totalShippingChanrges').html('(र�?.' + totalShippingChanrges + ')');
                    }else{
                        $('#totalShippingChanrges').html('(Rs.' + totalShippingChanrges + ')');
                    }
                    

                    if (country == 101) {
                        var domestic_per_kg = this.state.eventinfo.domestic_per_kg;
                        var regular_express = this.state.eventinfo.regular_express;
                        $('#domestic_per_kg').html(domestic_per_kg);
                        $('#regular_express').html(regular_express);

                        $('#noShippingChargesDiv').css('display', 'none');
                        $('#noteDiv').css('display', 'inline');
                    } else {
                        var first_500_grams = this.state.eventinfo.first_500_grams;
                        var add_500_grams = this.state.eventinfo.add_500_grams;
                        var above_10_kg = this.state.eventinfo.above_10_kg;
                        if (first_500_grams == 0 && add_500_grams == 0 && above_10_kg == 0) {
                            $('#noShippingChargesDiv').css('display', 'inline');
                            $('#foreignChargesDiv').css('display', 'none');
                            $('#noteDiv').css('display', 'none');
                        } else {
                            $('#noShippingChargesDiv').css('display', 'none');
                            $('#noteDiv').css('display', 'inline');
                            $('#first_500_grams').html(first_500_grams);
                            $('#add_500_grams').html(add_500_grams);
                            $('#above_10_kg').html(above_10_kg);
                            // toast(''+message, {containerId: 'B',type: toast.TYPE.ERROR});
                        }

                    }


                } else {
                    //toast(''+message, {containerId: 'B',type: toast.TYPE.ERROR});
                }

            })
            .catch(err => console.log(err))


        //alert('hi');
        $('#shippingModal').modal('show');
       
         this.setState({ productquantity: currentvalue });
        this.state.productquantity = currentvalue;
    }

    increaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val() === ''?0:$('#qty' + product_id).val()) + 1;
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    decreaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val() === ''?1:$('#qty' + product_id).val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    readMore(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'none');
        $('#ReadLessDiv').css('display', 'block');
    }

    readLess(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'block');
        $('#ReadLessDiv').css('display', 'none');
    }

    showMore(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'none');
        $('#showLessDiv_' + review_id).css('display', 'block');
    }

    showLess(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'block');
        $('#showLessDiv_' + review_id).css('display', 'none');
    }


    addToCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_quantity > 0 && product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addProductToCart/' + product_id + '/' + product_quantity + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {
                        // $('#'+product_id).css('display','none');
                        // $('#addedInCart_'+product_id).css('display','inherit');
                        $('#' + product_id).removeClass('btn-brown');
                        $('#' + product_id).addClass('btn-light');
                        $('#' + product_id).html('ADD MORE');
                        if (getLanguage() == 'en') {
                            $('#' + product_id).html('ADD MORE');
                        } else {
                            $('#' + product_id).html('अधिक जोडा');
                        }
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS,autoClose: 50000 });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    applyFilters() {
        $('#ShowAllReviewDiv').css('display', 'none');
        const answer_array = this.props.location.pathname.split('/');
        fetch(myConstClass.BASE_URL + 'product/getProductReviewFrontEnd/' + answer_array['3'] + '/200')
            .then(response => response.json())
            .then(response => this.setState({ review: response }))
            .catch(err => console.log(err))
    }

    submitReview(e) {

        e.preventDefault();
        console.log('this.refs', $('#pform').serializeArray());
        const formData = {};
        for (const field in this.refs) {
            formData[field] = this.refs[field].value;
        }
        const answer_array = this.props.location.pathname.split('/');
        var user_review_current = $('#user_review').val();
        var user_review = $.trim(user_review_current);
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {
            if (user_review != '') {
                var message = 'Your review added successfully,once admin approval will display it.';

                //fetch(myConstClass.BASE_URL+'review/saveProductReviewFrontEnd/'+user_id+'/'+answer_array['3']+'/'+user_review)
                fetch(myConstClass.BASE_URL + `review/saveProductReviewFrontEnd/` + user_id + '/' + answer_array['3'], {
                    method: 'POST',
                    body: JSON.stringify($('#pform').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventinfo: response })
                        var status = this.state.eventinfo.status;
                        var message = this.state.eventinfo.message;
                        if (status == 'true') {
                            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                            //setTimeout(this.props.history.push('/products'),1000);
                        } else {
                            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                            //toast("Wow so easy !");
                        }
                    })
                    .catch(err => console.log(err))
                // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

                $('#user_review').val('');
            } else {
                var message = "Please enter review.";
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for submitting review.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }



    submitLike() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {

            fetch(myConstClass.BASE_URL + 'review/saveProductLikeFrontEnd/' + user_id + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    if (status == 'true') {
                        var productLikeCount = $('#productLikeCount').html();
                        var productLikeCountNew = parseInt(productLikeCount) + 1;
                        $('#productLikeCount').html(productLikeCountNew);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        //setTimeout(this.props.history.push('/products'),1000);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        //toast("Wow so easy !");
                    }
                })
                .catch(err => console.log(err))
            // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for like product.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }

    componentDidMount() {
        localStorage.removeItem('scrolled');
        localStorage.removeItem('scrolledDown');
        const answer_array = this.props.location.pathname.split('/');
        var current_language = getLanguage();
        var user_id = localStorage.getItem('user_id');
        localStorage.setItem('current_language', current_language);
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        if(getLanguage() == 'ma'){
            $('.relatedImageMarathi').css('display','block');
            $('.relatedImageEnglish').css('display','none');
        }else{
             $('.relatedImageMarathi').css('display','none');
            $('.relatedImageEnglish').css('display','block');
        }
        //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
        //   .then(response => response.json())
        //   .then(response =>this.setState({types: response}))
        //   .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductReviewFrontEnd/' + answer_array['3'] + '/3')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })


                fetch(myConstClass.BASE_URL + 'product/getImages' + '/' + answer_array['3'])
                    .then(response => response.json())
                    .then(response => this.setState({ images: response }))
                    .catch(err => console.log(err))

                const numRows = this.state.review.length;
                if (numRows < 1) {
                    $('#ShowAllReviewDiv').css('display', 'none')
                    $('#no_review_found').css('display', 'inline')
                }
            })


            .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getImagesOnly' + '/' + answer_array['3'])
                    .then(response => response.json())
                    .then(response => this.setState({ imagesonly: response }))
                    .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductVideoFrontEnd/' + answer_array['3'])
            .then(response => response.json())
            .then(response => {
                this.setState({ arrVideo: response })
                // var numRow = this.state.arrVideo.length;
                // if(numRow > 0){
                //     $('#carouselExampleControls1').css('display','inline');
                // }else{
                //     $('#carouselExampleControls1').css('display','none');
                // }
            })
            .catch(err => console.log(err))

        // alert(answer_array);
        if (answer_array['3']) {

            fetch(myConstClass.BASE_URL + 'product/getProductInfoFrontEnd/' + answer_array['3'] + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        guestinfo: response
                    })
                    var type_id_current = this.state.guestinfo.type_id;
                    var desc = "";
                    //if(this.state.guestinfo.description != ''){
                    // var desc = Base64.decode(this.state.guestinfo.description);
                    var desc = (this.state.guestinfo.description);
                    this.state.guestinfo.description = this.state.guestinfo.description;
                    this.state.guestinfo.short_description = this.state.guestinfo.short_description;
                    this.setState({ social_description: this.state.guestinfo.social_description });
                    this.setState({
                        downSlide: response.images_array.length
                    })
                    this.state.guestinfo.images_array.map((info, ii) => {
                        if (ii == '0') {
                            this.setState({ share_image_url: info.image_name });
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            //alert('1'+this.state.share_image_url);
                        }
                    });
                    //   if(this.state.guestinfo.short_description == ''){
                    //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';
                    var type_id = this.state.guestinfo.type_id;
                    if (type_id == 3) {
                        $('#AllAudioVideoDiv').css('display', 'block');
                    } else {
                        $('#AllAudioVideoDiv').css('display', 'none');
                    }

                    //alert(desc);
                    var descripotion_length = desc.length;
                    if (descripotion_length < 320 || descripotion_length == undefined) {
                        $('#readMoreButton').css('display', 'none');
                    }
                    //alert(type_id_current);
                    if (type_id_current == 3) {
                        $('#contentDiv').css('display', 'none');
                        $('#pagesDiv').css('display', 'none');
                        $('#authorDiv').css('display', 'none');
                        $('#agegroupDiv').css('display', 'none');
                    }
                    else if (type_id_current == 2) {
                        $('#contentDiv').css('display', '-webkit-box');
                        $('#pagesDiv').css('display', 'none');
                        $('#authorDiv').css('display', 'none');
                        $('#agegroupDiv').css('display', 'none');
                    } else if (type_id_current == 4) {
                        $('#contentDiv').css('display', '-webkit-box');
                        $('#agegroupDiv').css('display', '-webkit-box');
                        $('#pagesDiv').css('display', 'none');
                        $('#authorDiv').css('display', 'none');
                    } else if (type_id_current == 5) {
                        $('#contentDiv').css('display', '-webkit-box');
                        $('#agegroupDiv').css('display', 'none');
                        $('#pagesDiv').css('display', 'none');
                        $('#authorDiv').css('display', 'none');
                    } else {
                        $('#contentDiv').css('display', 'none');
                        $('#agegroupDiv').css('display', 'none');
                        if (type_id_current == 6 || type_id_current == 7) {
                            $('#authorDiv').css('display', 'none');
                        }
                    }
                })
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getRelatedProducts/' + answer_array['3'] + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => this.setState({ posts: response }))
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                })
                .catch(err => console.log(err))

        }
        this.getCountry();
        this.getStateCountryWiseEdit(101);
    }


    componentDidUpdate(prevProps) {
        if(getLanguage() == 'ma'){
            $('.relatedImageMarathi').css('display','block');
            $('.relatedImageEnglish').css('display','none');
        }else{
             $('.relatedImageMarathi').css('display','none');
            $('.relatedImageEnglish').css('display','block');
        }
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        // alert(answer_array);
        var user_id = localStorage.getItem('user_id');

        var current_product_id = answer_array['3'];
        var old_product_id = localStorage.getItem('old_product_id');
        localStorage.removeItem('old_product_id');
        localStorage.setItem('old_product_id', current_product_id);

        //alert(current_product_id);
        if (current_product_id != '' && old_product_id != current_product_id && current_product_id != null && old_product_id > 0) {
            this.setState({ flag: 1 });
            console.log(old_product_id);
        } else {
            var current_language = localStorage.getItem('current_language');

            if (current_language != getLanguage() && flag != 1 && current_language != null) {
                //alert(current_language);
                localStorage.removeItem('current_language');
                localStorage.setItem('current_language', getLanguage());
                this.setState({ flag: 1 });
            }
        }

        if (getLanguage() == 'ma' && flag == 1) {

            // e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            if (answer_array['3']) {


                fetch(myConstClass.BASE_URL + 'product/getProductReviewFrontEnd/' + answer_array['3'] + '/3')
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ review: response })


                        fetch(myConstClass.BASE_URL + 'product/getImages' + '/' + answer_array['3'])
                            .then(response => response.json())
                            .then(response => this.setState({ images: response }))
                            .catch(err => console.log(err))

                        const numRows = this.state.review.length;
                        if (numRows < 1) {
                            $('#ShowAllReviewDiv').css('display', 'none')
                            $('#no_review_found').css('display', 'inline')
                        }
                    })


                fetch(myConstClass.BASE_URL + 'product/getProductInfoFrontEnd/' + answer_array['3'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({
                            guestinfo: response
                        })
                        var type_id_current = this.state.guestinfo.type_id;
                        var desc = "";
                        //if(this.state.guestinfo.description != ''){
                        //   var desc = Base64.decode(this.state.guestinfo.description);
                        var desc = (this.state.guestinfo.description);
                        this.setState({ social_description: this.state.guestinfo.social_description });
                        this.state.guestinfo.images_array.map((info, ii) => {
                            if (ii == '0') {
                                this.setState({ share_image_url: info.image_name });
                                this.setState({ share_current_image_url: window.location.href });
                                if (this.state.share_image_url) {
                                    $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                                }
                                //alert('1'+this.state.share_image_url);
                            }
                        });

                        //   if(this.state.guestinfo.short_description == ''){
                        //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';


                        //alert(desc);
                        var descripotion_length = desc.length;
                        if (descripotion_length < 320 || descripotion_length == undefined) {
                            $('#readMoreButton').css('display', 'none');
                        }
                        //alert(type_id_current);
                        if (type_id_current == 3) {
                            $('#contentDiv').css('display', 'none');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        }
                        else if (type_id_current == 2) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        } else if (type_id_current == 4) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#agegroupDiv').css('display', '-webkit-box');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                        } else if (type_id_current == 5) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#agegroupDiv').css('display', 'none');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                        }
                        else {
                            $('#contentDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        }
                    })
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    })
                    .catch(err => console.log(err))

                fetch(myConstClass.BASE_URL + 'product/getRelatedProducts/' + answer_array['3'] + '/' + getLanguage() + '/' + user_id)
                    .then(response => response.json())
                    .then(response => this.setState({ posts: response }))
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    })
                    .catch(err => console.log(err))

            }
        }

        if (getLanguage() == 'en' && (flag == 1)) {

            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            if (answer_array['3']) {

                fetch(myConstClass.BASE_URL + 'product/getProductReviewFrontEnd/' + answer_array['3'] + '/3')
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ review: response })


                        fetch(myConstClass.BASE_URL + 'product/getImages' + '/' + answer_array['3'])
                            .then(response => response.json())
                            .then(response => this.setState({ images: response }))
                            .catch(err => console.log(err))

                        const numRows = this.state.review.length;
                        if (numRows < 1) {
                            $('#ShowAllReviewDiv').css('display', 'none')
                            $('#no_review_found').css('display', 'inline')
                        }
                    })



                fetch(myConstClass.BASE_URL + 'product/getProductInfoFrontEnd/' + answer_array['3'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({
                            guestinfo: response
                        })
                        var type_id_current = this.state.guestinfo.type_id;
                        var desc = "";
                        //if(this.state.guestinfo.description != ''){
                        var desc = (this.state.guestinfo.description);
                        this.setState({ social_description: this.state.guestinfo.social_description });
                        this.state.guestinfo.images_array.map((info, ii) => {
                            if (ii == '0') {
                                this.setState({ share_image_url: info.image_name });
                                this.setState({ share_current_image_url: window.location.href });
                                if (this.state.share_image_url) {
                                    $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                                }
                                //alert('1'+this.state.share_image_url);
                            }
                        });

                        //   if(this.state.guestinfo.short_description == ''){
                        //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';


                        //alert(desc);
                        var descripotion_length = desc.length;
                        if (descripotion_length < 320 || descripotion_length == undefined) {
                            $('#readMoreButton').css('display', 'none');
                        }
                        //alert(type_id_current);
                        if (type_id_current == 3) {
                            $('#contentDiv').css('display', 'none');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        }
                        else if (type_id_current == 2) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        } else if (type_id_current == 4) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#agegroupDiv').css('display', '-webkit-box');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                        } else if (type_id_current == 5) {
                            $('#contentDiv').css('display', '-webkit-box');
                            $('#agegroupDiv').css('display', 'none');
                            $('#pagesDiv').css('display', 'none');
                            $('#authorDiv').css('display', 'none');
                        }
                        else {
                            $('#contentDiv').css('display', 'none');
                            $('#agegroupDiv').css('display', 'none');
                        }
                    })
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    })
                    .catch(err => console.log(err))

                fetch(myConstClass.BASE_URL + 'product/getRelatedProducts/' + answer_array['3'] + '/' + getLanguage() + '/' + user_id)
                    .then(response => response.json())
                    .then(response => this.setState({ posts: response }))
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    })
                    .catch(err => console.log(err))

            }
        }



    }


    getStateCountryWise = _ => {
        var shipping_countryid = $('#country').val();
        if (shipping_countryid != 101) {
            $('#stateDiv').css('display', 'none');
        } else {
            $('#stateDiv').css('display', 'inline');
        }
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))

    }
    getStateCountryWiseEdit(shipping_countryid) {
        //var shipping_countryid = $('#country').val();
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))

    }


    getCountry = _ => {
        fetch(myConstClass.BASE_URL + 'getAllCountry')
            .then(response => response.json())
            .then(response => this.setState({ country: response }))
            .catch(err => console.log(err))
        // console.log("this.setState.data",this.state.country);

    }
    markDown(text) {
        // alert(text);
        if (text && text != undefined) {
            //Here I replace special chars for html tags, this is the example: __ Text in bold __
            return text.replace(/__(.*?)__((_+|\W+|$))/g, '<strong>$1</strong>$2');
        }
    }

    handleChange(event) {
        //   console.log(event.target.id);
        // alert(event.target.value);
        var currentvalue = event.target.value;
        var product_id = event.target.id;

        this.setState({ productquantity: currentvalue });
        this.state.productquantity = currentvalue;
    }

    handleChangeIncrement(event) {
        var qty = parseInt($('#qty').val()) + 1;

        this.setState({ value: qty });
    }

    handleChangeDecrement(event) {
        var qty = parseInt($('#qty').val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        this.setState({ value: qty });
    }


    render() {

        const { photoIndex, isOpen, lightboxOpen, shipping_countrystate, country, posts, guestinfo, review } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <i style={{ cursor: 'pointer', display: 'none' }} onClick={this.closeGallery} className="fa fa-times" aria-hidden="true" id="videoCloseIcon2"></i>
                <div>
                    <ToastContainer />
                </div>
                <Header />

                <Container>
                    <Row>
                        <div className="col-lg-7 mt-3">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>


                                        </li>
                                        <li className="breadcrumb-item">

                                            <Link to="/products">{t('home.products')}</Link>

                                        </li>
                                        <li className="breadcrumb-item">

                                            <Link to={'/product_type/' + guestinfo.type_name + '/' + guestinfo.type_id}>{guestinfo.type_title}</Link>

                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{guestinfo.title}</li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="row align-items-end">
                                <div className="col-12 col-md-8">
                                    {guestinfo.currentProductTopics.map((topicss, ii) => {
                                        if (ii == 0) {
                                            return (
                                                <Link to={'/topics/' + topicss.label + '/' + topicss.value}>
                                                    <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{topicss.label}</button>
                                                </Link>

                                            )
                                        } else {
                                            return (
                                                <Link to={'/topics/' + topicss.label + '/' + topicss.value}>
                                                    <button style={{ marginLeft: '5px' }} type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{topicss.label}</button>
                                                </Link>

                                            )
                                        }
                                    })
                                    }
                                    <h4 className="mb-0 mt-3">{guestinfo.title}</h4>

                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="row align-items-end text-right">
                                        <div className="col text-right">
                                            <span className="mr-2 d-inline-block pr-2">
                                                <i className="fas fa-comment-dots orangeColor"></i> <span className="small">{guestinfo.total_review}</span>
                                            </span>
                                            <span className="d-inline-block pr-2">
                                                <i className="fas fa-thumbs-up orangeColor"></i> <span className="small" id="productLikeCount">{guestinfo.total_like}</span>
                                            </span>
                                            <span className="d-inline-block pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-thumbs-up color-brown"></i> <br /> <span className="small d-block mt-n1" >{t('home.LIKE THIS')}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-right">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <Col xl="7" lg="7" md="12" >
                            <div id="demo" className="carousel slide vert product-slider" data-ride="carousel" >
                                <Row>
                                    <Col md="2" sm="4" xs="3" className="text-center">
                                        <div>

                                            <i className="fa fa-chevron-up fa-2x " style={{ color: '#6a6a6a', cursor: 'pointer', zIndex: '999999' }} href="#demo" role="button" data-slide="prev"></i>


                                        </div>

                                        <div className="carousel-indicators div_height123 vert-thumb">
                                            {guestinfo.images_array.map((info, ii) => {
                                                if (info.type_main == 2) {
                                                    if (ii == 0) {
                                                        return (
                                                            <div data-target="#demo" data-slide-to={ii} className="item active">
                                                                <img className="image_carosal 123" src={info.you_tube_url} alt="" />
                                                            </div>
                                                        )
                                                    } else {

                                                        return (
                                                            <div data-target="#demo" data-slide-to={ii} className="item 222">
                                                                <img className="image_carosal 123" src={info.you_tube_url} alt="" />
                                                            </div>
                                                        )
                                                    }

                                                } else {
                                                    if (ii == 0) {
                                                        return (<div data-target="#demo" data-slide-to={ii} className="item active"><img style={{ cursor: 'pointer' }} id={ii} className="image_carosal" src={info.image_name} alt="" /></div>
                                                        )
                                                    } else {
                                                        return (<div data-target="#demo" data-slide-to={ii} className="item "><img style={{ cursor: 'pointer' }} id={ii} className="image_carosal" src={info.image_name} alt="" /></div>
                                                        )
                                                    }
                                                }
                                            }

                                            )}

                                        </div>

                                        <div>
                                            <i className="fa fa-chevron-down fa-2x " id='downClick' style={{ color: '#6a6a6a', cursor: 'pointer' }} href="#demo" role="button" data-slide="next"></i>
                                        </div>
                                    </Col>


                                    <Col md="10" sm="8" xs="9" className="align-self-center">
                                        <div className="carousel-inner border" style={{ background: '#f9f9f9' }}>
                                            {guestinfo.images_array.map((info, ii) => {
                                                if (info.type_main == 2) {
                                                    if (ii == 0) {
                                                        return (
                                                            <div className="carousel-item active" >
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="div1">
                                                                        <img style={{ cursor: 'pointer' }} src={info.you_tube_url} className="card-img-top galleryResponsiveImage" alt="..." id={ii} onClick={this.openGalleryVideo} />
                                                                        <div className="overlay">
                                                                            <a style={{ cursor: 'pointer' }} href="#" className="icon" title="User Profile" onClick={this.openGalleryVideo}>
                                                                                <i className="fab fa-youtube color-red" ></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (<div className="carousel-item">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <div className="div1">
                                                                    <img style={{ cursor: 'pointer' }} src={info.you_tube_url} id={ii} className="card-img-top galleryResponsiveImage" alt="..." onClick={this.openGalleryVideo} />
                                                                    <div className="overlay">
                                                                        <a href="#" className="icon" title="" onClick={this.openGalleryVideo}>
                                                                            <i className="fab fa-youtube color-red" ></i>
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>
                                                        )
                                                    }
                                                } else {
                                                    if (ii == 0) {
                                                        return (<div className="carousel-item active" >
                                                            <img style={{ cursor: 'pointer' }} id={ii} className="image_main_carosal productDetailsImageNew" src={info.image_name} onClick={this.openGallery} />
                                                        </div>
                                                        )
                                                    } else {
                                                        return (<div className="carousel-item">
                                                            <img style={{ cursor: 'pointer' }} id={ii} className="image_main_carosal productDetailsImageNew" src={info.image_name} onClick={this.openGallery} />
                                                        </div>
                                                        )
                                                    }
                                                }


                                            }

                                            )}

                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </Col>
                        <Col xl="5" lg="5" md="12">
                            <div className="row" id="agegroupDiv" style={{ display: 'none' }}>
                                <div className="col-3">
                                    <p>{t('home.Age Group')}:</p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder">{guestinfo.age_group}</span></p>
                                </div>
                            </div>

                            <div className="row" id="contentDiv">
                                <div className="col-3">
                                    <p>{t('home.Content')}:</p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder">{guestinfo.content}</span></p>
                                </div>
                            </div>

                            <div className="row" id="authorDiv">
                                <div className="col-3">
                                    <p>{t('home.author')}</p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder">{guestinfo.author}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>{t('home.b_lang')}:</p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder">{guestinfo.language}</span></p>
                                </div>
                            </div>
                            <div className="row" id="pagesDiv">
                                <div className="col-3">
                                    <p>{t('home.pages')}:</p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder">{guestinfo.no_of_pages}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>{t('home.donation_contribution')}:
                        </p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder h5">{t('home.INR')} {guestinfo.price}
                                    </span><br />
                                        {/* <span className="small"> </span> */}



                                        {t('home.Shipping charges extra')}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>{t('home.Weight')}:
                        </p>
                                </div>
                                <div className="col-9">
                                    <p><span className="font-weight-bolder h5">
                                        {guestinfo.weight_to_display}
                                    </span>
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>{t('home.quantity')}:
                        </p>
                                </div>
                                <div className="col-9">
                                    <div className="qty mb-3">
                                        {/* <span className="minus border">
                                        <span className="d-flex align-items-center ico-hld" onClick={this.handleChangeDecrement}>
                                            <i className="fas fa-minus"></i>
                                        </span>
                            </span>
                            <input type="number" className="count" name={guestinfo.product_id} id={guestinfo.product_id} value={this.state.value} onChange={this.handleChange}/>
                            <span className="plus border">
                                            <span className="d-flex align-items-center ico-hld" onClick={this.handleChangeIncrement}>
                                                <i className="fas fa-plus"></i>
                                            </span>
                            </span> */}
                                        <span className="minus border" onClick={this.decreaseQuantity.bind(this, guestinfo.product_id)}>
                                            <span className="d-flex align-items-center ico-hld">
                                                <i className="fas fa-minus"></i>
                                            </span>
                                        </span>
                                        <input type="text" className="count" value={this.state.productquantity} name={"qty" + guestinfo.product_id} id={"qty" + guestinfo.product_id} onChange={this.handleChange} />
                                        <span className="plus border" name={"qty_inc" + guestinfo.product_id} id={"qty_inc" + guestinfo.product_id} onClick={this.increaseQuantity.bind(this, guestinfo.product_id)}>
                                            <span className="d-flex align-items-center ico-hld"  >
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </span>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3">
                                    {t('home.Shipping Charges')}:
                                </div>
                                <div className="col-9">
                                    <Row>
                                        <Col xl="4" lg="4" md="4" sm="12" xs="12">
                                            <Input type="select" value={guestinfo.country_id} onChange={e => { this.getStateCountryWise(); this.setState({ guestinfo: { ...guestinfo, country: e.target.value } }) }} id="country" name="country" >
                                                {/* <option value="" >{t('home.Select Country')}</option> */}
                                                {country.map((info, ii) => {
                                                    if (info.id == 101) {
                                                        return (
                                                            <option selected="selected" value={info.id} >{info.name}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.id} >{info.name}</option>
                                                        )
                                                    }

                                                })
                                                }
                                            </Input>
                                            {/* <input type="text" className="form-control form-control-sm" id="" aria-describedby="" placeholder="Enter pincode"/> */}

                                            {/* <small id="emailHelp" className="form-text text-muted">{t('home.Delivery in 3 days')}</small> */}
                                        </Col>
                                        <Col xl="4" lg="4" md="4" sm="12" xs="12" id="stateDiv" className="mt-xl-0 mt-lg-0 mt-md-0  mt-2">
                                            <Input type="select" value={guestinfo.state} onChange={e => { this.setState({ guestinfo: { ...guestinfo, state: e.target.value } }) }} type="select" id="state" name="state" placeholder={t('home.Select State / Province')} >
                                                {/* <option value="" >{t('home.Select State/Province')}</option> */}
                                                {shipping_countrystate.map((info, ii) => {
                                                    if (info.id == 22) {
                                                        return (
                                                            <option selected="selected" value={info.id} >{info.name}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.id} >{info.name}</option>
                                                        )
                                                    }

                                                })
                                                }
                                            </Input>
                                        </Col>
                                        <Col xl="4" lg="4" md="4" sm="12" xs="12" className="mt-xl-0 mt-lg-0 mt-md-0  mt-2 align-self-center">
                                            <Button className="btn-brown btn-outline-brown px-1" onClick={this.calculateShipping} size="sm">{t('home.Show Rate')}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 col-12 mt-2 mb-2">

                                    {guestinfo.added_in_cart.map((carts, j) => {
                                        if (carts.cart_id > 0) {
                                            return (
                                                <span>
                                                    {/* <button id={'addedInCart_'+guestinfo.product_id} className="btn btn-sm btn-light">{t('home.ADD MORE')}</button> */}
                                                    <button className="btn btn-sm btn-light btn-block" name={guestinfo.product_id} id={guestinfo.product_id} onClick={this.addToCart}>{t('home.ADD MORE')}</button>
                                                </span>
                                            )
                                        } else {
                                            
                                             if( guestinfo.out_of_stock=='1') {  
                                          return (  
                                                <span>
                                                    <button style={{ display: 'none' }} id={'addedInCart_' + guestinfo.product_id} className="btn btn-sm btn-light">{t('home.ADD MORE')}</button>
                                                    <button  className="btn btn-brown btn-block" >OUT OF STOCK</button>
                                                </span>
                                                )
                                            } else {
                                            return (
                                                <span>
                                                    <button style={{ display: 'none' }} id={'addedInCart_' + guestinfo.product_id} className="btn btn-sm btn-light">{t('home.ADD MORE')}</button>
                                                    <button className="btn btn-brown btn-block" name={guestinfo.product_id} id={guestinfo.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                </span>
                                                )
                                            }
                                        }
                                    })
                                    }
                                    {/* <button className="btn btn-brown" name={guestinfo.product_id} id={guestinfo.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button> */}

                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col productDescriptionUL" >

                                    {/* {guestinfo.description} */}
                                    <span id="ReadMoreDiv" >
                                        {ReactHtmlParser(guestinfo.short_description)}
                                        <br /> <button id="readMoreButton" onClick={this.readMore} className="btn orangeColor btn-link">{t('home.Read more')}</button>
                                    </span>

                                    <span id="ReadLessDiv" style={{ display: 'none' }}>
                                        {ReactHtmlParser(guestinfo.description)}
                                        {/* {Base64.decode(guestinfo.description)} */}
                                        <br /> <button onClick={this.readLess} className="btn orangeColor btn-link">{t('home.Read less')}</button>
                                    </span>
                                    {/* <span style={(guestinfo.description.length > 8000)? 'display' : 'hidden'}>Read more</span> */}
                                    <br />
                                    <div style={{ marginTop: '10px', display: 'none' }} id="AllAudioVideoDiv">
                                        {t('home.**All audio-video CDs are available in ManaTarang App on Play Store')}

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col
                            xl={{ size: 11, offset: 1 }}
                            lg={{ size: 11, offset: 1 }}
                            md={{ size: 11, offset: 1 }}
                            sm={{ size: 12 }}
                            xs={{ size: 12 }} className="lead font-weight-bolder">
                            <hr />
                            {t('home.REVIEWS')}
                        </Col>
                    </Row>

                    <Row>
                        {/* <div className="col-md-1 col-1 text-center"></div> */}
                        {review.map((info, ii) => {
                            if (info.description.length < 210) {
                                return (
                                    <Col xl={{ size: 5, offset: 1 }}
                                        lg={{ size: 5, offset: 1 }}
                                        md={{ size: 5, offset: 1 }}
                                        sm={{ size: 12 }}
                                        xs={{ size: 12 }}>
                                        <p style={{ minHeight: '10px' }}>{info.description}
                                            {/* <span className="orangeColor"><br/>Show more...</span> */}
                                        </p>
                                        <p className="font-weight-bolder">{info.name}, {info.address} | {info.created_on}</p>
                                    </Col>
                                )
                            } else {
                                return (

                                    <Col xl={{ size: 5, offset: 1 }}
                                        lg={{ size: 5, offset: 1 }}
                                        md={{ size: 5, offset: 1 }}
                                        sm={{ size: 12 }}
                                        xs={{ size: 12 }}>
                                        <p id={'showMoreDiv_' + info.review_id} style={{ minHeight: '93px' }}>{info.short_description}
                                            <span className="orangeColor" id={'showMoreSpan_' + info.review_id} onClick={this.showMore} style={{ cursor: 'pointer' }}><br />{t('home.Show more')}</span>
                                        </p>

                                        <p id={'showLessDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>{info.description}
                                            <span className="orangeColor" id={'showLesSpan_' + info.review_id} onClick={this.showLess} style={{ cursor: 'pointer' }}><br />{t('home.Show less')}</span>
                                        </p>

                                        <p className="font-weight-bolder">{info.name}, {info.address} | {info.created_on}</p>
                                    </Col>


                                )
                            }
                        }

                        )}
                    </Row>


                    {/* <div className="row">
            <div className="col-6">
                <p>I had attended the 3 days Garbh Samarthya course in the month of March 2012 and that time I was 8 months pregnant. The experience was so nice, I felt the immediate difference at the same time....
                    <span className="orangeColor"><br/>Show more...</span>
                </p>
                <p className="font-weight-bolder">Shreya S. Pednekar, Pune | June 23, 2017</p>
            </div>
            <div className="col-6">
                <p>I had attended the 3 days Garbh Samarthya course in the month of March 2012 and that time I was 8 months pregnant. The experience was so nice, I felt the immediate difference at the same time....
                    <span className="orangeColor"><br/>Show more...</span>
                </p>
                <p className="font-weight-bolder">Shreya S. Pednekar, Pune | June 23, 2017</p>
            </div>
        </div> */}
                    <Row id="no_review_found" style={{ display: 'none' }}>
                        <Col xl={{ size: 11, offset: 1 }} className="px-0">
                            {t('home.Be the first to the review this product')}
                        </Col>
                    </Row>
                    <Row id="ShowAllReviewDiv">
                        <Col xl={{ size: 3, offset: 1 }} lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 12 }} xs={{ size: 12 }} className="text-xl-left">
                            <button className="btn btn-outline-brown btn-block" onClick={this.applyFilters}>{t('home.SHOW ALL')}</button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={{ size: 11, offset: 1 }} className="px-0">
                            <Form action="" id="pform" onSubmit={this.submitReview} method="post" encType="multipart/form-data" className="form-horizontal">
                                <div className="col-md-12 mt-3">
                                    <div className="form-group" >
                                        <label for="" className=" font-weight-bolder">{t('home.Please add your review')}</label>
                                        <textarea id="user_review" name="user_review" className="form-control" placeholder={t('home.Type here')} rows="4"></textarea>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <button className="btn btn-outline-brown btn-block" >{t('home.SUBMIT')}</button>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <div className="row" >
                        <div className="col-md-1 col-1 text-center"></div>
                        <div className="col-lg-11 mt-3">
                            <div className="row">

                               


                                 <div className="col p-3 text-white mb-4 relatedProductsImage relatedImageEnglish" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + "title-bg.png)", minHeight: '80px', backgroundSize: 'contain', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',display:'none' }}>

                                    </div>
                                    <div className="col p-3 text-white mb-4 relatedProductsImage relatedImageMarathi" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + "Related_Products_mr.jpg)", minHeight: '80px', backgroundSize: 'contain', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',display:'none' }}>

                                    </div>

                            </div>
                            <Row >

                                {posts.map((info, ii) => {

                                    return (
                                        <Col xl="3" lg="3" md="6" sm="6" xs="6" className="mb-3">
                                            <Card className="text-center">
                                                <Link to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                    <div className="image-container px-2 py-2">
                                                        <img className="image_css_product" src={info.image_name} />
                                                    </div>
                                                </Link>

                                                <CardBody className="py-3">
                                                    <Row>
                                                        <Col xs="12">
                                                            <span className="mr-2">
                                                                <i className="far fa-comment-dots orangeColor"></i> <span className="small">{info.total_review}</span>
                                                            </span>
                                                            <span>
                                                                <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{info.total_like}</span>
                                                            </span>
                                                        </Col>

                                                        <Col xs="12" className="px-1 text-truncate mh--100-px">
                                                            <Link className="font-weight-bold" style={{ color: 'black' }} to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                                {info.short_title}
                                                            </Link>
                                                        </Col>
                                                        <Col xs="12" className="px-1">
                                                            {t('home.donation_contribution')}
                                                        </Col>
                                                        <Col xs="12" className="font-weight-bolder">
                                                            {t('home.INR')} {info.price}/-
                                                    </Col>

                                                        <Col xs="12" className="qty mb-3 px-0">
                                                            <span>{t('home.Qty')} &nbsp;</span>
                                                            <span className="minus border" onClick={this.decreaseQuantity.bind(this, info.product_id)}>
                                                                <span className="d-flex align-items-center ico-hld">
                                                                    <i className="fas fa-minus"></i>
                                                                </span>
                                                            </span>
                                                            <input type="text" className="count" value="1" name={"qty" + info.product_id} id={"qty" + info.product_id} onChange={this.handleChange} />
                                                            <span className="plus border" name={"qty_inc" + info.product_id} id={"qty_inc" + info.product_id} onClick={this.increaseQuantity.bind(this, info.product_id)}>
                                                                <span className="d-flex align-items-center ico-hld"  >
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </span>
                                                        </Col>

                                                        <Col xs="12" className="px-0">
                                                            {info.added_in_cart.map((carts, j) => {
                                                                if (carts.cart_id > 0) {
                                                                    return (
                                                                        <span>
                                                                            <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD MORE')}</button>
                                                                        </span>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <span>
                                                                            <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} className="btn btn-sm btn-light">{t('home.ADD MORE')}</button>
                                                                            <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                        </span>
                                                                    )
                                                                }
                                                            })
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                })
                                }

                            </Row>
                            <div className="row">
                                <div className="col text-center">
                                    {/* <button className="btn btn-outline-brown">{t('home.SHOW MORE')}</button> */}
                                    <Link className="btn btn-outline-brown" to={"/product_type/" + guestinfo.type_name + '/' + guestinfo.type_id}>
                                        {t('home.SHOW MORE')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

                <div className="modal fade z-index--99999" id="shippingModal" style={{ marginTop: '0px' }}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-l" role="document">
                        <div className="modal-content bg-light">
                            <div className="modal-header" style={{ background: '#9d0b0e', }}>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '0px', color: 'white', fontSize: '15px' }}>

                                    {t('home.SHIPPING CHARGES')} -&nbsp;<span id="totalShippingChanrges"></span>
                                </h5>
                                {/* <button type="button" className="close">
                                    <i className="far fa-times-circle"></i>
                                    
                          </button> */}
                                <span style={{ color: 'white', cursor: 'pointer' }} data-dismiss="modal" aria-label="Close">X</span>
                            </div>
                            <div className="modal-body" style={{ marginLeft: '25px' }}>
                                <div className="row">

                                    <div className="col">
                                        <div id="indiaChargesDiv">
                                            <p>
                                                1. {t('home.Charges upto 1 kg are INR')} <span id="domestic_per_kg"></span>/-
                                            </p>
                                            <p>
                                                2. {t('home.Premium Express Service charges upto 1 kg are INR')} <span id="regular_express"></span>/-
                                            </p>
                                            <p>
                                                3. {t('home.To save money, check if you can add more products')}
                                            </p>

                                            <p>
                                                4. {t('home.Total charges for all selected products on Checkout page')}
                                            </p>
                                        </div>


                                        <div id="foreignChargesDiv" style={{ display: 'none' }}>
                                            <p>
                                                1. {t('home.Charges first 500 gram are INR')}&nbsp; <span id="first_500_grams"></span>

                                            </p>
                                            <p>
                                                2. {t('home.Charges additional 500 gram are INR')}&nbsp; <span id="add_500_grams"></span>

                                                {t('home.Charges above 10 kg are INR')}&nbsp;
{/*
                                    <span id="above_10_kg"></span>
                                */}
                                            </p>
                                            <p>
                                                3. {t('home.Charges for the part of the weight above 10 kg are INR')}&nbsp;<span id="above_10_kg"></span>&nbsp; {t('home.per additional kg')}
                                            </p>
                                            <p>
                                                2. {t('home.To save money, check if you can add more products')}
                                            </p>

                                            <p>
                                                3. {t('home.Total charges for all selected products on Checkout page')}
                                            </p>

                                        </div>

                                        <div id="noShippingChargesDiv" style={{ display: 'none' }}>
                                            {t('home.Parcel cannot be sent for selected country')}
                                        </div>

                                        {/* <br/>
                          <div id="noteDiv">
                            <p>
                                <span style={{color:'red'}}>{t('home.NOTE')}</span>
                            </p>
                            <p >
                            {t('home.The charges in 1st & 2nd line will change as the kg slab will change')}
                            
                            </p>
                          </div> */}

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">


                                        {/* <span style={{color:'white',cursor:'pointer'}} className="btn btn-brown btn-sm mb-2" data-dismiss="modal" aria-label="Close">
                    {t('home.CLOSE')}
                        
                        </span> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                    <span data-dismiss="modal" aria-label="Close">Cancel</span>
                    <button type="button" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4">SAVE</button>
                </div> */}
                        </div>
                    </div>
                </div>


                <Footer share_current_image_url={this.state.share_current_image_url} share_image_url={this.state.share_image_url} description={this.state.social_description} />

                <div id="openGallery" className="openGallerydiv"  >
                <span ><i style={{ cursor: 'pointer' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon"></i></span>;

                    {lightboxOpen && (
                        <ReactImageVideoLightbox
                            data={this.state.arrVideo}
                            startIndex={this.state.startIndex}
                            showResourceCount={true}
                            onCloseCallback={this.callbackFunction} />
                    )}

                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.imagesonly[photoIndex]['image_url']}
                        nextSrc={this.state.imagesonly[(photoIndex + 1) % this.state.imagesonly.length]['image_url']}
                        prevSrc={this.state.imagesonly[(photoIndex + this.state.imagesonly.length - 1) % this.state.imagesonly.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.imagesonly.length - 1) % this.state.imagesonly.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.imagesonly.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
Products_detail.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Products_detail);
