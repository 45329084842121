import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import checkoutImage from './assets/images/mycart.png';
import { Base64 } from 'js-base64';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    ListGroup,
    ListGroupItem,
    Row,
    Col
} from "reactstrap";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            cart: [],
            posts: [],
            arrWithinIndia: [],
            arrForeignSubscription: [],
            arrLifeTime: [],
            flag: 0,
            value: 1,
            eventinfoarray: [],
            guestinfoarray: [],
            cartEvent: [],
            cartMachineTestEvent: [],
            guestinfo: {
                title: '',
                sub_title: '',
                magazine_id: '',
                issue_id: '',
                description: '',
                short_description: '',
                english_title: '',
                language: '',
                total_likes: '',
                total_review: '',

            },
            eventinfo: {
                first_name_shipping: '',
                middle_name: '',
                last_name: '',
                address_line_1: '',
                address_line_2: '',
                landmark: '',
                country: '',
                state: '',
                city: '',
                pincode: '',
                phone: '',
                email: '',
                country_name: '',
                state_name: '',
                cart_total_price: '',


            },

            removeinfo: {
                message: '',
                status: '',
                encrypted_data: '',
                access_code: '',

            },
            cartTotal: {
                message: '',
                status: '',
                cart_total_price: '',
            },
            editorState: EditorState.createEmpty(),
        }

        this.handleChange = this.handleChange.bind(this);
        //this.removeFromCart = this.removeFromCart.bind(this);
        this.proceedToPayment = this.proceedToPayment.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.lifeTimeAddToCart = this.lifeTimeAddToCart.bind(this);
        this.submitLike = this.submitLike.bind(this);
        this.changeArrowDirection = this.changeArrowDirection.bind(this);
        // this.checkout = this.checkout.bind(this);
    }


    submitLike() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {

            fetch(myConstClass.BASE_URL + 'review/saveMagazinetLikeFrontEnd/' + user_id + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    if (status == 'true') {
                        var magazineLikeCount = $('#magazineLikeCount').html();
                        var magazineLikeCountNew = parseInt(magazineLikeCount) + 1;
                        $('#magazineLikeCount').html(magazineLikeCountNew);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        //setTimeout(this.props.history.push('/products'),1000);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        //toast("Wow so easy !");
                    }
                })
                .catch(err => console.log(err))
            // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for like magazine.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }


    lifeTimeAddToCart(e) {
        var arr_package_id = e.target.id;

        const arr_package_ids = arr_package_id.split('_');
        var package_id = arr_package_ids[1];
        var user_id = localStorage.getItem('user_id');
        if (package_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addMagazineToCart/' + package_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    
                    if (status == 'true') {
                        $('.' + package_id).removeClass('btn-brown');
                        $('.' + package_id).addClass('btn-light');

                        // $('#'+package_id).html('ADDED TO CART');
                        if (getLanguage() == 'en') {
                            $('.' + package_id).html('ADDED TO CART');
                        } else {
                            $('.' + package_id).html('कार�?टमध�?ये जोडले');
                        }

                        $('.lifeTime_' + package_id).removeClass('btn-brown');
                        $('.lifeTime_' + package_id).addClass('btn-light');
                        if (getLanguage() == 'en') {
                            $('.lifeTime_' + package_id).html('ADDED TO CART');
                        } else {
                            $('.lifeTime_' + package_id).html('कार�?टमध�?ये जोडले');

                        }


                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                })
                .catch(err => console.log(err))
        } else {
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
            }
        }
    }


    addToCart(e) {
        var package_id = e.target.id.split('_')[0];
        console.log("Add to Cart : ",e.target.id.split('_')[0]);
        var user_id = localStorage.getItem('user_id');
        
        if (package_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addMagazineToCart/' + package_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    
                    if (status == 'true') {



                        $('.' + package_id).removeClass('btn-brown');
                        $('.' + package_id).addClass('btn-light');
                        //$('.' + e.target.id).removeClass('btn-brown');
                        //$('.' + e.target.id).addClass('btn-light');
                        if (getLanguage() == 'en') {
                            $('.' + package_id).html('ADDED TO CART');
                            //$('.' + e.target.id).html('ADDED TO CART');
                        } else {
                            $('.' + package_id).html('कार्ट मध्ये जोडले');
                           // $('.' + e.target.id).html('कार्ट मध्ये जोडले');
                        }

                        $('.lifeTime_' + package_id).removeClass('btn-brown');
                        $('.lifeTime_' + package_id).addClass('btn-light');
                        $('.lifeTime_' + package_id).removeClass('btn-brown');
                        $('.lifeTime_' + package_id).addClass('btn-light');
                        if (getLanguage() == 'en') {
                            $('.lifeTime_' + package_id).html('ADDED TO CART');
                            $('.lifeTime_' + package_id).html('ADDED TO CART');
                        } else {
                            $('.lifeTime_' + package_id).html('कार�?टमध�?ये जोडले');
                            $('.lifeTime_' + package_id).html('कार�?टमध�?ये जोडले');
                        }


                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                           
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }



                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
            }

        }
    }

    proceedToPayment(e) {

        if ($("#customControlInline").prop('checked') == true) {
            var user_id = localStorage.getItem('user_id');
            var total_price = this.state.cartTotal.cart_total_price;
            //var total_price = 1;
            if (total_price != '' && user_id != '' && user_id > 0 && total_price > 0) {
                var exampleFormControlTextarea1 = $('#exampleFormControlTextarea1').val();

                var is_same_address = localStorage.getItem('is_same_address');
                // alert(myConstClass.BASE_URL+`payment/goToPayment/`+user_id+'/'+is_same_address);
                //alert(is_same_address)
                // if(is_same_address > 0){

                // }else{
                //     is_same_address = 0;
                // }

                console.log(exampleFormControlTextarea1);
                // fetch(myConstClass.BASE_URL+'payment/goToPayment/'+user_id+'/'+exampleFormControlTextarea1)
                fetch(myConstClass.BASE_URL + `payment/goToPayment/` + user_id + '/' + is_same_address, {
                    method: 'POST',
                    body: JSON.stringify($('#exampleFormControlTextarea1').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ removeinfo: response })
                        var message = this.state.removeinfo.message;
                        var status = this.state.removeinfo.status;
                        var order_id = this.state.removeinfo.order_id;
                        if (status == 'true') {
                            // $('#TR'+product_id).css('display','none');
                            //toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
                            var message = "Your order completed successfully.";
                            localStorage.removeItem('success');
                            // localStorage.setItem('success',message);
                            //var encrypted_data = this.state.removeinfo.encrypted_data;
                            this.state.removeinfo.encrypted_data = this.state.removeinfo.encrypted_data;

                            // var access_code = this.state.removeinfo.access_code;
                            this.state.removeinfo.access_code = this.state.removeinfo.access_code;

                            $("#redirect").submit();

                            //this.props.history.push('/order-details/'+order_id)
                        } else {
                            localStorage.removeItem('error');
                            localStorage.setItem('error', message);
                            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                            //this.props.history.push('/order-details/'+order_id);
                        }
                        //console.log(status);
                    })
                    .catch(err => console.log(err))


            } else {
                //toastr.warning('Product quantity must be greater than 0')
                if (user_id > 0) {
                    if (total_price == '' || total_price == undefined) {
                        toast('You must have to add products in your cart.', { containerId: 'B', type: toast.TYPE.ERROR });
                    }

                } else {
                    var message = "Please login to our website.";
                    localStorage.removeItem('error');
                    localStorage.setItem('error', message);
                    this.props.history.push('/signin')
                    //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

                }

            }

        } else {
            var message = "Please confirm you are not a robot";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        }
    }

    componentDidMount() {
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        //this.state.guestinfo.product_total_price = 0;
        const answer_array = this.props.location.pathname.split('/');

        var user_id = localStorage.getItem('user_id');
        fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/' + answer_array['2'])
            .then(response => response.json())
            .then(response => this.setState({ guestinfo: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
            .then(response => response.json())
            .then(response => this.setState({ posts: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ arrWithinIndia: response })
                var total_rows = this.state.arrWithinIndia.length;
                if (total_rows < 1) {
                    $('#withinIndiaTR').css('display', 'none');
                    $('#withinIndiaNotAvailableTR').css('display', 'contents');
                    $('#withinIndiaNotAvailableTR_m').css('display', 'contents');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/2')
            .then(response => response.json())
            .then(response => {
                this.setState({ arrForeignSubscription: response })
                var total_rows = this.state.arrForeignSubscription.length;
                if (total_rows < 1) {
                    $('#foreignSubscriptionTR').css('display', 'none');
                    $('#foreignSubscriptionNotAvailableTR').css('display', 'contents');
                    $('#foreignSubscriptionNotAvailableTR_m').css('display', 'contents');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ arrLifeTime: response })
                var total_rows = this.state.arrLifeTime.length;
                if (total_rows < 1) {
                    $('#lifeTimeTR').css('display', 'none');
                    $('#lifeTimeNotAvailableTR').css('display', 'contents');
                    $('#lifeTimeNotAvailableTR_m').css('display', 'contents');
                }
            })
            .catch(err => console.log(err))



    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
                .then(response => response.json())
                .then(response => this.setState({ posts: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrWithinIndia: response })
                    var total_rows = this.state.arrWithinIndia.length;
                    if (total_rows < 1) {
                        $('#withinIndiaTR').css('display', 'none');
                        $('#withinIndiaNotAvailableTR').css('display', 'contents');
                        $('#withinIndiaNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrForeignSubscription: response })
                    var total_rows = this.state.arrForeignSubscription.length;
                    if (total_rows < 1) {
                        $('#foreignSubscriptionTR').css('display', 'none');
                        $('#foreignSubscriptionNotAvailableTR').css('display', 'contents');
                        $('#foreignSubscriptionNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrLifeTime: response })
                    var total_rows = this.state.arrLifeTime.length;
                    if (total_rows < 1) {
                        $('#lifeTimeTR').css('display', 'none');
                        $('#lifeTimeNotAvailableTR').css('display', 'contents');
                        $('#lifeTimeNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
                .then(response => response.json())
                .then(response => this.setState({ posts: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrWithinIndia: response })
                    var total_rows = this.state.arrWithinIndia.length;
                    if (total_rows < 1) {
                        $('#withinIndiaTR').css('display', 'none');
                        $('#withinIndiaNotAvailableTR').css('display', 'contents');
                        $('#withinIndiaNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrForeignSubscription: response })
                    var total_rows = this.state.arrForeignSubscription.length;
                    if (total_rows < 1) {
                        $('#foreignSubscriptionTR').css('display', 'none');
                        $('#foreignSubscriptionNotAvailableTR').css('display', 'contents');
                        $('#foreignSubscriptionNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazinePackagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/' + answer_array['2'] + '/0/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrLifeTime: response })
                    var total_rows = this.state.arrLifeTime.length;
                    if (total_rows < 1) {
                        $('#lifeTimeTR').css('display', 'none');
                        $('#lifeTimeNotAvailableTR').css('display', 'contents');
                        $('#lifeTimeNotAvailableTR_m').css('display', 'contents');
                    }
                })
                .catch(err => console.log(err))

        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    
    changeArrowDirection(event) {
        if($("#"+event.target.id).hasClass('rotate180')) {
            $("#"+event.target.id).addClass('rotate0');
            $("#"+event.target.id).removeClass('rotate180');
        } else {
            $(".image_main_carosal").addClass('rotate0');
            $(".image_main_carosal").removeClass('rotate180');

            $("#"+event.target.id).addClass('rotate180');
            $("#"+event.target.id).removeClass('rotate0');
            
        }     
    }



    render() {

        const { arrLifeTime, arrForeignSubscription, arrWithinIndia, removeinfo, cartMachineTestEvent, cartEvent, posts, guestinfo, cart, eventinfo, eventinfoarray, guestinfoarray } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0 ml-n2">
                                        <li class="breadcrumb-item"><Link to="/">{t('home.home')}</Link></li>
                                        <li class="breadcrumb-item"><Link to="/products">{t('home.Products')}</Link></li>
                                        <li class="breadcrumb-item" aria-current="page">
                                            {/* {t('home.Magazines')} */}
                                            <Link to={'/magazine/' + guestinfo.magazine_id}>{t('home.Magazines')}</Link>
                                        </li>

                                        <li class="breadcrumb-item active" aria-current="page">
                                            {t('home.Monthly Subscription')}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row ">
                                <div class="col-lg-9 col-md-9 col-sm-12 ">

                                    <button type="button" class="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{guestinfo.title}</button>

                                    <h4><b>
                                        {t('home.MANASHAKTI MONTHLY SUBSCRIPTION')}

                                    </b> <span class="sub_text"><i>

                                        {/* {guestinfo.sub_title} */}

                                    </i></span>
                                        <Link style={{ marginLeft: '20px', marginTop: '-5px' }} class="btn btn-sm btn-outline-brown" to={'/magazine_details/' + guestinfo.magazine_id}>{t('home.Go Back to Main Page')}</Link>
                                    </h4>


                                    {t('home.Language')}:&nbsp;<strong>{guestinfo.language}</strong>

                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12 " style={{ marginTop: '10px' }}>
                                    <span class="mr-2 d-inline-block pr-2">
                                        <i class="fas fa-comment-dots orangeColor"></i> <span class="small">
                                            {guestinfo.total_review}
                                        </span>
                                    </span>
                                    <span class="d-inline-block pr-2">
                                        <i class="fas fa-thumbs-up orangeColor"></i> <span class="small" id="magazineLikeCount">
                                            {guestinfo.total_like}
                                        </span>
                                    </span>
                                    <span class="d-inline-block pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                                        <i class="fas fa-thumbs-up color-brown"></i> <br /> <span class="small d-block mt-n1">
                                            {t('home.LIKE THIS')}

                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row" >
                                <div class="col-lg-9">
                                    <div id="accordion">
                                        <div class="card my-3">
                                            <div class="card-header bg-dark-grey" >
                                                <h5 class="mb-0">
                                                    {t('home.WITHIN INDIA')}

                                                </h5>
                                                {/* <span  style={{fontSize: '11px',background: 'white', padding: '0px 6px', borderRadius: '54%', marginTop: '-17px', marginLeft: '94%', position: 'absolute'}}>
                                    <div class="arrow-down" style={{width:'0px',height:'0px',borderLeft:'6px solid transparent',borderRight:'6px solid transparent',borderTop:'8px solid #9d0b0e',marginTop:'6px',marginBottom:'3px'}}></div>
                                    </span> */}
                                                <img 
                                                    style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} 
                                                    className="image_main_carosal rotate180" 
                                                    src={checkoutImage} 
                                                    onClick={this.changeArrowDirection}
                                                    id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                                    />
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body p-0 d-xl-flex d-lg-flex d-md-flex d-none">
                                                    <table class="table table-bordered productMagazine">
                                                        <tbody>
                                                            <tr class="bg-light" id="withinIndiaTR">
                                                                <th colspan="2">{t('home.SUBSCRIPTION PACKAGE')}</th>
                                                                <th colspan="2">{t('home.PACKAGE DETAILS')}</th>
                                                                <th colspan="2">{t('home.DONATION CONTRIBUTION')}</th>
                                                                <th colspan="2"></th>
                                                            </tr>

                                                            {arrWithinIndia.map((info, ii) => {
                                                                return (
                                                                    <tr class={'bg-extra-light TR' + info.package_id}>
                                                                        <th colspan="2">{info.package_title}</th>
                                                                        <td colspan="2">
                                                                            {ReactHtmlParser(info.description)}

                                                                        </td>
                                                                        <th colspan="2">{t('home.INR')}{info.donation_contribution}/-</th>

                                                                        <th colspan="4">
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-light btn-sm '+info.package_id} name={info.package_id + '_m'} id={info.package_id + '_m'} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-brown btn-sm '+info.package_id}  name={info.package_id + '_m'} id={info.package_id + '_m'} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }

                                                                        </th>
                                                                    </tr>

                                                                )

                                                            })
                                                            }

                                                            <tr style={{ display: 'none' }} id="withinIndiaNotAvailableTR">
                                                                <td colspan="8">{t('home.Not added yet')}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-body p-0 d-xl-none d-lg-none d-md-none d-flex">
                                                    <ListGroup>
                                                        {arrWithinIndia.map((info, ii) => {
                                                            return (
                                                                <ListGroupItem>
                                                                    <Row>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.SUBSCRIPTION PACKAGE')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {info.package_title}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.PACKAGE DETAILS')}&nbsp;:&nbsp;
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            {ReactHtmlParser(info.description)}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.DONATION CONTRIBUTION')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {t('home.INR')}{info.donation_contribution}/-
                                                                        </Col>
                                                                        <Col sm="12" xs="12" className="text-center my-3">
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-light btn-sm '+info.package_id} name={info.package_id + '_m'} id={info.package_id + '_m'} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-brown btn-sm '+info.package_id} name={info.package_id + '_m'} id={info.package_id + '_m'} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        })
                                                        }
                                                    </ListGroup>

                                                    <div style={{ display: 'none' }} id="withinIndiaNotAvailableTR_m">
                                                        {t('home.Not added yet')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card my-3">
                                            <div class="card-header bg-dark-grey collapsed">
                                                <h5 class="mb-0">
                                                    {t('home.FOREIGN SUBSCRIPTION')}
                                                </h5>
                                                <img style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} className="image_main_carosal rotate180" src={checkoutImage} 
                                                onClick={this.changeArrowDirection}
                                                id="headingTwo" data-toggle="collapse" aria-expanded="true" data-target="#collapseTwo" aria-controls="collapseTwo"/>

                                            </div>
                                            <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body p-0 d-xl-flex d-lg-flex d-md-flex d-none">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr class="bg-light" id="foreignSubscriptionTR">
                                                                <th colspan="2">{t('home.SUBSCRIPTION PACKAGE')}</th>
                                                                <th colspan="2">{t('home.PACKAGE DETAILS')}</th>
                                                                <th colspan="2">{t('home.DONATION CONTRIBUTION')}</th>
                                                                <th colspan="2"></th>
                                                            </tr>

                                                            {arrForeignSubscription.map((info, ii) => {
                                                                return (
                                                                    <tr class={'bg-extra-light TR' + info.package_id}>
                                                                        <th colspan="2">{info.package_title}</th>
                                                                        <td colspan="2">
                                                                            {ReactHtmlParser(info.description)}
                                                                        </td>
                                                                        <th colspan="2">{t('home.INR')}{info.donation_contribution}/-</th>

                                                                        <th colspan="4">
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-light btn-sm '+info.package_id} name={info.package_id} id={info.package_id} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-brown btn-sm '+info.package_id}  name={info.package_id} id={info.package_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </th>
                                                                    </tr>

                                                                )

                                                            })
                                                            }

                                                            <tr style={{ display: 'none' }} id="foreignSubscriptionNotAvailableTR">
                                                                <td colspan="8">{t('home.Not added yet')}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-body p-0 d-xl-none d-lg-none d-md-none d-flex">
                                                    <ListGroup>
                                                        {arrForeignSubscription.map((info, ii) => {
                                                            return (
                                                                <ListGroupItem>
                                                                    <Row>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.SUBSCRIPTION PACKAGE')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {info.package_title}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.PACKAGE DETAILS')}&nbsp;:&nbsp;
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            {ReactHtmlParser(info.description)}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.DONATION CONTRIBUTION')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {t('home.INR')}{info.donation_contribution}/-
                                                                        </Col>
                                                                        <Col sm="12" xs="12" className="text-center my-3">
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-sm btn-light '+info.package_id}  name={info.package_id} id={info.package_id + '_m'} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-sm btn-light '+info.package_id} name={info.package_id} id={info.package_id + '_m'} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        })
                                                        }
                                                    </ListGroup>

                                                    <div style={{ display: 'none' }} id="foreignSubscriptionNotAvailableTR_m">
                                                        {t('home.Not added yet')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card my-3">
                                            <div class="card-header bg-dark-grey collapsed">
                                                <h5 class="mb-0">
                                                    {t('home.SUBSCRIPTION FOR LIFE MEMBERSHIP (14 YEARS)')}
                                                </h5>

                                                <img 
                                                style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} className="image_main_carosal rotate180" src={checkoutImage} 
                                                onClick={this.changeArrowDirection}
                                                id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"/>

                                            </div>
                                            <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body p-0 d-xl-flex d-lf-flex d-md-flex d-none">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr class="bg-light" id="lifeTimeTR">
                                                                <th colspan="2">{t('home.SUBSCRIPTION PACKAGE')}</th>
                                                                <th colspan="2">{t('home.PACKAGE DETAILS')}</th>
                                                                <th colspan="2">{t('home.DONATION CONTRIBUTION')}</th>
                                                                <th colspan="2"></th>
                                                            </tr>

                                                            {arrLifeTime.map((info, ii) => {
                                                                return (
                                                                    <tr class={'bg-extra-light TRLIFETIME_' + info.package_id}>
                                                                        <th colspan="2">{info.package_title}</th>
                                                                        <td colspan="2">
                                                                            {/* {Base64.decode(info.description)} */}
                                                                            {ReactHtmlParser(info.description)}
                                                                        </td>
                                                                        <th colspan="2">{t('home.INR')}{info.donation_contribution}/-</th>

                                                                        <th colspan="4">
                                                                            {/* <button style={{width:'122px'}} className="btn btn-brown btn-sm" name={'lifeTime_'+info.package_id} id={'lifeTime_'+info.package_id}  onClick={this.lifeTimeAddToCart}>{t('home.ADD TO CART')}</button> */}
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-sm btn-light lifeTime_' + info.package_id}  name={'lifeTime_' + info.package_id} id={'lifeTime_' + info.package_id} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={'btn btn-sm btn-brown lifeTime_' + info.package_id}  name={'lifeTime_' + info.package_id} id={'lifeTime_' + info.package_id} onClick={this.lifeTimeAddToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </th>
                                                                    </tr>

                                                                )

                                                            })
                                                            }
                                                            <tr style={{ display: 'none' }} id="lifeTimeNotAvailableTR" class="lifeTimeNotAvailableTR">
                                                                <td colspan="8">{t('home.Not added yet')}</td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-body p-0 d-xl-none d-lg-none d-md-none d-flex">
                                                    <ListGroup>
                                                        {arrLifeTime.map((info, ii) => {
                                                            return (
                                                                <ListGroupItem>
                                                                    <Row className="border-bottom">
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.SUBSCRIPTION PACKAGE')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {info.package_title}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.PACKAGE DETAILS')}&nbsp;:&nbsp;
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            {ReactHtmlParser(info.description)}
                                                                        </Col>
                                                                        <Col sm="12" xs="12">
                                                                            <span className="font-weight-bold">
                                                                                {t('home.DONATION CONTRIBUTION')}&nbsp;:&nbsp;
                                                                            </span>
                                                                            {t('home.INR')}{info.donation_contribution}/-
                                                                        </Col>
                                                                        <Col sm="12" xs="12" className="text-center my-3">
                                                                            {info.added_in_cart.map((carts, j) => {
                                                                                if (carts.cart_id > 0) {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={' btn btn-sm btn-light lifeTime_' + info.package_id}  name={'lifeTime_' + info.package_id} id={'lifeTimeM_' + info.package_id} >{t('home.ADDED TO CART MAGAZINE')}</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button style={{ width: '122px' }} className={' btn btn-sm btn-brown lifeTime_' + info.package_id} name={'lifeTimeM_' + info.package_id} id={'lifeTime_' + info.package_id} onClick={this.lifeTimeAddToCart}>{t('home.ADD TO CART')}</button>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        })
                                                        }
                                                    </ListGroup>

                                                    <div style={{ display: 'none' }} id="lifeTimeNotAvailableTR_m">
                                                        {t('home.Not added yet')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                {/* {posts.map((cell, i) => { */}
                                {/* return(                     */}
                                <div class="col-lg-3">
                                    <div class=" mt-3 border border-dark ">
                                        <div class="pl-3 pr-1 pb-3 ">
                                            <p class="mt-3">
                                                <h5>{t('home.E-SUBSCRIPTION')}</h5>
                                            </p>
                                            <p>
                                                {t('home.Magazines_desc_first')}
                                            </p>
                                            <p>
                                                <a style={{ textDecoration: 'none' }} target="_blank" href={'https://www.magzter.com/IN/Manashakti/Manashakti/Culture/'}>
                                                    <button class="btn btn-brown btn-sm mb-2">{t('home.E-SUBSCRIBE NOW')}</button>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* )

                })}                */}


                            </div>

                        </div>
                    </div>

                    <form method="post" id="redirect" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                        <input type='hidden' name='encRequest' value={removeinfo.encrypted_data} />
                        <input type='hidden' name='access_code' value={removeinfo.access_code} />
                        <input type="submit" name="btn_submit" id="btn_submit" style={{ display: 'none' }} />
                    </form>

                </div>
                <Footer />
            </div>
        );
    }
}
Subscription.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Subscription);
