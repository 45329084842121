import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import 'react-image-lightbox/style.css';

// Translation Higher Order Component
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,

} from 'reactstrap';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class News_details extends Component {
    constructor(props) {
        super(props);
        this.event_id = 0;
        this.state = {
            posts: [],
            types: [],
            photoIndex: 0,
            startIndex: 0,
            lightboxOpen: false,
            images: [],
            isOpen: false,
            scheduleEvent: [],
            eventtopic: [],
            flag: 0,
            review: [],
            feedback: [],
            arrVideo: [],
            cartEvent: [],
            arrMagazine: [],
            machineCartEvent: [],
            guestinfo: {
                type_id: '',
                no_of_pages: '',
                total_like: '',
                total_review: '',
                title: '',
                author: '',
                quantity: '',
                price: '',
                description: '',
                images_array: [],
                arr_event_languages: [],
                current_event_type_id: '',
            },
            editorState: EditorState.createEmpty(),
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.submitLike = this.submitLike.bind(this);

        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);

        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);
        this.showallReview = this.showallReview.bind(this);

        this.showMoreFeedback = this.showMoreFeedback.bind(this);
        this.showLessFeedback = this.showLessFeedback.bind(this);
        this.openGallery = this.openGallery.bind(this);

        this.openGalleryVideo = this.openGalleryVideo.bind(this);
        this.closeGallery = this.closeGallery.bind(this);
    }

    showallReview() {
        $('#ShowAllReviewDiv').css('display', 'none');
        const answer_array = this.props.location.pathname.split('/');
        fetch(myConstClass.BASE_URL + 'review/getNewsReviewFrontEnd/' + answer_array['2'] + '/200')
            .then(response => response.json())
            .then(response => this.setState({ review: response }))
            .catch(err => console.log(err))

    }
    openGalleryVideo(e) {
        $('.jss1').attr("style", "display: none !important");
        $("#openGallery").css({'display':'flex'});
        this.setState({ lightboxOpen: true });
        // var id = e.target.id;
        $('html,body').animate({
            scrollTop: 0
        }, 700);
        $('.slide-ico').css('display', 'none');
        this.state.photoIndex = e.target.id;
        $('#videoCloseIcon').css('display', 'inline');
        $('body').css('overflow', 'hidden');
        // $("#openGallery > div").css("z-index","99999"); 
        // $("#openGallery > div").css("z-index","99999 !important");
        // $( "#openGallery div" ).first().css("z-index","99999 !important"); 
        $('.fixed-top').css('z-index', '-9999999');
        $('.jss1').css('display', 'none !important');
    }
    closeGallery() {
        this.setState({ lightboxOpen: false });
        $('#videoCloseIcon').css('display', 'none');
        $('.slide-ico').css('display', 'block');
        $('body').css('overflow', 'scroll');
        // $('.fixed-top').css('z-index','9999999');
        $('.fixed-top').css('z-index', '9999999');
        $('#openGallery').css('display', 'none');
    }

    openGallery(e) {
        $('.jss1').attr("style", "display: none !important");
//        $("#openGallery").css({'display':'flex'});
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }


    showMore(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'none');
        $('#showLessDiv_' + review_id).css('display', 'block');
    }

    showLess(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'block');
        $('#showLessDiv_' + review_id).css('display', 'none');
    }

    showMoreFeedback(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreFeedbackDiv_' + review_id).css('display', 'none');
        $('#showLessFeedbackDiv_' + review_id).css('display', 'block');
    }

    showLessFeedback(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreFeedbackDiv_' + review_id).css('display', 'block');
        $('#showLessFeedbackDiv_' + review_id).css('display', 'none');
    }

    readMore(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'none');
        $('#ReadLessDiv').css('display', 'block');
    }

    readLess(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'block');
        $('#ReadLessDiv').css('display', 'none');
    }



    submitLike() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {

            fetch(myConstClass.BASE_URL + 'review/saveNewLikeFrontEnd/' + user_id + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    if (status == 'true') {
                        var productLikeCount = $('#productLikeCount').html();
                        var productLikeCountNew = parseInt(productLikeCount) + 1;
                        $('#productLikeCount').html(productLikeCountNew);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        //setTimeout(this.props.history.push('/products'),1000);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        //toast("Wow so easy !");
                    }
                })
                .catch(err => console.log(err))
            // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for like product.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }

    submitReview(e) {
        e.preventDefault();
        console.log('this.refs', $('#pform').serializeArray());
        const formData = {};
        for (const field in this.refs) {
            formData[field] = this.refs[field].value;
        }
        const answer_array = this.props.location.pathname.split('/');
        var user_review_current = $('#user_review').val();
        var user_review = $.trim(user_review_current);
        //alert(user_review);
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {
            if (user_review != '') {
                var message = 'Your review added successfully,once admin approval will display it.';

                // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
                fetch(myConstClass.BASE_URL + `review/saveNewsReviewFrontEnd/` + user_id + '/' + answer_array['2'], {
                    method: 'POST',
                    body: JSON.stringify($('#pform').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventinfo: response })
                        var status = this.state.eventinfo.status;
                        var message = this.state.eventinfo.message;
                        if (status == 'true') {
                            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                            //setTimeout(this.props.history.push('/products'),1000);
                        } else {
                            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                            //toast("Wow so easy !");
                        }
                    })
                    .catch(err => console.log(err))
                // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

                $('#user_review').val('');
            } else {
                var message = "Please enter review.";
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for submitting review.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }




    handleChange(event) {
        this.setState({ value: event.target.value });
    }


    componentDidMount() {
        //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
        //   .then(response => response.json())
        //   .then(response =>this.setState({types: response}))
        //   .catch(err => console.log(err))
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);

        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        // alert(answer_array);
        if (answer_array['2']) {

            fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    var current_event_type_id = this.state.guestinfo.event_type_id;
                    this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                    //alert(current_event_type_id);
                    var second_price = this.state.guestinfo.second_price;
                    var event_id = this.state.guestinfo.event_id;

                    var desc = (this.state.guestinfo.description);
                    this.state.guestinfo.description = desc;

                    var short_description = (this.state.guestinfo.short_description);
                    this.state.guestinfo.short_description = short_description;

                    //   if(this.state.guestinfo.short_description == ''){
                    //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';
                    var total_images = this.state.guestinfo.images_array.length;
                    if (total_images < 2) {
                        $('#imagePrev').css('display', 'none');
                        $('#imageNext').css('display', 'none');
                    }
                    //alert(desc);
                    var descripotion_length = desc.length;
                    if (descripotion_length < 440 || descripotion_length == undefined) {
                        $('#readMoreButton').css('display', 'none');
                    }
                })
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                }).then(console.log('hi' + this.state.guestinfo.title))
                .catch(err => console.log(err))



        }

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'review/getNewsReviewFrontEnd/' + answer_array['2'] + '/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })
                var total_rows = this.state.review.length;
                if (total_rows > 0) {

                } else {
                    $('#ShowAllReviewDiv').css('display', 'none');
                }
            })
            .catch(err => console.log(err))




        fetch(myConstClass.BASE_URL + 'news/getNewsVideoFrontEnd/' + answer_array['2'])
            .then(response => response.json())
            .then(response => {
                this.setState({ arrVideo: response })
                var numRow = this.state.arrVideo.length;
                if (numRow > 0) {
                    $('#carouselExampleControls1').css('display', 'inline');
                } else {
                    $('#carouselExampleControls1').css('display', 'none');
                }
                if (numRow < 2) {
                    $('#videoPrev').css('display', 'none');
                    $('#videoNext').css('display', 'none');
                } else {
                    // $('#carouselExampleControls1').css('display','none');
                }
            })
            .catch(err => console.log(err))

        // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;

        fetch(myConstClass.BASE_URL + 'news/getImages' + '/' + answer_array['2'])
            .then(response => response.json())
            .then(response => this.setState({ images: response }))
            .catch(err => console.log(err))

    }

    componentDidUpdate(prevProps) {

        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }

        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            // alert(answer_array);
            if (answer_array['2']) {

                fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ guestinfo: response })
                        var current_event_type_id = this.state.guestinfo.event_type_id;
                        this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                        //alert(current_event_type_id);
                        var second_price = this.state.guestinfo.second_price;
                        var event_id = this.state.guestinfo.event_id;

                        var desc = (this.state.guestinfo.description);
                        this.state.guestinfo.description = desc;

                        var short_description = (this.state.guestinfo.short_description);
                        this.state.guestinfo.short_description = short_description;

                        //   if(this.state.guestinfo.short_description == ''){
                        //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';
                        var total_images = this.state.guestinfo.images_array.length;
                        if (total_images < 2) {
                            $('#imagePrev').css('display', 'none');
                            $('#imageNext').css('display', 'none');
                        }
                        //alert(desc);
                        var descripotion_length = desc.length;
                        if (descripotion_length < 440 || descripotion_length == undefined) {
                            $('#readMoreButton').css('display', 'none');
                        }
                    })
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    }).then(console.log('hi' + this.state.guestinfo.title))
                    .catch(err => console.log(err))



            }

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'review/getNewsReviewFrontEnd/' + answer_array['2'] + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ review: response })
                    var total_rows = this.state.review.length;
                    if (total_rows > 0) {

                    } else {
                        $('#ShowAllReviewDiv').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {


            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');



            // alert(answer_array);
            if (answer_array['2']) {

                fetch(myConstClass.BASE_URL + 'news/getNewsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ guestinfo: response })
                        var current_event_type_id = this.state.guestinfo.event_type_id;
                        this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                        //alert(current_event_type_id);
                        var second_price = this.state.guestinfo.second_price;
                        var event_id = this.state.guestinfo.event_id;

                        var desc = (this.state.guestinfo.description);
                        this.state.guestinfo.description = desc;

                        var short_description = (this.state.guestinfo.short_description);
                        this.state.guestinfo.short_description = short_description;

                        //   if(this.state.guestinfo.short_description == ''){
                        //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';
                        var total_images = this.state.guestinfo.images_array.length;
                        if (total_images < 2) {
                            $('#imagePrev').css('display', 'none');
                            $('#imageNext').css('display', 'none');
                        }
                        //alert(desc);
                        var descripotion_length = desc.length;
                        if (descripotion_length < 440 || descripotion_length == undefined) {
                            $('#readMoreButton').css('display', 'none');
                        }
                    })
                    .then(response => {
                        this.setState({
                            editorState: EditorState.createWithContent(
                                convertFromRaw(JSON.parse(this.state.guestinfo.description))
                            )
                        })
                    }).then(console.log('hi' + this.state.guestinfo.title))
                    .catch(err => console.log(err))



            }

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'review/getNewsReviewFrontEnd/' + answer_array['2'] + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ review: response })
                    var total_rows = this.state.review.length;
                    if (total_rows > 0) {

                    } else {
                        $('#ShowAllReviewDiv').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))

        }


    }

    render() {

        const { photoIndex, isOpen, lightboxOpen, arrMagazine, cartEvent, machineCartEvent, arrVideo, feedback, review, scheduleEvent, posts, guestinfo, eventtopic } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <i style={{ cursor: 'pointer', display: 'none' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon2"></i>
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0 ml-n2">
                                        <li class="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item">

                                            <Link to="/news/1">{t('home.News')}</Link>

                                            {/* <a href="#">{t('home.events')}
                                </a> */}
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            {guestinfo.title}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col">

                                    <h4>{guestinfo.title}</h4>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col mb-3">
                                    {guestinfo.format_date} , <span>{t('home.Lonavla')}</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col mb-3">
                                    <span class="mr-2 d-inline-block pr-2">
                                        <i class="far fa-comment-dots orangeColor"></i> <span class="small">{guestinfo.total_review}</span>
                                    </span>
                                    <span class="d-inline-block pr-2">

                                        <i class="fas fa-thumbs-up orangeColor"></i> <span class="small" id="productLikeCount">{guestinfo.total_like}</span>
                                    </span>
                                    <span class="d-inline-block pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                                        <i class="fas fa-thumbs-up color-brown"></i> <br /> <span class="small d-block mt-n1" >{t('home.LIKE THIS')}</span>
                                    </span>
                                </div>
                            </div>


                        </div>

                        <div class="col-lg-9 mt-3">

                            <div class="row mt-3">
                                <div class="col-12 col-xl-5 col-lg-5 col-md-5">
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">

                                            {guestinfo.images_array.map((info, ii) => {
                                                if (ii == 0) {
                                                    
                                                    return (<div class="carousel-item active"> <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGallery} class="d-block w-100 newsFitScreenImageNew mb-2" src={info.image_name} /></div>
                                                    )
                                                } else {
                                                    
                                                    return (<div class="carousel-item "> <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGallery} class="d-block w-100  newsFitScreenImageNew mb-2" src={info.image_name} /></div>
                                                    )
                                                }
                                            }

                                            )}
                                        </div>
                                        <a id="imagePrev" class="carousel-control-prev" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="prev">
                                            <i class="fas fa-chevron-left slide-ico"></i>
                                            <span class="sr-only">{t('home.Previous')}</span>
                                        </a>
                                        <a id="imageNext" class="carousel-control-next" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="next">
                                            <i class="fas fa-chevron-right slide-ico"></i>
                                            <span class="sr-only">{t('home.Next')}</span>
                                        </a>
                                    </div>

                                    <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">

                                            {arrVideo.map((info, ii) => {
                                                if (ii == 0) {

                                                    return (

                                                        <div class="carousel-item active wrapper">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div >
                                                                    <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGalleryVideo} src={info.you_tube_url} class="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                    <div class="overlay">
                                                                        <a href="#" class="icon" >
                                                                            <i class="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    // return( <div class="carousel-item "> <img class="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                                                    return (
                                                        //  <div class="carousel-item wrapper"> 
                                                        // <ReactPlayer
                                                        // className='d-block w-100 img-fluid cmsVideoNew'
                                                        // width='100%'
                                                        // height='100%'
                                                        // type="video"  url={info.src}  /> 
                                                        //  </div>  
                                                        <div class="carousel-item wrapper">
                                                            {/* <ReactPlayer
                                        className='d-block  w-100  img-fluid '
                                        width='100%'
                                        height='100%'
                                        type="video"  id={ii}   src={info.you_tube_url}  />  */}
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div class="div1">
                                                                    <img style={{ cursor: 'pointer' }} onClick={this.openGalleryVideo} src={info.you_tube_url} class="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                    <div class="overlay">
                                                                        <a href="#" class="icon" >
                                                                            <i class="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }

                                            )}
                                            <a id="videoPrev" class="carousel-control-prev" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="prev">
                                                <i class="fas fa-chevron-left slide-ico"></i>
                                                <span class="sr-only">{t('home.Previous')}</span>
                                            </a>
                                            <a id="videoNext" class="carousel-control-next" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="next">
                                                <i class="fas fa-chevron-right slide-ico"></i>
                                                <span class="sr-only">{t('home.Next')}</span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 col-xl-7 col-lg-7 col-md-5">
                                    <p>

                                        <span id="ReadMoreDiv">

                                            {ReactHtmlParser(guestinfo.description)}
                                            <br />

                                        </span>

                                       
                                    </p>
                                    <p><a href="/news/1">{t('home.Read All News')}...</a></p>
                                    <br />

                                    <div class="mt-3">
                                        <hr />
                                        <h5>{t('home.COMMENT News')}</h5>
                                        {review.map((info, ii) => {
                                            if (info.description.length < 300) {
                                                return (
                                                    <div>
                                                        <p>
                                                            {ReactHtmlParser(info.description)}

                                                        </p>
                                                        <p class="font-weight-bolder">

                                                            {info.name}
                            , {info.city_name} </p>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        <p id={'showMoreDiv_' + info.review_id} style={{ minHeight: '93px' }}>
                                                            {ReactHtmlParser(info.short_description)}
                                                            {/* {info.short_description} */}
                                                            <span class="orangeColor" id={'showMoreSpan_' + info.review_id} onClick={this.showMore} style={{ cursor: 'pointer' }}><br />{t('home.Show more')}</span>
                                                        </p>

                                                        <p id={'showLessDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>
                                                            {ReactHtmlParser(info.description)}

                                                            <span class="orangeColor" id={'showLesSpan_' + info.review_id} onClick={this.showLess} style={{ cursor: 'pointer' }}><br />{t('home.Show less')}</span>
                                                        </p>

                                                        <p class="font-weight-bolder">{info.name}, {info.city_name} </p>

                                                    </div>

                                                )
                                            }
                                        }

                                        )}
                                        
                                        <p id="ShowAllReviewDiv"><button class="btn btn-outline-brown color-brown btn-sm" onClick={this.showallReview}>

                                            {t('home.SHOW ALL')}
                                        </button></p>

                                        <h5 class=" text-black-50">{t('home.Please add your comment')}</h5>
                                        <Form action="" id="pform" onSubmit={this.submitReview} method="post" encType="multipart/form-data" className="form-horizontal">
                                            <textarea id="user_review" name="user_review" class="form-control" rows="4"></textarea>
                                            <button class="btn btn-outline-brown color-brown mt-3" >{t('home.SUBMIT')}</button>
                                        </Form>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="col-lg-3 pt-3" id="eventRightMenuMain" style={{ marginTop: '23px' }}>
                            {arrMagazine.map((cell, i) => {
                                if (cell.magazine_id == 2) {
                                    return (
                                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div class="row d-flex">
                                                <div class="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                    return (
                                                                        <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <Link to={'/magazine'}>
                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                    </Link>
                                                                )
                                                            }
                                                        }
                                                        )}



                                                        <Link class="btn btn-brown btn-sm mb-2 btn-block magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div class="row d-flex">
                                                <div class="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                            <p >
                                                                                <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            <p >
                                                                                <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                }

                                                            } else {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        )}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}


                        </div>

                    </div>

                </div>
                <Footer />
                <div id="openGallery"  className="openGallerydiv" >
                <span ><i style={{ cursor: 'pointer' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon"></i></span>

                    {lightboxOpen && (
                        <ReactImageVideoLightbox
                            // data={[
                            //     { url: 'https://placekitten.com/450/300', type: 'photo', altTag: 'some image' },
                            //     { url: 'https://www.youtube.com/embed/ScMzIvxBSi4', type: 'video', altTag: 'some video' },
                            //     { url: 'https://placekitten.com/550/500', type: 'photo', altTag: 'some other image' },
                            //     { url: 'https://www.youtube.com/embed/ScMzIvxBSi4', type: 'video', altTag: 'some other video' }
                            // ]}

                            data={this.state.arrVideo}
                            startIndex={this.state.startIndex}
                            showResourceCount={true}
                            onCloseCallback={this.callbackFunction} />
                    )}

                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex]['image_url']}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
News_details.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(News_details);
