import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ListGroup,
    ListGroupItem

} from 'reactstrap';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';

function DesktopDesignParentChild(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-md-flex d-none">
            <Col xl="5" lg="5" md="5">
                <h4>
                    <Link style={{ color: '#212529' }} to={"/event_details/" + props.cell.event_id}>
                        {props.cell.title}
                    </Link>
                </h4>
                <Col xl="12" lg="12" md="12">
                    {props.cell.topic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7" md="7">
                <Row>
                    <Col xl="2" lg="2" md="2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>

                    <Col xl="2" lg="2" md="2">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Duration')}&nbsp;
                    </Label>
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                        <p className="mb-0 d-xl-block d-lg-block d-none">
                            <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                        </p>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parent" + props.cell.event_id} id={"parent" + props.cell.topic_id_value +"_"+ props.cell.stopic_id}  custom-attribute={props.cell.topic_id_value  +"_"+ props.cell.stopic_id}>
                             <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.second_price_for}</Label>
                        <select className="form-control form-control-sm" name={"child" + props.cell.event_id} id={"child" + props.cell.topic_id_value +"_"+ props.cell.stopic_id}  custom-attribute={props.cell.topic_id_value  +"_"+ props.cell.stopic_id}>
                             <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col xl="4" lg="4" md="4" className="text-right">
                        <h5>
                            {props.t('home.INR')} {props.cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.first_price_for}</small>
                            <br />
                            {props.t('home.INR')} {props.cell.second_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.second_price_for}</small>
                        </h5>


                        <button style={{ fontWeight: '700' }} className={'schedule_' + props.cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + 'd_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                          {props.t('home.SHOW SCHEDULE')}
                    </button>

                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

function MobileDesignParentChild(props) {
    return (
        <Row className="d-xl-none d-lg-none d-md-none d-flex">
            <Col xs="12" sm="12">
                <Link to={"/event_details/" + props.cell.event_id}>
                    <h5>
                        {props.cell.title}
                    </h5>
                </Link>
            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col sm="12" xs="12">
                        {props.cell.topic.map((info, ii) => {

                            if (ii == 0) {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            }
                        }
                        )}
                    </Col>
                    <Col sm="12" xs="12">
                        <Label>
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="font-weight-normal">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col sm="6" xs="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.first_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.first_price_for}
                        </small>
                        <br />
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.second_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.second_price_for}
                        </small>
                    </Col>
                    <Col sm="3" xs="3">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parentm" + props.cell.event_id} id={"parentm" + props.cell.event_id}>
                             <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col sm="3" xs="3">
                        <Label>{props.cell.second_price_for}</Label>
                        <select className="form-control form-control-sm" name={"childm" + props.cell.event_id} id={"childm" + props.cell.event_id}>
                            <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                </Row>

            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col sm="5" xs="5" className="align-self-start">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i>
                            <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>
                    <Col sm="7" xs="7" className="align-self-start">
                        <button className={'schedule_' + props.cell.event_id + '' + props.topicId + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton font-weight-bold'} id={'schedule_' + 'm_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                             {props.t('home.SHOW SCHEDULE')}
                            </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )



}

function DesktopDesignAdult(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-md-flex d-none">
            <Col xl="5" lg="5" md="5">
                <h4>
                    <Link style={{ color: '#212529' }} to={"/event_details/" + props.cell.event_id}>
                        {props.cell.title}
                    </Link>
                </h4>
                <Col xl="12" lg="12" md="12">
                    {props.cell.topic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7" md="7">
                <Row>
                    <Col xl="2" lg="2" md="2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>

                    <Col xl="2" lg="2" md="2">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Duration')}&nbsp;
                    </Label>
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                        <p className="mb-0 d-xl-block d-lg-block d-none">
                            <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                        </p>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.first_price_for}</Label>
                        <input type="hidden" name="topicevent_id" id="topicevent_id" value={props.cell.topic_id_value + props.cell.stopic_id} />

                        <select className="form-control form-control-sm q2" name={"parent" + props.cell.event_id} id={"parent" + props.cell.topic_id_value +"_"+props.cell.stopic_id}  custom-attribute={props.cell.topic_id_value  +"_"+  props.cell.stopic_id} >
                            <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>

                    <Col xl="6" lg="6" md="6" className="text-right">
                        <h5>
                            {props.t('home.INR')} {props.cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.first_price_for}</small>
                        </h5>


                        <button style={{ fontWeight: '700' }} className={'schedule_' + props.cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + 'd_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule} custom-ss-topic_id_value={props.cell.topic_id_value}>

                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

function MobileDesignAdult(props) {
    console.log("Mobile Design", props);
    return (
        <Row className="d-xl-none d-lg-none d-md-none d-flex">
            <Col xs="12" sm="12">
                <Link to={"/event_details/" + props.cell.event_id}>
                    <h5>
                        {props.cell.title}
                    </h5>
                </Link>
            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col xs="12" sm="12">
                        {props.cell.topic.map((info, ii) => {

                            if (ii == 0) {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            }
                        }
                        )}
                    </Col>
                    <Col sm="12" xs="12">
                        <Label>
                            {props.t('home.Duration')}&nbsp;
                    </Label>
                        <Label className="font-weight-normal">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col sm="6" xs="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.first_price}/-
                    </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.first_price_for}
                        </small>
                    </Col>
                    <Col sm="3" xs="3">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parent" + props.cell.event_id} id={"parent" + props.cell.topic_id_value +"_"+ props.cell.stopic_id}  custom-attribute={props.cell.topic_id_value  +"_"+ props.cell.stopic_id}>
                             <option value="0"  selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                </Row>

            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col sm="5" xs="5" className="align-self-start">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i>
                            <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>
                    <Col sm="7" xs="7" className="align-self-start">
                        <button className={'schedule_' + props.cell.event_id + '' + props.topicId + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton font-weight-bold'} id={'schedule_' + 'm_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

class Booknow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifyinfo: {
                first_name: '',
                last_name: '',
                message: '',
                status: '',
                email: '',
            },
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            showScheduleFlag:-1,
            types: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.deleteFromCart = this.deleteFromCart.bind(this);
        this.showSchedule = this.showSchedule.bind(this);
        this.showMoreEvents = this.showMoreEvents.bind(this);
        this.goToCart = this.goToCart.bind(this);
        this.notifyMe = this.notifyMe.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }


    leftScroll = () => {
        $(".navigate-scrollbar").animate({ scrollLeft: "-=" + 100 });

    }

    rightScroll = () => {
        $(".navigate-scrollbar").animate({ scrollLeft: "+=" + 100 });
    }
    handleSubmit(e) {
        e.preventDefault();
        var user_id = localStorage.getItem('user_id');
        var notify_schedule_id = $('#notify_schedule_id').val();
        console.log('this.refs', $('#pform').serializeArray());
        if (this.validator.allValid()) {
            const formData = {};
            for (const field in this.refs) {
                formData[field] = this.refs[field].value;
            }
            console.log('-->', formData);
            fetch(myConstClass.BASE_URL + `users/addnotify/` + user_id + '/' + notify_schedule_id, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),

            })
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    var message = this.state.notifyinfo.message;
                    var status = this.state.notifyinfo.status;
                    console.log('hii' + status);
                    if (status == 'true') {
                        // $('#address').modal('hide');
                        // $('#pform')[0].reset();

                        //const numRows = this.state.notifyinfo.length;

                        this.state.notifyinfo.first_name = this.state.notifyinfo.first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo.last_name;

                        //this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo.email;
                        //this.state.notifyinfo.event_type = this.state.notifyinfo[0].event_type;
                        //this.state.notifyinfo.comment = this.state.notifyinfo[0].comment;

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    $('#notifyModel').modal('hide');
                    //console.log(status);
                })
                .catch(err => console.log(err))
        }
        else {
            //alert('here');
            this.validator.showMessages();
            console.log('validations' + this.validator.showMessages());
            e.preventDefault();
            toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

        }
    }

    notifyMe(e) {
        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var schedule_id = arr_event_schedule[1];
        var title = $('.notifyScheduletitle_' + schedule_id).val();
        var eventTitle = $('#notifyEventtitle_' + schedule_id).val();
        $('#eventTitle').html(eventTitle);
        // alert(schedule_id);

        $('#notifyTitle').html(title);
        $('#notify_schedule_id').val(schedule_id);
        $('#notifyModel').modal('show');
    }

    goToCart() {
        var user_id = localStorage.getItem('user_id');
        if (user_id > 0 && user_id != undefined) {
            if(parseInt(localStorage.getItem("cartCount")) > 0) {
                this.props.history.push('/mycart');
            } else {
                if(this.state.showScheduleFlag > -1) {
                    if(this.state.showScheduleFlag) {
                        if (getLanguage() == 'ma') {
                            var message = "कृपया कार्टवर जोडा वर क्लिक करा.";
                        } else {
                            var message = "Please click on add to cart.";
                        }    
                    } else {
                        if (getLanguage() == 'ma') {
                            message = "कार्यक्रमाचे वेळापत्रक उपलब्ध नाही.";
                        } else {
                            message = "Event schedule not available.";
                        }  
                    }
                } else {
                    if (getLanguage() == 'ma') {
                        var message = "कृपया शो वेळापत्रक वर क्लिक करा एक तारीख निवडा आणि कार्टमध्ये जोडा.";
                    } else {
                        var message = "Please click on show schedule select a date and add to cart.";
                    }
                }
                
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }
            
        } else {
            var message = "Please login to our website.";
            localStorage.removeItem('error');
            localStorage.setItem('error', message);
            this.props.history.push('/signin')
            //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

        }
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
    }
    increaseQuantity(evt) { //console.log(evt);
        var qty = evt.target.value;
        var qty = parseInt(qty) + 1; //alert(evt.target.value);
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: qty });
    }
    componentDidMount() {
        console.log("hello : ",this.props)
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        var event_type = answer_array['3'];
        
        var current_product_type = answer_array['3'];
        // alert(answer_array['3']);
        if (answer_array['3'] > 0) {
            localStorage.setItem('old_event_type', current_product_type);
        } else {
            var current_product_type = 0;
            localStorage.setItem('old_event_type', current_product_type);
        }
        if ((answer_array['3'] == 4 || answer_array['3'] == 2) && current_language == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'inline');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'none');
        } else if ((answer_array['3'] == 1 || answer_array['3'] == 5) && current_language == 'ma') {
            //alert('#bookNowInCartMrPoojs');
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'inline');
            $('.bookNowInCartEn').css('display', 'none');
        }

        if (event_type == 1) {
            $("#residentialStudyCources").removeClass("orangeColor");
            $('#residentialStudyCources').css('color', 'black');

            $('#residentialStudyCources').css('border-bottom', 'solid 2px #ed9a16');
            $('.for_res_event').css('display', 'contents');
            $('.for_other_event').css('display', 'none');

        } else if (event_type == 2) {
            $("#oneDayWorkshop").removeClass("orangeColor");
            $('#oneDayWorkshop').css('color', 'black');
            $('#oneDayWorkshop').css('border-bottom', 'solid 2px #ed9a16');
            $('#oneDayWorkshop').css('color', '#3');
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else if (event_type == 4) {
            $("#rationalPooja").removeClass("orangeColor");
            $('#rationalPooja').css('color', 'black');
            $('#rationalPooja').css('border-bottom', 'solid 2px #ed9a16');
            $('#rationalPooja').css('color', '#3 ');
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else {
            $("#freeEvents").removeClass("orangeColor");
            $('#freeEvents').css('color', 'black');
            $('#freeEvents').css('border-bottom', 'solid 2px #ed9a16');
            $('#freeEvents').css('color', '#3');
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        }

        var topic_id = answer_array['4'];

        var language_id = answer_array['5'];

        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            topic_id = 0;
        }

        if (language_id > 0) {
            this.state.guestinfo.language_id = language_id;
        } else {
            language_id = 0;
        }

        // alert(topic_id);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }

        fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                this.state.guestinfo.event_type_name = answer_array['2'];
                this.state.guestinfo.event_type = answer_array['2'];
                this.state.guestinfo.event_id = this.state.posts[0].event_id;

                if (this.state.posts.length > 0) {
                    var numRows = this.state.posts[0].arr_events.length;
                    if (numRows < 1) {
                        $('#productNotAvailable').css('display', 'block');
                    } else {
                        $('#productNotAvailable').css('display', 'none');
                    }
                } else {
                    $('#productNotAvailable').css('display', 'block');
                }


            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'] + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ eventdetails: response })
                //const numRows = this.state.scheduleEvent.length;
                var event_type_id = response[0].event_type_id;
                //alert(event_type_id);
                if (event_type_id == 7) {
                    $('#CurrentEventType').css('display', 'none');

                } else {
                    $('#CurrentEventType').css('display', 'inline');
                }

            })


        fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
            .then(response => response.json())
            .then(response => {
                this.setState({ cartEvent: response })

                const numRows = this.state.cartEvent.length;
                localStorage.setItem("cartCount",numRows)
                if (numRows > 0) {
                    $('#noItemInCart').css('display', 'none');
                } else {
                    $('#noItemInCart').css('display', 'block');
                }

            })


        fetch(myConstClass.BASE_URL + 'event/getEventTypes')
            .then(response => response.json())
            .then(response => this.setState({ eventTypes: response }))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
            .then(response => response.json())
            .then(response => this.setState({ languages: response }))
            .catch(err => console.log(err))

        if (user_id > 0) {
            var btoa_user_id = btoa(user_id);
            // fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + user_id)
            fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + btoa_user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    const numRows = this.state.notifyinfo.length;
                    if (numRows > 0) {
                        this.state.notifyinfo.first_name = this.state.notifyinfo[0].first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo[0].last_name;
                        this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo[0].email;

                    }
                })
        }


    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
        //alert(answer_array['2']);
        const { flag } = this.state
        var current_product_type = answer_array['3'];
        //alert(answer_array['3']);
        var old_event_type = localStorage.getItem('old_event_type');
        var user_id = localStorage.getItem('user_id');
        localStorage.setItem('old_event_type', current_product_type);

        var language_id = answer_array['5'];
        if (language_id == '') {
            language_id = 0;
        }
         if (answer_array['3'] == 1 ) { 
            $('.for_res_event').css('display', 'contents');
            $('.for_other_event').css('display', 'none');
         }else{
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
         }
        var old_language_id = localStorage.getItem('old_language_id');
        localStorage.setItem('old_language_id', language_id);
        if ((answer_array['3'] == 4 || answer_array['3'] == 2) && getLanguage() == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'inline');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'none');
        } else if ((answer_array['3'] == 1 || answer_array['3'] == 5) && getLanguage() == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'inline');
            $('.bookNowInCartEn').css('display', 'none');
        } else {
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'inline');
        }
        //alert(old_event_type);
        if (old_event_type != '' && flag != 1 && (current_product_type != old_event_type || old_language_id != language_id) && old_event_type != null && old_event_type > 0) {
            // this.setState({ flag: 1 });
            //alert('hi');
        } else {
            if (current_product_type == 0 && flag != 1 && (current_product_type != old_event_type || old_language_id != language_id) && old_event_type != null) {
                //alert('hii')
                // this.setState({ flag: 1 });
            }
        }


        var current_topic_id = answer_array['4'];
        //alert(current_topic_id);
        var old_topic_id = localStorage.getItem('old_topic_id');
        if (old_topic_id == undefined) {
            old_topic_id = answer_array['4'];
        }
        //alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id);
        localStorage.setItem('old_topic_id', current_topic_id);
        //alert(old_topic_id);
        if (old_topic_id != '' && flag != 1 && current_topic_id != old_topic_id && old_topic_id != null && old_topic_id > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        } else {
            if (current_topic_id > 0 && flag != 1 && current_topic_id != old_topic_id) {
                this.setState({ flag: 1 });
            }
        }


        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            this.setState({ flag: 1 });

            // alert(this.state.languagePdf);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
        }

        var event_type = answer_array['3'];

        var topic_id = answer_array['4'];
        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }
        // alert(event_type);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }
        var user_id = localStorage.getItem('user_id');
        //const { flag} = this.state  
        if (getLanguage() == 'ma' && prevProps.history.location.pathname !== prevProps.location.pathname) {


            this.state.scheduleEvent = [];
             $('.noScheduleAvailable').css('display','block');
            $(".eventTypeName").addClass("orangeColor");
            if (event_type == 1) {
                $("#residentialStudyCources").removeClass("orangeColor");
                $('#residentialStudyCources').css('color', 'black');
                $('#residentialStudyCources').css('border-bottom', 'solid 2px #ed9a16');


                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');
            } else if (event_type == 2) {
                $("#oneDayWorkshop").removeClass("orangeColor");
                $('#oneDayWorkshop').css('color', 'black');
                $('#oneDayWorkshop').css('border-bottom', 'solid 2px #ed9a16');

                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');

            } else if (event_type == 4) {
                $("#rationalPooja").removeClass("orangeColor");
                $('#rationalPooja').css('color', 'black');
                $('#rationalPooja').css('border-bottom', 'solid 2px #ed9a16');

                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');
            } else {
                $("#freeEvents").removeClass("orangeColor");
                $('#freeEvents').css('color', 'black');
                $('#freeEvents').css('border-bottom', 'solid 2px #ed9a16');

                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
            }

            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;

                        if (numRows < 1) {
                            $('#productNotAvailable').css('display', 'block');
                        } else {
                            $('#productNotAvailable').css('display', 'none');
                        }
                    } else {
                        $('#productNotAvailable').css('display', 'block');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;
                    var event_type_id = this.state.eventdetails[0].event_type_id;
                    if (event_type_id == 7) {
                        $('#CurrentEventType').css('display', 'none');

                    } else {
                        $('#CurrentEventType').css('display', 'inline');
                    }

                })


            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })

                    const numRows = this.state.cartEvent.length;
                    localStorage.setItem("cartCount",numRows)
                    if (numRows > 0) {
                        $('#noItemInCart').css('display', 'none');
                    } else {
                        $('#noItemInCart').css('display', 'block');
                    }

                })


        }

        if (getLanguage() == 'en' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            $(".eventTypeName").addClass("orangeColor");
            this.state.scheduleEvent = [];
             $('.noScheduleAvailable').css('display','block');
            if (event_type == 1) {
                $('#residentialStudyCources').css('border-bottom', 'solid 2px #ed9a16');
                $("#residentialStudyCources").removeClass("orangeColor");
                $('#residentialStudyCources').css('color', 'black');

                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');
            } else if (event_type == 2) {
                $('#oneDayWorkshop').css('color', 'black');
                $("#oneDayWorkshop").removeClass("orangeColor");

                $('#oneDayWorkshop').css('border-bottom', 'solid 2px #ed9a16');

                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');

            } else if (event_type == 4) {
                $('#rationalPooja').css('color', 'black');
                $("#rationalPooja").removeClass("orangeColor");
                $('#rationalPooja').css('border-bottom', 'solid 2px #ed9a16');

                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#freeEvents').css('border-bottom', 'none');
            } else {
                $('#freeEvents').css('color', 'black');
                $("#freeEvents").removeClass("orangeColor");
                $('#freeEvents').css('border-bottom', 'solid 2px #ed9a16');

                $('#oneDayWorkshop').css('border-bottom', 'none');
                $('#residentialStudyCources').css('border-bottom', 'none');
                $('#rationalPooja').css('border-bottom', 'none');
            }

            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows < 1) {
                            $('#productNotAvailable').css('display', 'block');
                        } else {
                            $('#productNotAvailable').css('display', 'none');
                        }
                    } else {
                        $('#productNotAvailable').css('display', 'block');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    console.log("EVent Details : ",response)
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;
                    var event_type_id = response[0].event_type_id;
                    if (event_type_id == 7) {
                        $('#CurrentEventType').css('display', 'none');

                    } else {
                        $('#CurrentEventType').css('display', 'inline');
                    }
                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })

                    const numRows = this.state.cartEvent.length;
                    localStorage.setItem("cartCount",numRows)
                    if (numRows > 0) {
                        $('#noItemInCart').css('display', 'none');
                    } else {
                        $('#noItemInCart').css('display', 'block');
                    }

                })


        }
        this.state.guestinfo.event_type_name = answer_array['2'];
    }

    changeQuantity(e, product_id) {
        //this.state.quantityValue = event.target.value;
        // this.setState(
        //     {quantityValue : event.target.value}
        // );
        //alert(product_id);
        // this.setState({quantityValue: e.target.value});
        // document.getElementById("qty"+product_id).value = e;

    }


   showSchedule(e) {

        const currentEventId = e.target.id;

        var btnName =  document.getElementById(currentEventId).innerHTML;
        var custom_ss_topic_id_value= (e.currentTarget.getAttribute('custom-ss-topic_id_value'));
        //alert(btnName);
        if (btnName == 'SHOW SCHEDULE' || btnName == 'वेळापत्रक दर्शवा'){

            const previousEventId = localStorage.getItem('currentEventId');

            const eventIdArr = currentEventId.split('_');
       
            const eventId = eventIdArr[1];
                      
            const eventTopicId = eventIdArr[3];

            var user_id = localStorage.getItem('user_id');
            if (user_id == null || user_id == undefined) {
                user_id = 0;
            }



            if (document.getElementById(previousEventId)) {
                if(getLanguage() == 'ma'){
                     document.getElementById(previousEventId).innerHTML = "वेळापत्रक दर्शवा"
                 }else{
                     document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE"
                 }
               
            }

            $(".eventrow").removeClass("border-warning ");
            $(".eventrow").removeClass("bg-white");
            $(".eventrow").addClass("border-dark bg-light-light");

            $('.viewScheduleForMobile').css('display', 'none');
            $('.viewScheduleForMobile' + eventIdArr[1] + '_' + eventIdArr[2] + '_' + eventIdArr[3]).css('display', 'inline');
            console.log('.viewScheduleForMobile' + eventIdArr[1] + '_' + eventIdArr[2] + '_' + eventIdArr[3]);

            $("." + previousEventId).removeClass("btn-light");
            $("." + previousEventId).addClass("btn-outline-brown");
            $("." + previousEventId).css("color", 'red');

            $("." + currentEventId).removeClass("btn-outline-brown");
            $("." + currentEventId).addClass("btn-light");
            $("." + currentEventId).css("color", 'black');


            // $(".Row_" + event_id).removeClass("border-dark bg-light-light");
            // $(".Row_" + event_id).addClass("border-warning bg-white");

            localStorage.removeItem('currentEventId');
            localStorage.setItem('currentEventId', currentEventId);


            fetch(myConstClass.BASE_URL + 'event/getAllScheduleEventFrontEnd' + '/' + getLanguage() + '/' + eventIdArr[2] + '/' + user_id +"/"+eventTopicId )
                .then(response => response.json())
                .then(response => {
                    this.setState({ scheduleEvent: response,showScheduleFlag:response.length })
                    const numRows = this.state.scheduleEvent.length;
                    $('.scheduleMainDiv').css('display', 'block');
                    $('.withoutScheduleDiv').css('display', 'none');
                    $('.eventRightMenuMain').css('margin-top', '48px');
                    if(getLanguage() == 'ma'){
                        document.getElementById(currentEventId).innerHTML = "वेळापत्रक लपवा";
                    }else{
                        document.getElementById(currentEventId).innerHTML = "HIDE SCHEDULE";
                    }
                    if(getLanguage() == 'ma'){
                        document.getElementById(previousEventId).innerHTML = "वेळापत्रक दर्शवा";
                    }else{
                        document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE";
                    }

                    if (numRows < 1) {
                        $('.noScheduleAvailable').css('display', 'block');
                        $('.eventRightMenuMain').css('margin-top', '48px')
                    } else {
                        this.setState({
                            guestinfo: {
                                ...this.state.guestinfo,
                                schedule_event_name: response[0].title
                            }
                        })
                        $('.noScheduleAvailable').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))
        } else {
            const previousEventId = localStorage.getItem('currentEventId');
            $(".eventrow").removeClass("border-warning");
            $(".eventrow").removeClass("bg-white");
            $(".eventrow").addClass("border-dark bg-light-light");

            $('.viewScheduleForMobile').css('display', 'none');
            $('.viewScheduleForMobile' + currentEventId).css('display', 'inline');

            $('.scheduleMainDiv').css('display', 'none');
            $('.withoutScheduleDiv').css('display', 'block');
            $(".schedule_" + previousEventId).removeClass("btn-light");
            $(".schedule_" + previousEventId).addClass("btn-outline-brown");
            $(".schedule_" + previousEventId).css("color", 'black');
            //document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE"
            this.setState({ scheduleEvent: [] })
        }


    }

    showMoreEvents(e) {
        var show_more_event_type_id = e.target.id;
        var arr_show_more_event_type = show_more_event_type_id.split('_');
        var current_event_type_id = arr_show_more_event_type[1];
        $('.showMoreEventsDiv_' + current_event_type_id).css('display', 'block');
        $('#showMoreEvents_' + current_event_type_id).css('display', 'none');
    }


    deleteFromCart(e) {
        var delete_event_schedule_id = e.target.id;
        var arr_event_schedule = delete_event_schedule_id.split('_');
        var event_id = arr_event_schedule[1];
        var schedule_id = arr_event_schedule[2];
        var event_schedule_id = event_id + '_' + schedule_id;
        // console.log(e.target.id);
        //alert(event_id);

        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((schedule_id > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + schedule_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeFromCart: response })
                    var message = this.state.removeFromCart.message;
                    var status = this.state.removeFromCart.status;
                    if (status == 'true') {
                        $('.' + event_schedule_id).css('display', 'inline');
                        $('.remove' + schedule_id).css('display', 'none');
                        $('.' + delete_event_schedule_id).css('display', 'none'); //for delete

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }
                                })
                        }


                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Error occure.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }


    addToCart(e) {
        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];
        var schedule_id = arr_event_schedule[1];
        // console.log(e.target.id);
        //alert(event_id);

        var custome_id = (e.currentTarget.getAttribute('custom-attribute'));
        var parent = $('#parent' + custome_id).val();
        // alert(parent);
        if(parent > 0){

        }else{
            var parent = $('#parentm' + event_id).val();
        }

        var child = $('#child' + custome_id).val();
        if(child > 0){

        }else{
            var child = $('#childm' + event_id).val();
        }
        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addEventToCart/' + schedule_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {

                        $('.' + event_schedule_id).css('display', 'none');
                        $('.remove' + schedule_id).css('display', 'contents');
                        $('.delete_' + event_schedule_id).css('display', 'contents');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                    localStorage.setItem("cartCount", totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                   if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }
                                })

                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if(getLanguage() == 'ma'){
					var message = "कृपया सहभागींची संख्या निवडा.";
                }else{
                    var message = "Please select the number of participants.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    
    mobileApplyFilter = () => {
        this.applyFilters($('#topic_id_m').val(), $('#language_id_m').val())
    }

    desktopApplyFilter = () => {
        this.applyFilters($('#topic_id').val(), $('#language_id').val())
    }

    applyFilters(topic_id, language_id) {
        const answer_array = this.props.location.pathname.split('/');
        console.log(topic_id, language_id)
        if (topic_id == '') {
            topic_id = 0;
        }
        
        if (language_id == '') {
            language_id = 0;
        }

        var event_type = answer_array['3'];
        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/' + language_id;
            $('#residentialStudyCources').css('border-bottom', 'solid 2px #ed9a16');
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/' + language_id;
            $('#oneDayWorkshop').css('border-bottom', 'solid 2px #ed9a16');
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/' + language_id;
            $('#rationalPooja').css('border-bottom', 'solid 2px #ed9a16');
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/' + language_id;
            $('#freeEvents').css('border-bottom', 'solid 2px #ed9a16');
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/' + language_id;
        } else {
            var path = "booknow/0" + '/' + topic_id + '/' + language_id;
        }
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/booknow/' + path);
        }



        //    const answer_array = this.props.location.pathname.split('/');
        //    var event_type =  $('#event_type_id').val();


        //    //var event_type =  answer_array['3'];
        //      // alert(event_type);
        //      if(event_type > 0){
        //         this.state.guestinfo.event_type_id = event_type; 
        //      }else{
        //         var event_type = 0; 
        //      }

        //    fetch(myConstClass.BASE_URL+'event/getEventsFrontEnd'+'/'+getLanguage()+'/'+event_type)
        //    .then(response => response.json())
        //    .then(response =>{this.setState({posts: response})
        //        this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
        //        console.log('hi'+this.state.posts[0].event_type);
        //    })

        // this.componentDidMount();
        //console.log(this.state.posts);
        //
        //console.log(this.state.eventinfo);
        //this.state.posts = this.state.eventinfo;
        //this.setState({ posts: this.state.eventinfo });
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { notifyinfo, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <Link to="/booknow">{t('home.Book Now')}</Link>
                                        </li>
                                        
                                    </ol>
                                </nav>
                            </div>
                            
                            <div className="my-3 mx-2 d-xl-block d-lg-block d-md-block d-none">
                                <div className="row">
                                    <div className="d-xl-none d-lg-none d-md-none col-1 align-self-center" onClick={this.leftScroll}>
                                        <i className="fa fa-angle-left"></i>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-9 align-self-center">
                                        <div className="row flex-row flex-nowrap overflow-auto navigate-scrollbar">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-8 font-weight-bold" >
                                                <Link id="residentialStudyCources" style={{ paddingRight: '0px', cursor: 'pointer', }} className="active eventTypeName orangeColor" to="/booknow/Residential Study Courses/1" >{t('home.Residential Study Courses')}</Link>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-6 font-weight-bold">
                                                <Link style={{ paddingRight: '0px', cursor: 'pointer', }} className="eventTypeName orangeColor" id="oneDayWorkshop" to="/booknow/One Day Workshops/2">
                                                    {t('home.1-day Workshops')}
                                                </Link>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-5 font-weight-bold">
                                                <Link style={{ paddingRight: '0px', cursor: 'pointer', }} className="eventTypeName orangeColor" id="rationalPooja" to="/booknow/Rational Poojas/4">
                                                    {t('home.Rational Poojs')}
                                                </Link>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-4 font-weight-bold">
                                                <Link style={{ paddingRight: '0px', cursor: 'pointer' }} className="eventTypeName orangeColor" id="freeEvents" to="/booknow/Free Events/5">
                                                    {t('home.Free Events')}
                                                </Link>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-5 font-weight-bold">
                                                <Link id="machineTests" style={{ paddingRight: '0px', cursor: 'pointer' }} className="eventTypeName  orangeColor active" to="/machine-test/Machine Tests/3" >{t('home.Machine Tests')}</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-xl-none d-lg-none d-md-none col-1 align-self-center" onClick={this.rightScroll}>
                                        <i className="fa fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3 text-center d-xl-none d-lg-none d-md-none d-block">
                                <div id="carouselExampleControls" class="carousel slide" data-interval="false">
                                    <a href="#carouselExampleControls" role="button" data-slide="prev">
                                        <i className="fa fa-angle-left text-dark"></i>
                                    </a>
                                    <div class="carousel-inner d-inline-block w-75 text-center">
                                        <div class="carousel-item active">
                                            <Link id="residentialStudyCources_m" 
                                                // style={ this.props.location.pathname.split('/')[2] } 
                                                to="/booknow/Residential Study Courses/1" 
                                                style={this.props.location.pathname == '/booknow/Residential Study Courses/1'?{color:'black',borderBottomWidth:'2px',borderBottomColor:'#ed9a16',borderBottomStyle:'solid'}:{color:'#ed9a16'} } >
                                                {t('home.Residential Study Courses')}
                                            </Link>
                                        </div>
                                        <div class="carousel-item">
                                            <Link  
                                                id="oneDayWorkshop_m" 
                                                style={this.props.location.pathname == '/booknow/One Day Workshops/2'?{color:'black',borderBottomWidth:'2px',borderBottomColor:'#ed9a16',borderBottomStyle:'solid'}:{color:'#ed9a16'} } 
                                                to="/booknow/One Day Workshops/2">
                                                {t('home.1-day Workshops')}
                                            </Link>
                                        </div>
                                        <div class="carousel-item">
                                            <Link 
                                                style={this.props.location.pathname == '/booknow/Rational Poojas/4'?{color:'black',borderBottomWidth:'2px',borderBottomColor:'#ed9a16',borderBottomStyle:'solid'}:{color:'#ed9a16'} } 
                                             id="rationalPooja_m" to="/booknow/Rational Poojas/4">
                                                {t('home.Rational Poojs')}
                                                
                                            </Link>
                                        </div>
                                        <div class="carousel-item">
                                            <Link id="freeEvents_m" to="/booknow/Free Events/5"
                                                style={this.props.location.pathname == '/booknow/Free Events/5'?{color:'black',borderBottomWidth:'2px',borderBottomColor:'#ed9a16',borderBottomStyle:'solid'}:{color:'#ed9a16'} } >
                                                {t('home.Free Events')}
                                            </Link>
                                        </div>
                                        <div class="carousel-item">
                                        <Link id="machineTests_m" to="/machine-test/Machine Tests/3" 
                                            style={this.props.location.pathname == '/machine-test/Machine Tests/3'?{color:'black',borderBottomWidth:'2px',borderBottomColor:'#ed9a16',borderBottomStyle:'solid'}:{color:'#ed9a16'} }>
                                            {t('home.Machine Tests')}
                                        </Link>
                                        </div>
                                    </div>
                                    <a href="#carouselExampleControls" role="button" data-slide="next">
                                        <i className="fa fa-angle-right text-dark"></i>
                                    </a>
                                </div>
                            </div>
                            <Row className="bg-light m-0 py-2">
                                <Col xl="3" lg="3" md="3" className="align-self-center">
                                    {t('home.Select_filters_to_refine_the_results_below')}:
                                    </Col>

                                {/* Desktop Design Start */}
                                <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div className="form-group">

                                        <label for="">{t('home.Topic')}</label>

                                        <select data-dropup-auto="false" id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                            <option value="">{t('home.All')}</option>
                                            {topic.map((info, ii) => {
                                                if (guestinfo.topic_id == info.value) {
                                                    return (
                                                        <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option className="selectOrange" value={info.value}>{info.label}</option>
                                                    )
                                                }
                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex  d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Language')}</label>
                                        <select id="language_id" name="language_id" className="form-control form-control-sm">
                                            <option value="">{t('home.All')}</option>
                                            {languages.map((info, ii) => {
                                                if (info.value != 4) {
                                                    if (guestinfo.language_id == info.value) {
                                                        return (
                                                            <option value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                }

                                            })


                                            }
                                        </select>
                                    </div>

                                </Col>
                                <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex  d-none">
                                    <div className="form-group">
                                        <button style={{ marginTop: '28px' }} onClick={this.desktopApplyFilter} className="btn btn-sm btn btn-outline-brown btn-block ">
                                            {t('home.APPLY FILTERS')}

                                        </button>
                                    </div>
                                </Col>
                                {/* Desktop Design End */}

                                {/* Mobile Design Start */}
                                <Col sm="8" xs="8" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup>
                                        <Label for="">{t('home.Topic')}</Label>

                                        <Input type="select" data-dropup-auto="false" id="topic_id_m" name="topic_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All')}</option>
                                            {topic.map((info, ii) => {
                                                if (guestinfo.topic_id == info.value) {
                                                    return (
                                                        <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option className="selectOrange" value={info.value}>{info.label}</option>
                                                    )
                                                }
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="4" xs="4" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup>
                                        <Label for="">{t('home.Language')}</Label>
                                        <Input type="select" id="language_id_m" name="language_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All')}</option>
                                            {languages.map((info, ii) => {
                                                if (info.value != 4) {
                                                    if (guestinfo.language_id == info.value) {
                                                        return (
                                                            <option value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                }

                                            })


                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <div className="form-group">
                                        <button onClick={this.mobileApplyFilter} className="btn btn-sm btn btn-outline-brown btn-block ">
                                            {t('home.APPLY FILTERS')}
                                        </button>
                                    </div>
                                </Col>
                                {/* Mobile Design End */}
                            </Row>
                            {/* <div className="row">
                    <div className="col-12">
                        <h6 className="color-red my-2">
                        {t('home.Step 1')} : 
                        {t('home.Please_call_and_take_and_appointment_before_booking_the_Test.')}
                            

                        </h6>
                        <p>
                        {t('home.Contact on')} : {t('home.Monday_to_Friday')}
                            

                             <br/> {t('home.Phone')}: {t('home.Phone_all')} 
                             
                             </p>
                    </div>
                </div> */}
                        </div>

                        {/* <div className="col-lg-3 pt-3">
                <p id="bookNowSeeAllMachineTest">
                 <Link id="machineTests" style={{marginTop:'-8px',cursor:'pointer',marginLeft:'-10px'}} className="nav-link  orangeColor active"  to="/machine-test/Machine Tests/3" >{t('home.Machine Tests')}</Link>    
                </p>    
            </div> */}





                        <div className="col-lg-9 mt-3" style={{ maxHeight: '900px', overflowY: 'scroll' }}>
                            {posts.map((info, ii) => {
                                if (info.arr_events.length > 4) {
                                    return (
                                        <div >
                                            <div className="row ">
                                                <div className="col">
                                                    <h4 style={{ fontSize: '1.4rem' }}>
                                                        {info.topic_title}
                                                    </h4>
                                                    <h6 className="color-red my-2 mb-4 fontWeight700">
                                                        {t('home.Step 1')}:
                                                        {t('home.Click on Show Schedule of the')}
                                                        {eventdetails.map((infos, ii) => {
                                                            return (
                                                                <span>{infos.event_type}</span>

                                                            )
                                                        })
                                                        }
                                                        {t('home.you intend to attend')}

                                                    </h6>
                                                </div>
                                            </div>

                                            {info.arr_events.map((cell, i) => {
                                                if (i < 4) {
                                                    if (cell.first_price > 0 && cell.second_price > 0) {
                                                        return (
                                                            <div className="row px-3" >
                                                                <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id} >
                                                                    <DesktopDesignParentChild
                                                                        cell={cell}
                                                                        showSchedule={this.showSchedule}
                                                                        t={(name) => t(name)}
                                                                        topicId={info.topic_id}
                                                                    />
                                                                    <MobileDesignParentChild
                                                                        cell={cell}
                                                                        showSchedule={this.showSchedule}
                                                                        t={(name) => t(name)}
                                                                        topicId={info.topic_id}
                                                                        topicList={this.state.topic}
                                                                        pathname={this.props.location.pathname}
                                                                    />
                                                                </div>
                                                                <div className="pt-3 eventRightMenuMainMobile w-100">
                                                                    <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                        <div className="withoutScheduleDiv">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    4519                  <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                                    {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                <div className="row d-flex">
                                                                                    <div className="col-12">

                                                                                        {t('home.Click on Show Schedule of the BooNow')}&nbsp;
                                                                            {eventdetails.map((infos, ii) => {
                                                                                            //var current_type_name = infos.event_type.toLowerCase();
                                                                                            var current_type_name = infos.event_type;
                                                                                            return (
                                                                                                <span className="CurrentEventType">{current_type_name} </span>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                         &nbsp;
                            {t('home.dates BooNow')}
                                                                                    </div>

                                                                                </div>


                                                                            </div>

                                                                        </div>




                                                                        <ListGroup style={{ display: 'none' }} className="scheduleMainDiv">
                                                                            <ListGroupItem>
                                                                                <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                            </ListGroupItem>
                                                                            {scheduleEvent.map((cell, ii) => {
                                                                                if (ii == 0) {
                                                                                    return (
                                                                                        <ListGroupItem className="font-weight-bolder robotoFontFamily">

                                                                                            {t('home.Upcoming')}&nbsp;

                                                                                          
                                                                                        {cell.title}
                                                                                           <span class="for_res_event" style={{display:'none'}}> {t('home.for')}</span>&nbsp;
                                                                                       <span class="for_other_event" style={{display:'none'}}>{t('home.for other event')} </span>
                                                                                       

                                                                                            

                                                                                        </ListGroupItem>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }

                                                                            {scheduleEvent.map((info, i) => {
                                                                                if (i == 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <ListGroupItem>
                                                                                                <span className="orangeColor">
                                                                                                    {info.date}
                                                                                                </span>
                                                                                            </ListGroupItem>
                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                if (cell.is_full == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    {t('home.FULL')}
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else if (cell.notify == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else {
                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </Col>
                                                                                                                    <Col className="text-right">
                                                                                                                        <span
                                                                                                                            className="mr-3"
                                                                                                                            style={{ cursor: 'pointer' }}>
                                                                                                                            <i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                        </span>
                                                                                                                        <br />
                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p className="text-right">
                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                            <br />
                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    }
                                                                                                }//Else complete


                                                                                            })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                } else {

                                                                                    return (
                                                                                        <>
                                                                                            <ListGroupItem>
                                                                                                <span className="orangeColor mb-0">
                                                                                                    {info.date}
                                                                                                </span>
                                                                                            </ListGroupItem>
                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                if (cell.is_full == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format}
                                                                                                                    <br />


                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>

                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </Col>
                                                                                                                <Col className="text-right">
                                                                                                                    {t('home.FULL')}
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else if (cell.notify == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>

                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else {
                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p className="text-right">
                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                            <br />
                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    }
                                                                                                }//Else complete


                                                                                            })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }



                                                                            })
                                                                            }







                                                                            <ListGroupItem style={{ display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                                                                {t('home.No schedule available')}
                                                                            </ListGroupItem>
                                                                        </ListGroup>




                                                                         <h6 class="color-red">
                 <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                {eventdetails.map((infos, ii) => {
                                 return(
                                  <span id="CurrentEventType">{infos.event_type} </span>      
                                    )
                              })
                            }&nbsp;

                  <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                    </h6>
                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                            {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            {cartEvent.map((info, i) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div className="row d-flex">
                                                                                            <div className="col-6">
                                                                                                <p className="font-weight-bold">
                                                                                                    {info.title}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-6 pl-0 text-right">
                                                                                                <div className="row">
                                                                                                    <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                        {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                        {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                        {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                        <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                            {info.city_name}
                                                                                                        </a>
                                                                                                        <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                            {info.city_name}
                                                                                                        </a>
                                                                                                        <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                            <p>{info.date}, {info.venue_address}</p>
                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                {info.contact_details}</p>
                                                                                                        </ReactTooltip>
                                                                                                    </div>
                                                                                                    <div className="col-2">
                                                                                                        {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                        <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }

                                                                            <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                            <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="row px-3">
                                                                <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                    <DesktopDesignAdult
                                                                        cell={cell}
                                                                        showSchedule={this.showSchedule}
                                                                        t={(name) => t(name)}
                                                                        topicId={info.topic_id}
                                                                    />
                                                                    <MobileDesignAdult
                                                                        cell={cell}
                                                                        showSchedule={this.showSchedule}
                                                                        t={(name) => t(name)}
                                                                        topicId={info.topic_id}
                                                                        topicList={this.state.topic}
                                                                        pathname={this.props.location.pathname}
                                                                    /></div>
                                                                <div className="pt-3 eventRightMenuMainMobile w-100">
                                                                    <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                        <div className="withoutScheduleDiv">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                                     <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                                    {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                <div className="row d-flex">
                                                                                    <div className="col-12">

                                                                                        {t('home.Click on Show Schedule of the BooNow')}&nbsp;
                                                                            {eventdetails.map((infos, ii) => {
                                                                                            //var current_type_name = infos.event_type.toLowerCase();
                                                                                            var current_type_name = infos.event_type;
                                                                                            return (
                                                                                                <span className="CurrentEventType">{current_type_name} </span>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                        &nbsp;
                            {t('home.dates BooNow')}
                                                                                    </div>

                                                                                </div>


                                                                            </div>

                                                                        </div>




                                                                        <ListGroup style={{ display: 'none' }} className="scheduleMainDiv">
                                                                            <ListGroupItem>
                                                                                <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                            </ListGroupItem>
                                                                            {scheduleEvent.map((cell, ii) => {
                                                                                if (ii == 0) {
                                                                                    return (
                                                                                        <ListGroupItem className="font-weight-bolder robotoFontFamily">

                                                                                            {t('home.Upcoming')}&nbsp;
                                                                                            {eventdetails.map((infos, ii) => {
                                                                                                return (
                                                                                                    <span className="CurrentEventType">{infos.event_type} </span>
                                                                                                )
                                                                                            })
                                                                                            }&nbsp;
                                                                                            <span class="for_res_event" style={{display:'none'}}> {t('home.for')}</span>&nbsp;
                                                                                       <span class="for_other_event" style={{display:'none'}}>{t('home.for other event')} </span>
                                                                                       

                                                                                            {cell.title}

                                                                                        </ListGroupItem>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }

                                                                            {scheduleEvent.map((info, i) => {
                                                                                if (i == 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <ListGroupItem>
                                                                                                <span className="orangeColor">
                                                                                                    {info.date}
                                                                                                </span>
                                                                                            </ListGroupItem>
                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                if (cell.is_full == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    {t('home.FULL')}
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else if (cell.notify == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else {
                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </Col>
                                                                                                                    <Col className="text-right">
                                                                                                                        <span
                                                                                                                            className="mr-3"
                                                                                                                            style={{ cursor: 'pointer' }}>
                                                                                                                            <i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                        </span>
                                                                                                                        <br />
                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p className="text-right">
                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                            <br />
                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    }
                                                                                                }//Else complete


                                                                                            })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                } else {

                                                                                    return (
                                                                                        <>
                                                                                            <ListGroupItem>
                                                                                                <span className="orangeColor mb-0">
                                                                                                    {info.date}
                                                                                                </span>
                                                                                            </ListGroupItem>
                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                if (cell.is_full == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format}
                                                                                                                    <br />


                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>

                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </Col>
                                                                                                                <Col className="text-right">
                                                                                                                    {t('home.FULL')}
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else if (cell.notify == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>

                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>

                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )

                                                                                                } else {
                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p className="text-right">
                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                            <br />
                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )
                                                                                                    }
                                                                                                }//Else complete


                                                                                            })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }



                                                                            })
                                                                            }







                                                                            <ListGroupItem style={{ display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                                                                {t('home.No schedule available')}
                                                                            </ListGroupItem>
                                                                        </ListGroup>




                                                                         <h6 class="color-red">
                 <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                {eventdetails.map((infos, ii) => {
                                 return(
                                  <span id="CurrentEventType">{infos.event_type} </span>      
                                    )
                              })
                            }&nbsp;

                  <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                    </h6>
                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                            {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            {cartEvent.map((info, i) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div className="row d-flex">
                                                                                            <div className="col-6">
                                                                                                <p className="font-weight-bold">
                                                                                                    {info.title}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-6 pl-0 text-right">
                                                                                                <div className="row">
                                                                                                    <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                        {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                        {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                        {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                        <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                            {info.city_name}
                                                                                                        </a>
                                                                                                        <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                            {info.city_name}
                                                                                                        </a>
                                                                                                        <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                            <p>{info.date}, {info.venue_address}</p>
                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                {info.contact_details}</p>
                                                                                                        </ReactTooltip>
                                                                                                    </div>
                                                                                                    <div className="col-2">
                                                                                                        {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                        <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }

                                                                            <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                            <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                } else {
                                                    if (cell.first_price > 0 && cell.second_price > 0) {
                                                        return (
                                                            <div className={'row px-3 showMoreEventsDiv_' + info.topic_id} style={{ display: 'none' }}>
                                                                <div className={'col-12 py-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                    <div className="row">
                                                                        <div className="col col-lg-5">
                                                                            <h4>
                                                                                <Link style={{ color: '#212529' }} to={"/event_details/" + cell.event_id}>
                                                                                    {cell.title}1684
                                                                                </Link>

                                                                            </h4>
                                                                            <p className="mb-0">


                                                                                {cell.topic.map((info, ii) => {
                                                                                    //if(ii%2 == 0){
                                                                                    if (ii == 0) {
                                                                                        return (
                                                                                            <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                            </Link>

                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                <button style={{ marginLeft: '5px' }} type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                            </Link>

                                                                                        )
                                                                                    }
                                                                                    //}
                                                                                }
                                                                                )}



                                                                                {/* <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">Happy Marriage</button> */}
                                                                            </p>

                                                                        </div>
                                                                        <div className="col col-lg-7">
                                                                            <div className="row">
                                                                                <div className="col col-md-2">
                                                                                    <span className="mr-2">
                                                                                        <i className="fas fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                                    </span>
                                                                                    <span>
                                                                                        <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col col-md-2">
                                                                                    <p className="mb-2">{t('home.Duration')}
                                                                                        <br />
                                                                                        {cell.duration} {t('home.Days')}
                                                                                    </p>

                                                                                    <p className="mb-0">

                                                                                        <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + cell.event_id}   >{t('home.Know More')}</Link>

                                                                                        {/* <a href="#">{t('home.Know More')}</a> */}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col col-md-2">
                                                                                    <label>{cell.first_price_for}</label>
                                                                                    <select className="form-control form-control-sm q4" name={"parent" + cell.event_id} id={"parent"+ cell.topic_id_value +"_"+ cell.stopic_id}  custom-attribute={cell.topic_id_value  +"_"+ cell.stopic_id} >
                                                                                       <option value="0"  selected="selected">00</option>
                                                                                        <option value="1">01</option>
                                                                                        <option value="2">02</option>
                                                                                        <option value="3">03</option>
                                                                                        <option value="4">04</option>
                                                                                        <option value="5">05</option>
                                                                                        <option value="6">06</option>
                                                                                        <option value="7">07</option>
                                                                                        <option value="8">08</option>
                                                                                        <option value="9">09</option>
                                                                                        <option value="10">10</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col col-md-2">
                                                                                    <label>{cell.second_price_for}</label>
                                                                                    <select className="form-control form-control-sm" name={"child" + cell.event_id} id={"child" + cell.event_id}>
                                                                                         <option value="0"  selected="selected">00</option>
                                                                                        <option value="1">01</option>
                                                                                        <option value="2">02</option>
                                                                                        <option value="3">03</option>
                                                                                        <option value="4">04</option>
                                                                                        <option value="5">05</option>
                                                                                        <option value="6">06</option>
                                                                                        <option value="7">07</option>
                                                                                        <option value="8">08</option>
                                                                                        <option value="9">09</option>
                                                                                        <option value="10">10</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col col-md-4 text-right">
                                                                                    <h5>{t('home.INR')} {cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{t('home.per')} {cell.first_price_for}</small>
                                                                                        <br />
                                                                                        {t('home.INR')} {cell.second_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{t('home.per')} {cell.second_price_for}</small>
                                                                                    </h5>


                                                                                    <button style={{ fontWeight: '700' }} className={'schedule_' + cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + cell.event_id} onClick={this.showSchedule}>{t('home.SHOW SCHEDULE')}</button>



                                                                                    {/* <button className="btn btn-sm btn-outline-brown color-red mt-4 scheduleButton" name={cell.event_id} id={cell.event_id}  onClick={this.addToCart}>{t('home.ADD TO CART')}</button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                            </div>
                                                        )
                                                    } else {

                                                        return (
                                                            <div className={'row px-3 showMoreEventsDiv_' + info.topic_id} style={{ display: 'none' }}>
                                                                <div className={'col-12 py-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                    <div className="row">
                                                                        <div className="col col-lg-5">
                                                                            <h4>
                                                                                <Link style={{ color: '#212529' }} to={"/event_details/" + cell.event_id}>
                                                                                    {cell.title}1805
                                                                                </Link>

                                                                            </h4>
                                                                            <p className="mb-0">


                                                                                {cell.topic.map((info, ii) => {
                                                                                    //if(ii%2 == 0){
                                                                                    if (ii == 0) {
                                                                                        return (
                                                                                            <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                            </Link>

                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                <button style={{ marginLeft: '5px' }} type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                            </Link>
                                                                                        )
                                                                                    }
                                                                                    //}
                                                                                }
                                                                                )}



                                                                                {/* <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">Happy Marriage</button> */}
                                                                            </p>

                                                                        </div>
                                                                        <div className="col col-lg-7">
                                                                            <div className="row">
                                                                                <div className="col col-md-2">
                                                                                    <span className="mr-2">
                                                                                        <i className="fas fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                                    </span>
                                                                                    <span>
                                                                                        <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col col-md-2">
                                                                                    <p className="mb-2">{t('home.Duration')}
                                                                                        <br />
                                                                                        {cell.duration} {t('home.Days')}
                                                                                    </p>

                                                                                    <p className="mb-0">

                                                                                        <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + cell.event_id}   >{t('home.Know More')}</Link>

                                                                                        {/* <a href="#">{t('home.Know More')}</a> */}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col col-md-2">
                                                                                    <label>{cell.first_price_for}</label>
                                                                                    <select className="form-control form-control-sm" name={"parent" + cell.event_id} id={"parent"+  cell.topic_id_value +"_"+ cell.stopic_id}   custom-attribute={cell.topic_id_value  +"_"+ cell.stopic_id}  >
                                                                                         <option value="0"  selected="selected">00</option>
                                                                                        <option value="1">01</option>
                                                                                        <option value="2">02</option>
                                                                                        <option value="3">03</option>
                                                                                        <option value="4">04</option>
                                                                                        <option value="5">05</option>
                                                                                        <option value="6">06</option>
                                                                                        <option value="7">07</option>
                                                                                        <option value="8">08</option>
                                                                                        <option value="9">09</option>
                                                                                        <option value="10">10</option>
                                                                                    </select>
                                                                                </div>
                                                                                {/* <div className="col col-md-2" >
                                                    <label>{cell.second_price_for}</label>
                                                    <select className="form-control form-control-sm" name={"child"+cell.event_id}  id={"child"+cell.event_id}>
                                                         <option value="0"  selected="selected">00</option>
                                                        <option value="1">01</option>
                                                        <option value="2">02</option>
                                                        <option value="3">03</option>
                                                        <option value="4">04</option>
                                                        <option value="5">05</option>
                                                    </select>
                                                </div> */}
                                                                                <div className="col col-md-6 text-right">
                                                                                    <h5>{t('home.INR')} {cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{t('home.per')} {cell.first_price_for}</small>
                                                                                        <br />
                                                                                        {/* {t('home.INR')} {cell.second_price} <small>{t('home.per')} {cell.second_price_for}</small> */}
                                                                                    </h5>


                                                                                    <button style={{ fontWeight: '700' }} className={'schedule_' + cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + cell.event_id} onClick={this.showSchedule}>{t('home.SHOW SCHEDULE')}</button>



                                                                                    {/* <button className="btn btn-sm btn-outline-brown color-red mt-4 scheduleButton" name={cell.event_id} id={cell.event_id}  onClick={this.addToCart}>{t('home.ADD TO CART')}</button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                            </div>
                                                        )
                                                    }
                                                }
                                            })}


                                            <div className="row">
                                                <div className="w-100 text-right  mt-3">
                                                    <button id={'showMoreEvents_' + info.topic_id} onClick={this.showMoreEvents} className="btn btn-sm btn-brown" style={{ marginRight: '12px' }}>{t('home.SHOW MORE')}</button>
                                                    <hr className="border-dark" />
                                                </div>
                                            </div>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div >
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <h4 style={{ fontSize: '1.4rem' }}>
                                                        {info.topic_title}
                                                    </h4>
                                                    <h6 className="color-red my-2 mb-4 fontWeight700">
                                                        {t('home.Step 1')}: &nbsp;

                                 {/* {t('home.Click on Show Schedule of the workshop you intend to attend')}   */}
                                                        {t('home.Click on Show Schedule of the')}
                                                        {eventdetails.map((infos, ii) => {
                                                            return (
                                                                <span>{infos.event_type}</span>

                                                            )
                                                        })
                                                        }
                                                        {t('home.you intend to attend')}

                                                    </h6>
                                                </div>
                                            </div>

                                            {info.arr_events.map((cell, i) => {
                                                if (cell.first_price > 0 && cell.second_price > 0) {
                                                    return (
                                                        <div className="row px-2">
                                                            <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                <DesktopDesignParentChild
                                                                    cell={cell}
                                                                    showSchedule={this.showSchedule}
                                                                    t={(name) => t(name)}
                                                                    topicId={info.topic_id}
                                                                />
                                                                <MobileDesignParentChild
                                                                    cell={cell}
                                                                    showSchedule={this.showSchedule}
                                                                    t={(name) => t(name)}
                                                                    topicId={info.topic_id}
                                                                    topicList={this.state.topic}
                                                                    pathname={this.props.location.pathname}
                                                                />


                                                            </div>
                                                            <div className="pt-3 eventRightMenuMainMobile w-100 ">
                                                                <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                    <div className="withoutScheduleDiv">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                                <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                                {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                            <div className="row d-flex">
                                                                                <div className="col-12">

                                                                                    {t('home.Click on Show Schedule of the')}&nbsp;
                                                                            {eventdetails.map((infos, ii) => {
                                                                                        //var current_type_name = infos.event_type.toLowerCase();
                                                                                        var current_type_name = infos.event_type;
                                                                                        return (
                                                                                            <span className="CurrentEventType">{current_type_name} </span>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                    {t('home.you intend to attend')}
                                                                                </div>

                                                                            </div>


                                                                        </div>

                                                                    </div>




                                                                    <ListGroup style={{ display: 'none' }} className="scheduleMainDiv">
                                                                        <ListGroupItem>
                                                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                            </ListGroupItem>
                                                                        {scheduleEvent.map((cell, ii) => {
                                                                            if (ii == 0) {
                                                                                return (
                                                                                    <ListGroupItem className="font-weight-bolder robotoFontFamily">

                                                                                        {t('home.Upcoming')}&nbsp;
                                                                                        {eventdetails.map((infos, ii) => {
                                                                                            return (
                                                                                                <span className="CurrentEventType">{infos.event_type} </span>
                                                                                            )
                                                                                        })
                                                                                        }&nbsp;
                                                                                        <span class="for_res_event" style={{display:'none'}}> {t('home.for')}</span>&nbsp;
                                                                                       <span class="for_other_event" style={{display:'none'}}>{t('home.for other event')} </span>
                                                                                       

                                                                                        {cell.title}

                                                                                    </ListGroupItem>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        {scheduleEvent.map((info, i) => {
                                                                            if (i == 0) {
                                                                                return (
                                                                                    <>
                                                                                        <ListGroupItem>
                                                                                            <span className="orangeColor">
                                                                                                {info.date}
                                                                                            </span>
                                                                                        </ListGroupItem>
                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                            if (cell.is_full == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>
                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>

                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                {t('home.FULL')}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else if (cell.notify == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>
                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else {
                                                                                                if (cell.available_in_cart == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>
                                                                                                                </Col>
                                                                                                                <Col className="text-right">
                                                                                                                    <span
                                                                                                                        className="mr-3"
                                                                                                                        style={{ cursor: 'pointer' }}>
                                                                                                                        <i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                    </span>
                                                                                                                    <br />
                                                                                                                    <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                    <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p className="text-right">
                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                }
                                                                                            }//Else complete


                                                                                        })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            } else {

                                                                                return (
                                                                                    <>
                                                                                        <ListGroupItem>
                                                                                            <span className="orangeColor mb-0">
                                                                                                {info.date}
                                                                                            </span>
                                                                                        </ListGroupItem>
                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                            if (cell.is_full == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format}
                                                                                                                <br />


                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>

                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>

                                                                                                            </Col>
                                                                                                            <Col className="text-right">
                                                                                                                {t('home.FULL')}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else if (cell.notify == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </p>
                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else {
                                                                                                if (cell.available_in_cart == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p className="text-right">
                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                    <br />
                                                                                                                    <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                    <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                }
                                                                                            }//Else complete


                                                                                        })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }



                                                                        })
                                                                        }







                                                                        <ListGroupItem style={{ display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                                                            {t('home.No schedule available')}
                                                                        </ListGroupItem>
                                                                    </ListGroup>




                                                                     <h6 class="color-red">
                 <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                {eventdetails.map((infos, ii) => {
                                 return(
                                  <span id="CurrentEventType">{infos.event_type} </span>      
                                    )
                              })
                            }&nbsp;

                  <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                    </h6>
                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                        {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        {cartEvent.map((info, i) => {
                                                                            return (
                                                                                <div>
                                                                                    <div className="row d-flex">
                                                                                        <div className="col-6">
                                                                                            <p className="font-weight-bold">
                                                                                                {info.title}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="col-6 pl-0 text-right">
                                                                                            <div className="row">
                                                                                                <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                    {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                    {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                    <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                        {info.city_name}
                                                                                                    </a>
                                                                                                    <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                        {info.city_name}
                                                                                                    </a>
                                                                                                    <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                        <p>{info.date}, {info.venue_address}</p>
                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                            {info.contact_details}</p>
                                                                                                    </ReactTooltip>
                                                                                                </div>
                                                                                                <div className="col-2">
                                                                                                    {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                    <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }

                                                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                        <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="row px-2">
                                                            <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                <DesktopDesignAdult
                                                                    cell={cell}
                                                                    showSchedule={this.showSchedule}
                                                                    t={(name) => t(name)}
                                                                    topicId={info.topic_id}
                                                                />
                                                                <MobileDesignAdult
                                                                    cell={cell}
                                                                    showSchedule={this.showSchedule}
                                                                    t={(name) => t(name)}
                                                                    topicId={info.topic_id}
                                                                    topicList={this.state.topic}
                                                                    pathname={this.props.location.pathname}
                                                                />
                                                            </div>
                                                            <div className="pt-3 eventRightMenuMainMobile w-100 ">
                                                                <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                    <div className="withoutScheduleDiv">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                                  <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                                {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                            <div className="row d-flex">
                                                                                <div className="col-12">

                                                                                    {t('home.Click on Show Schedule of the')}&nbsp;
                                                                            {eventdetails.map((infos, ii) => {
                                                                                        //var current_type_name = infos.event_type.toLowerCase();
                                                                                        var current_type_name = infos.event_type;
                                                                                        return (
                                                                                            <span className="CurrentEventType">{current_type_name} </span>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                    {t('home.you intend to attend')}
                                                                                </div>

                                                                            </div>


                                                                        </div>

                                                                    </div>




                                                                    <ListGroup style={{ display: 'none' }} className="scheduleMainDiv">
                                                                        <ListGroupItem>
                                                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                                                            </ListGroupItem>
                                                                        {scheduleEvent.map((cell, ii) => {
                                                                            if (ii == 0) {
                                                                                return (
                                                                                    <ListGroupItem className="font-weight-bolder robotoFontFamily">

                                                                                        {t('home.Upcoming')}&nbsp;
                                                                                        
                                                                                         {cell.title}&nbsp;
                                                                                        <span class="for_res_event" style={{display:'none'}}> {t('home.for')}</span>&nbsp;
                                                                                       <span class="for_other_event" style={{display:'none'}}>{t('home.for other event')} </span>
                                                                                       

                                                                                       

                                                                                    </ListGroupItem>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        {scheduleEvent.map((info, i) => {
                                                                            if (i == 0) {
                                                                                return (
                                                                                    <>
                                                                                        <ListGroupItem>
                                                                                            <span className="orangeColor">
                                                                                                {info.date}
                                                                                            </span>
                                                                                        </ListGroupItem>
                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                            if (cell.is_full == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>
                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>

                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                {t('home.FULL')}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else if (cell.notify == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>
                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else {
                                                                                                if (cell.available_in_cart == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>
                                                                                                                </Col>
                                                                                                                <Col className="text-right">
                                                                                                                    <span
                                                                                                                        className="mr-3"
                                                                                                                        style={{ cursor: 'pointer' }}>
                                                                                                                        <i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                    </span>
                                                                                                                    <br />
                                                                                                                    <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                    <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p className="text-right">
                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                }
                                                                                            }//Else complete


                                                                                        })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            } else {

                                                                                return (
                                                                                    <>
                                                                                        <ListGroupItem>
                                                                                            <span className="orangeColor mb-0">
                                                                                                {info.date}
                                                                                            </span>
                                                                                        </ListGroupItem>
                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                            if (cell.is_full == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                {cell.start_date_format} - {cell.end_date_format}
                                                                                                                <br />


                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {cell.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {cell.city_name}
                                                                                                                </a>

                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>

                                                                                                            </Col>
                                                                                                            <Col className="text-right">
                                                                                                                {t('home.FULL')}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else if (cell.notify == 1) {
                                                                                                return (
                                                                                                    <ListGroupItem>

                                                                                                        <Row>
                                                                                                            <Col sm="6" xs="6">
                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {cell.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {cell.contact_details}</p>
                                                                                                                    </ReactTooltip>

                                                                                                                </p>
                                                                                                            </Col>
                                                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                                                <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ListGroupItem>
                                                                                                )

                                                                                            } else {
                                                                                                if (cell.available_in_cart == 1) {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p className="text-right">
                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                        <br />
                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <ListGroupItem>
                                                                                                            <Row>
                                                                                                                <Col sm="6" xs="6">
                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </p>
                                                                                                                </Col>
                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                    <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                    <br />
                                                                                                                    <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                    <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </ListGroupItem>
                                                                                                    )
                                                                                                }
                                                                                            }//Else complete


                                                                                        })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }



                                                                        })
                                                                        }







                                                                        <ListGroupItem style={{ display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                                                            {t('home.No schedule available')}
                                                                        </ListGroupItem>
                                                                    </ListGroup>




                                                                     <h6 class="color-red">
                 <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                {eventdetails.map((infos, ii) => {
                                 return(
                                  <span id="CurrentEventType">{infos.event_type} </span>      
                                    )
                              })
                            }&nbsp;

                  <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                    </h6>
                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                        {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        {cartEvent.map((info, i) => {
                                                                            return (
                                                                                <div>
                                                                                    <div className="row d-flex">
                                                                                        <div className="col-6">
                                                                                            <p className="font-weight-bold">
                                                                                                {info.title}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="col-6 pl-0 text-right">
                                                                                            <div className="row">
                                                                                                <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                    {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                    {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                    <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                        {info.city_name}
                                                                                                    </a>
                                                                                                    <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                        {info.city_name}
                                                                                                    </a>
                                                                                                    <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                        <p>{info.date}, {info.venue_address}</p>
                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                            {info.contact_details}</p>
                                                                                                    </ReactTooltip>
                                                                                                </div>
                                                                                                <div className="col-2">
                                                                                                    {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                    <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }

                                                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                        <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                    </div>


                                                                </div>
                                                            </div>



                                                        </div>
                                                    )
                                                }
                                            })}


                                            {/* <div className="row">
                            <div className="w-100 text-right mb-2 mt-3">
                                <button className="btn btn-sm btn-brown">{t('home.SHOW MORE')}</button>
                                
                            </div>
                        </div> */}
                                            <hr className="border-dark" />
                                        </div>
                                    )
                                }
                            })}

                            <div className="row">
                                <p id="productNotAvailable" style={{ display: 'none', marginLeft: '20px' }}>
                                    {t('home.Not available')}
                                </p>
                            </div>

                        </div>








                        <div className="col-lg-3 pt-3 eventRightMenuMainDesktop" id="eventRightMenuMain" style={{ marginTop: '63px' }}>
                            <div className="withoutScheduleDiv">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                        {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                    </div>
                                </div>

                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                    <div className="row d-flex">
                                        <div className="col-12">

                                            {t('home.Click on Show Schedule of the')}&nbsp;
                        {eventdetails.map((infos, ii) => {
                                                //var current_type_name = infos.event_type.toLowerCase();
                                                var current_type_name = infos.event_type;
                                                return (
                                                    <span className="CurrentEventType">{current_type_name} </span>
                                                )
                                            })
                                            }
                                            {t('home.you intend to attend')}
                                        </div>

                                    </div>


                                </div>

                            </div>




                            <div style={{ display: 'none' }} className="scheduleMainDiv">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart BookNow')}</h6>
                                       
                                    </div>
                                </div>
                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                    {scheduleEvent.map((cell, ii) => {
                                        if (ii == 0) {
                                            return (
                                                <span>
                                                    <p className="font-weight-bolder robotoFontFamily">
                                                        {t('home.Upcoming')}&nbsp;
                       {/*  {eventdetails.map((infos, ii) => {
                                                            return (
                                                                <span className="CurrentEventType">{infos.event_type} </span>
                                                            )
                                                        })
                                                        } &nbsp;
                                                    */}
                        

                    {cell.title} 
                    <span class="for_res_event" style={{display:'none'}}> {t('home.for')}</span>&nbsp;
                                                                                       <span class="for_other_event" style={{display:'none'}}>{t('home.for other event')} </span>
                                                                                       

                    </p>

                                                </span>
                                            )
                                        }
                                    })
                                    }
                                    <div style={{ maxHeight: '700px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                        {scheduleEvent.map((info, i) => {
                                            if (i == 0) {
                                                return (
                                                    <span>
                                                        {/* <hr className="border-dark mx-n2" /> */}
                                                        <p className="orangeColor mb-0">{info.date}</p>
                                                        {info.arr_schedule.map((cell, ii) => {
                                                            if (cell.is_full == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                        {t('home.FULL')}
                                                                                    </p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )

                                                            } else if (cell.notify == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>

                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                    </p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )

                                                            } else {
                                                                if (cell.available_in_cart == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                        {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                        <button className={'btn btn-sm btn-outline-brown ca1 ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id}   custom-attribute={cell.event_topic_value +"_" + cell.stopic_id }     onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                }
                                                            }//Else complete


                                                        })
                                                        }
                                                    </span>
                                                )
                                            } else {

                                                return (
                                                    <span>
                                                        <hr className="border-dark mx-n2" />
                                                        <p className="orangeColor mb-0">{info.date}</p>
                                                        {info.arr_schedule.map((cell, ii) => {
                                                            if (cell.is_full == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                        {t('home.FULL')}
                                                                                    </p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )

                                                            } else if (cell.notify == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>

                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                    </p>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )

                                                            } else {
                                                                if (cell.available_in_cart == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                        {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                        <button className={'btn btn-sm btn-outline-brown color-red ca2 ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id}  custom-attribute={cell.event_topic_value +"_" + cell.stopic_id }  onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                }
                                                            }//Else complete


                                                        })
                                                        }
                                                    </span>
                                                )
                                            }



                                        })
                                        }






                                        <hr className="border-dark mx-n2 mt-2" />

                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                        <br />
                                        <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                        {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                    </div>
                                </div>

                            </div>




                            <h6 class="color-red">
                 <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                {eventdetails.map((infos, ii) => {
                                 return(
                                  <span id="CurrentEventType">{infos.event_type} </span>      
                                    )
                              })
                            }&nbsp;

                  <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                    </h6>
                            <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                <hr className="border-dark mx-n2 mt-2" />
                                {cartEvent.map((info, i) => {
                                    return (
                                        <div>
                                            <div className="row d-flex">
                                                <div className="col-6">
                                                    <p className="font-weight-bold">
                                                        {info.title}
                                                    </p>
                                                </div>
                                                <div className="col-6 pl-0 text-right">
                                                    <div className="row">
                                                        <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                            <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                {info.city_name}
                                                            </a>
                                                            <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                {info.city_name}
                                                            </a>
                                                            <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                <p>{info.date}, {info.venue_address}</p>
                                                                <p>{t('home.Contact No')}<br />
                                                                    {info.contact_details}</p>
                                                            </ReactTooltip>
                                                        </div>
                                                        <div className="col-2">
                                                            {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                            <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + info.event_id + '_' + info.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr className="border-dark mx-n2 mt-2" />
                                        </div>
                                    )
                                })
                                }

                                <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                <button className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                            </div>


                        </div>









                    </div>
                </div>
                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}


                <div className="modal fade z-index--99999" id="notifyModel" tabindex="-1" role="dialog" aria-labelledby="notifyModel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content bg-light-light" style={{ overflow: 'auto' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t('home.Event')}-&nbsp;<span id="eventTitle" style={{paddingRight:'38px'}}></span></h5><br />
                                <h5 className="modal-title" id="exampleModalLabel">
                                    - {/* {t('home.Notify for')} */}
                                    <span id="notifyTitle"></span></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="far fa-times-circle"></i>
                                </button>
                            </div>
                            <Form action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">
                                <div className="modal-body">

                                    {/* <div className="row">
                        <div className="col">
                            <p className="text-right color-red">* {t('home.Mandatory Fields')}</p>
                        </div>
                    </div> */}
                                    <div className="row">
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Name')}<span className="color-red">*</span></label>
                                            <input value={notifyinfo.first_name} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" id="first_name" name="first_name" className="form-control" placeholder={t('home.Enter First Name')} />


                                            {this.validator.message('first_name', this.state.notifyinfo.first_name, 'required')}

                                        </div>
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                            {/* <input type="text" className="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                                            <input value={notifyinfo.last_name} onBlur={() => this.validator.showMessageFor('last_name')} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, last_name: e.target.value } }) }} type="text" id="last_name" name="last_name" className="form-control" placeholder={t('home.Enter Last Name')} />
                                            {this.validator.message('last_name', this.state.notifyinfo.last_name, 'required')}
                                        </div>


                                    </div>
                                    <div className="row">

                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                                            <input value={notifyinfo.email} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, email: e.target.value } }) }} type="text" className="form-control" id="email" name="email" placeholder={t('home.Email')} />
                                            {this.validator.message('email', this.state.notifyinfo.email, 'required')}
                                        </div>
                                        <input type="hidden" value="" id="notify_schedule_id" name="notify_schedule_id" />
                                    </div>

                                </div>
                                <div className="modal-footer">

                                    <span data-dismiss="modal" aria-label="Close" style={{ cursor: 'pointer' }}>{t('home.Cancel')}</span>
                                    <button type="submit" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4" style={{ color: 'black' }}>{t('home.Submit')}</button>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

Booknow.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Booknow);

