import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import pdf from './assets/images/pdf.png';
import image from './assets/images/jpg.png';
import excel from './assets/images/excel.png';
import doc from './assets/images/doc.png';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input
} from "reactstrap";

const images = [
    '//placekitten.com/1500/500',
    '//placekitten.com/4000/3000',
    '//placekitten.com/800/1200',
    '//placekitten.com/1500/1500',
];



class Photogallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            images: [],
            isOpen: false,
            limit: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            arr_year: [],
            arr_event: [],
            guestinfo: {
                format_date: '',
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',
                topic_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            arr_gallery: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],
            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.applyFiltersDesktop = this.applyFiltersDesktop.bind(this);
        this.applyFiltersMobile = this.applyFiltersMobile.bind(this);
        this.showMoreDesktop = this.showMoreDesktop.bind(this);
        this.showMoreMobile = this.showMoreMobile.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.topicChange = this.topicChange.bind(this);
        this.eventChange = this.eventChange.bind(this);
    }

    eventChange() {
        var event_id = $('#event_id').val();
        if (event_id > 0) {
            $('#topic_id').prop('selectedIndex', 0);
            $("#topic_id").prop("disabled", true);
        } else {
            $("#topic_id").prop("disabled", false);
        }

        var event_id_m = $('#event_id_m').val();
        if (event_id_m > 0) {
            $('#topic_id_m').prop('selectedIndex', 0);
            $("#topic_id_m").prop("disabled", true);
            $("#topic_id_m").addClass('disabled--text');
            $("#topic_id_m").removeClass('bg-transparent');
        } else {
            $("#topic_id_m").prop("disabled", false);
            $("#topic_id_m").removeClass('disabled--text');
        }
    }
    topicChange() {
        var topic_id = $('#topic_id').val();
        if (topic_id > 0) {
            $('#event_id').prop('selectedIndex', 0);
            $("#event_id").prop("disabled", true);
        } else {
            $("#event_id").prop("disabled", false);
        }

        var topic_id_m = $('#topic_id_m').val();
        if (topic_id_m > 0) {
            $('#event_id_m').prop('selectedIndex', 0);
            $("#event_id_m").prop("disabled", true);
            $("#event_id_m").addClass('disabled--text');
            $("#event_id_m").removeClass('bg-transparent');
        } else {
            $("#event_id_m").prop("disabled", false);
            $("#event_id_m").removeClass('disabled--text');
        }

    }

    openGallery(e) {
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }

    showMoreMobile() {
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 9;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters($('#topic_id_m').val(), $('#year_m').val(), $('#event_id_m').val());
    }
    showMoreDesktop() {
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 9;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters($('#topic_id').val(), $('#year').val(), $('#event_id').val());
    }

    componentDidMount() {
        this.state.limit = 9;
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        // var year = currentTime.getFullYear();
        // this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');



        //alert(research);

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'photo/getYear')
            .then(response => response.json())
            .then(response => this.setState({ arr_year: response }, () => {
                this.getImages();
                this.getPhotoGalleryFrontEnd();
            }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'photo/getEventList' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arr_event: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))


    }

    getImages = () => {
        fetch(myConstClass.BASE_URL + 'photo/getImages' + '/' + getLanguage() + '/9/0/' + this.state.arr_year[0].date + '/')
            .then(response => response.json())
            .then(response => this.setState({ images: response }))
            .catch(err => console.log(err))
    }

    getPhotoGalleryFrontEnd = () => {
        fetch(myConstClass.BASE_URL + 'photo/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/9/0/' + this.state.arr_year[0].date + '/')
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response })
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                    $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                    $('#showMoreBtnM').removeClass('d-block');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    //   alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#showMoreBtn').css('display', 'inline');
                        $('#showMoreBtnM').css('display', 'inline');
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })
            .catch(err => console.log(err))
    }

    componentDidUpdate(prevProps) {

        const { flag } = this.state;

        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            topic_id = 0;
            var topic_id = $('#topic_id').val();
            // alert(topic_id);
            if (topic_id > 0) {

            } else {
                var topic_id = $('#topic_id_m').val();
            }
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year > 0) {

            } else {
                year = $('#year_m').val();
            }
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id > 0) {

            } else {
                event_id = $('#event_id_m').val();
            }
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;


            fetch(myConstClass.BASE_URL + 'photo/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => this.setState({ images: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'photo/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                            $('#showMoreBtnM').removeClass('d-block');
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                            $('#showMoreBtnM').css('display', 'inline');
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })
            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'photo/getEventList' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arr_event: response }))
                .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];


            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            topic_id = 0;
            var topic_id = $('#topic_id').val();
            if (topic_id > 0) {

            } else {
                var topic_id = $('#topic_id_m').val();
            }
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year > 0) {

            } else {
                var year = $('#year_m').val();
            }
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id > 0) {

            } else {
                var event_id = $('#event_id_m').val();
            }
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;

            fetch(myConstClass.BASE_URL + 'photo/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => this.setState({ images: response }))
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'photo/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                            $('#showMoreBtnM').removeClass('d-block');
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                            $('#showMoreBtnM').css('display', 'inline');
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'photo/getEventList' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arr_event: response }))
                .catch(err => console.log(err))


        }
    }


    applyFiltersDesktop = (e) => {
        // e.preventDefault();
        console.log("Hello");
        this.applyFilters($('#topic_id').val(), $('#year').val(), $('#event_id').val());
    }

    applyFiltersMobile = (e) => {
        // e.preventDefault();
        this.applyFilters($('#topic_id_m').val(), $('#year_m').val(), $('#event_id_m').val());
    }

    applyFilters(topic_id, year, event_id) {

        if (topic_id == '' || topic_id == undefined) {
            topic_id = 0;
        }
        const answer_array = this.props.location.pathname.split('/');
        //  alert(filetype);
        var limit = this.state.limit;

        fetch(myConstClass.BASE_URL + 'photo/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
            .then(response => response.json())
            .then(response => this.setState({ images: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'photo/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response })
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                    $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                    $('#showMoreBtnM').removeClass('d-block');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    // alert(limit)
                    // alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#showMoreBtn').css('display', 'inline')
                        $('#showMoreBtnM').css('display', 'inline');
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })



    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { photoIndex, isOpen, arr_event, month, arr_year, arrMagazine, arr_gallery, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                <Header />


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 ">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb" id="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0 ml-n2 pb-0  ">
                                        <li className="breadcrumb-item h5 pl-2 ">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item h5 pl-2 "><a href="#">{t('home.Gallery')}</a></li>
                                        <li className="breadcrumb-item active h5 d-xl-inline d-lg-inline d-md-inline d-none" aria-current="page"><a href="#">{t('home.Photo Gallery')}</a></li>
                                        <li className="breadcrumb-item active h5 d-xl-none d-lg-none d-md-none d-inline" aria-current="page"><a href="#">Photo</a></li>
                                    </ol>

                                    <div className="d-xl-block d-lg-block d-md-block d-none">
                                        <Link className="text-dark ml-2 pl-1 h5 font-weight-bold" to="/photo-gallery"><b>{t('home.PHOTO GALLERY')}</b></Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/video-gallery">{t('home.VIDEO GALLERY')}</Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/audio-gallery">{t('home.AUDIO GALLERY')}</Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/wallpaper-gallery">
                                            {t('home.WALLPAPER GALLERY')}
                                        </Link>
                                    </div>

                                    <div className="d-xl-none d-lg-none d-md-none d-block">
                                        <Link className="text-dark ml-2 pl-1 h5 font-weight-bold" to="/photo-gallery"><b>PHOTO</b></Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/video-gallery">VIDEO</Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/audio-gallery">AUDIO</Link>
                                        <Link className="btn btn-link orangeColor font-weight-bold" to="/wallpaper-gallery">
                                            WALLPAPER
                            </Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 ">
                            <Row className="bg-light m-0 py-2">
                                <Col xl="3" lg="3" md="3" className="align-self-center">
                                    {t('home.Select_filters_to_refine_the_results_below')}:
                                </Col>

                                {/* Desktop Design */}
                                <Col xl="3" lg="3" md="3" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Topic')}</label>
                                        <select id="topic_id" name="topic_id" className="form-control" placeholder={t('home.Topic')} onChange={this.topicChange}>
                                            <option value="">{t('home.Select Topic')}</option>
                                            {topic.map((info, ii) => {
                                                if (info.value == guestinfo.topic_id) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex  d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Year')}</label>
                                        <select className="form-control" id="year" name="year" defaultValue="2019">
                                            {/* <option value="">{t('home.Select Year')}</option> */}
                                            {arr_year.map((info, ii) => {
                                                return (
                                                    <option value={info.date}>{info.date}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex  d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Event')}</label>
                                        <select className="form-control " id="event_id" name="event_id" onChange={this.eventChange}>
                                            <option value="">{t('home.Select Event')}</option>
                                            {arr_event.map((info, ii) => {
                                                return (
                                                    <option value={info.event_id}>{info.title}</option>
                                                )
                                            })
                                            }

                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex  d-none">
                                    <div className="form-group">
                                        <label for="exampleFormControlSelect1" style={{ visibility: 'hidden' }}>{t('home.Event')}</label>
                                        <button type="button" className="btn btn-outline-brown " onClick={this.applyFiltersDesktop}>{t('home.APPLY FILTERS')}  </button>
                                    </div>
                                </Col>

                                {/* Mobile Design */}

                                <Col sm="8" xs="8" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup>
                                        <Label for="">{t('home.Topic')}</Label>
                                        <Input type="select" id="topic_id_m" name="topic_id_m" className="form-control" placeholder={t('home.Topic')} onChange={this.topicChange} bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Topic')}</option>
                                            {topic.map((info, ii) => {
                                                if (info.value == guestinfo.topic_id) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="4" xs="4" className="d-xl-none d-lg-none d-flex">
                                    <FormGroup>
                                        <Label for="">{t('home.Year')}</Label>
                                        <Input type="select" id="year_m" name="year_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0" defaultValue="2019">
                                            {/* <option value="">{t('home.Select Year')}</option> */}
                                            {arr_year.map((info, ii) => {
                                                return (
                                                    <option value={info.date}>{info.date}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" xs="12" className="d-xl-none d-lg-none d-flex">
                                    <FormGroup>
                                        <Label for="">{t('home.Event')}</Label>
                                        <Input type="select" id="event_id_m" name="event_id_m" onChange={this.eventChange} bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Event')}</option>
                                            {arr_event.map((info, ii) => {
                                                return (
                                                    <option value={info.event_id}>{info.title}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" xs="12" className="d-xl-none d-lg-none d-flex">
                                    <div className="form-group">
                                        <button onClick={this.applyFiltersMobile} className="btn btn-sm btn btn-outline-brown btn-block ">
                                            {t('home.APPLY FILTERS')}
                                        </button>
                                    </div>
                                </Col>
                            </Row>


                            {/* <Row className="my-3">
                                <Col className="d-xl-flex d-lg-flex d-md-flex d-none" >
                                    {$("#topic_id").val() !== '' ? 'Topic : ' + $("#topic_id option:selected").text() : ''}
                                    {$("#event_id").val() !== '' ? 'Event : ' + $("#event_id option:selected").text() : ''}
                                    {$('#year').val() ? 'Year : ' + $('#year').val() : ''}
                                </Col>
                                <Col className="d-xl-none d-lg-none d-md-none d-flex" >
                                    {$("#topic_id_m").val() !== '' ? 'Topic : ' + $("#topic_id_m option:selected").text() : ''}
                                    {$("#event_id_m").val() !== '' ? 'Event : ' + $("#event_id_m option:selected").text() : ''}
                                    {$('#year_m').val() ? 'Year : ' + $('#year_m').val() : ''}
                                </Col>
                            </Row> */}
                            <div className="row py-4">
                                {arr_gallery.map((gallery, i) => {
                                    return (
                                        <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                                            <div className="">
                                                {/* <a target="_blank" href={gallery.image_url} download={gallery.image_url} className="btn btn-brown btn-sm" style={{ position: 'absolute', marginLeft: '80%', marginTop: '10%' }}>
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                </a> */}

                                                <img id={i} onClick={this.openGallery} src={gallery.image_url} className="card-img-top galleryResponsiveImage" alt="..." />
                                                <div className="">
                                                    <small dangerouslySetInnerHTML={{ __html: gallery.description }}></small>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }

                            </div>


                            <div className="row pt-3 pb-3">
                                <div className="col-xl-5">

                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-12 text-center">
                                    <button type="button" id="showMoreBtn" className="btn btn-outline-brown m-auto d-xl-block d-lg-block d-md-block d-none" onClick={this.showMoreDesktop}>{t('home.Show More')}</button>
                                    <button type="button" id="showMoreBtnM" className="btn btn-outline-brown m-auto d-xl-none d-lg-none d-md-none d-block" onClick={this.showMoreMobile}>{t('home.Show More')}</button>
                                    <input type="hidden" name="limit" id="limit" value={this.state.limit} />
                                    <p id="notAvailable" style={{ display: 'none' }}>
                                        {t('home.Not available')}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col">
                            <div className="pl-2 ">

                                {arrMagazine.map((cell, i) => {
                                    if (cell.magazine_id == 2) {
                                        return (
                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div className="row d-flex">
                                                    <div className="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                        return (
                                                                            <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/magazine'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                            )}



                                                            <Link className="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div className="row d-flex">
                                                    <div className="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                                <p >
                                                                                    <Link className="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                <p >
                                                                                    <Link className="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                } else {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>


                </div>
                {
                    isOpen && (
                        <Lightbox
                            mainSrc={this.state.images[photoIndex]['image_url']}
                            nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
                            prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % this.state.images.length,
                                })
                            }
                        />
                    )
                }
                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            Â© 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div >
        );
    }
}

Photogallery.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Photogallery);
